import React from "react";
import {
  BrowserRouter as Router,
  HashRouter,
  Routes,
  Route,
  Navigate,
  redirect,
} from "react-router-dom";
import Main from "./layouts/Main";
import Login from "./auth/Login";
import Dashboard from "./views/Dashboard";
import ChangePassword from "./views/users/ChangePassword";
import Profile from "./views/users/Profile";
import Contacts from "./views/settings/Contacts";
import SocialLinks from "./views/settings/SocialLinks";
import ContactRequests from "./views/contact-requests/ContactRequests";
import ListWorks from "./views/our-work/ListWorks";
import SeoContent from "./views/seo/seo-content";
import EbookSubscribers from "./views/e-book/EbookSubscribers";
import Openings from "./views/career/Openings";
import PostJob from "./views/career/PostJob";
import UpdateSeo from "./views/seo/UpdateSeo";
import Logout from "./auth/Logout";
import ViewContactRequest from "./views/contact-requests/View";
import AddWorks from "./views/our-work/AddWorks";
import ViewWorks from "./views/our-work/ViewWorks";
import UpdateWorks from "./views/our-work/UpdateWorks";
import UpdateSocialLinks from "./views/settings/UpdateSocialLinks";
import CreateRole from "./views/user-management/roles/CreateRole";
import ListRoles from "./views/user-management/roles/ListRoles";
import GeneralSettings from "./views/settings/GeneralSettings";
import IntegrationSettings from "./views/settings/IntegrationSettings";

import Edit from "./views/user-management/roles/Edit";

import ListPermissions from "./views/user-management/permission/ListPermissions";
import CreateMenu from "./views/menu-bar/CreateMenu";
import ManageMenu from "./views/menu-bar/ManageMenu";
import SectionCreate from "./views/sections/SectionCreate";
import SectionManage from "./views/sections/SectionManage";
import SectionUpdate from "./views/sections/SectionUpdate";

// import ListCategories from "./views/categories/ListCategories";
// import CreateCategories from "./views/categories/CreateCategories";
import ListCollections from "./views/collections/ListCollections";
import CreateCollections from "./views/collections/collectionForm";
import RoleForm from "./views/user-management/permission/RoleForm";
import Manage from "./views/user-management/roles/Manage";
import PermissionGroupForm from "./views/user-management/permissionGroup/PermissionGroupForm";
import CreatePermission from "./views/user-management/permission/CreatePermission";
import EditPermissionForm from "./views/user-management/permissionGroup/EditPermissionForm";
import ManagePermission from "./views/user-management/permission/ManagePermission";
import ListPermissionsGroup from "./views/user-management/permissionGroup/ListPermissionGroup";
import Static from "./views/static/Static";
import CreateAttribute from "./views/attribute/CreateAttribute";
import ListAttribute from "./views/attribute/ListAttribute";
import ListPermissionGroup from "./views/user-management/permissionGroup/ListPermissionGroup";
import User from "./views/user-management/user/User";
// import PushNotifications from "./views/settings/push-notification/PushNotifications";
// import ManagePushNotification from "./views/settings/push-notification/CreatePushNotification";
import ManagePushNotifications from "./views/settings/push-notification/ManagePushNotifications";
import CreatePage from "../components/views/pages/createPage";
import ListPage from "../components/views/pages/ListPages";

import RssFeed from '../components/views/rss-feed/listing'
import RssListing from '../components/views/rss-listing/index'
import CommonMaster from '../components/common/commom-master'
import RssBreaking from '../components/views/rss-breaking/index'
import RssBreakingEdit from '../components/views/rss-breaking/edit'
import RaftaarRssBreaking from "./views/rss-feed/raftaar-rss-breaking";

import RssBreakingNewsList from "./views/RssBreakingNews/List"
import RssBreakingNewsCreate from "./views/RssBreakingNews/Create"
import EditedRss from "./views/rss-breaking/edited-rss";

import OpinonPolls from "./views/opinion-polls/Index";
import CreateOpinionPoll from "./views/opinion-polls/Create";
import ForgetPasswordForm from "./views/users/forgetPassword";
import { useEffect } from "react";
import SectionDescription from "./views/sections/SectionDescription";
import CollectionRead from "./views/collections/CollectionRead";
// latest published
//trending 24 hours
import StoryReport from "./views/stories/StoryReport";
import AuthorReport from "./views/stories/AuthorReport"
import ResetPassword from "./views/users/resetPassword";
import CreateStory from "./views/stories/createStory";
import CreateLiveBlog from "./views/stories/LiveBlog"
import ListStory from "./views/stories/ListStory-V2";
import BreakingNews from "./views/stories/ListBreakingNews2";
import ViewStory from "./views/stories/viewStory";
import CreateWebStory from "./views/web-stories/createStory";
import ListWebStory from "./views/web-stories/ListStory";
import TagsManage from "./views/tags/TagsManage";
import ListVideoStory from "./views/video-stories/ListStory";
import CreateVideoStory from "./views/video-stories/createStory";
import CollectionUpdate from "./views/collections/update";
import ListPhotoStory from "./views/photo-story/ListPhotoStory";
import CreatePhotoStory from "./views/photo-story/createStory";
import ScriptTagManage from "./views/settings/script-tag/SectionManage";
import AuthorPerformance from "./views/reports/author-performance";
import AuthorStory from "./views/reports/author";
import StoryByAuthor from "./views/reports/story";
const PrivateRoute = ({ element, rolesAllowed = [] }) => {
  const token = sessionStorage.getItem("token");
  const token1 = localStorage.getItem("token");

  const user = JSON.parse(localStorage.getItem('user'))

  if (!user) return <Navigate to="/login" />

  if (rolesAllowed?.length > 0 && !rolesAllowed?.includes(user?.user_role?.name)) return <Navigate to="/" />

  return token1 ? element : <Navigate to="/login" />;
};

const AppRoutes = () => {
  const token = sessionStorage.getItem("token");
  const token1 = localStorage.getItem("token");
  useEffect(() => {
    if (token1 !== null && window.location.pathname === "/") {
      redirect(`/dashboard`);
    } else {
      redirect(`/login`);
    }
  }, [token1]);
  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/logout" element={<Logout />} />
        <Route path="/forget-password" element={<ForgetPasswordForm />} />

        <Route path="/forget-password/:id" element={<ResetPassword />} />

        <Route path="/" element={<Main />}>
          {/* Secure the Dashboard and Users routes */}
          <Route
            path="/dashboard"
            element={<PrivateRoute element={<Dashboard />} />}
          />
          {/* Menu card */}
          <Route
            path="/menu/create"
            element={<PrivateRoute element={<CreateMenu />} rolesAllowed={['Admin']} />}
          />
          <Route
            path="/menu"
            element={<PrivateRoute element={<ManageMenu />} rolesAllowed={['Admin']} />}
          />
          <Route
            path="/menu/update/:id"
            element={<PrivateRoute element={<CreateMenu />} rolesAllowed={['Admin']} />}
          />
          {/* Section */}
          <Route
            path="/section/create"
            element={<PrivateRoute element={<SectionCreate />} />}
          />
          <Route
            path="/section/:id"
            element={<PrivateRoute element={<SectionDescription />} />}
          />

          <Route
            path="/section"
            element={<PrivateRoute element={<SectionManage />} />}
          />
          <Route
            path="/section/update/:id"
            element={<PrivateRoute element={<SectionUpdate />} />}
          />
          {/*   Static */}
          <Route
            path="/static"
            element={<PrivateRoute element={<Static />} rolesAllowed={['Admin']} />}
          />
          {/*   Attribute */}
          <Route
            path="/attribute"
            element={<PrivateRoute element={<ListAttribute />} rolesAllowed={['Admin']} />}
          />
          <Route
            path="/attribute/create"
            element={<PrivateRoute element={<CreateAttribute />} rolesAllowed={['Admin']} />}
          />
          {/* Users */}
          <Route
            path="/users/change-password"
            element={<PrivateRoute element={<ChangePassword />} />}
          />
          <Route
            path="/users/profile"
            element={<PrivateRoute element={<Profile />} />}
          />
          {/* Settings */}
          <Route
            path="/settings/contacts"
            element={<PrivateRoute element={<Contacts />} rolesAllowed={['Admin']} />}
          />
          <Route
            path="/settings/social-links"
            element={<PrivateRoute element={<SocialLinks />} rolesAllowed={['Admin']} />}
          />
          <Route
            path="/settings/social-links/:id"
            element={<PrivateRoute element={<UpdateSocialLinks />} rolesAllowed={['Admin']} />}
          />

          <Route
            path="/settings/tag"
            element={<PrivateRoute element={<ScriptTagManage />} rolesAllowed={['Admin']} />}
          />
          {/* Categories */}
          {/* <Route path="/categories" element={<PrivateRoute element={<ListCategories />} />} /> */}
          {/* <Route path="/categories/create" element={<PrivateRoute element={<CreateCategories />} />} />
          <Route path="/categories/update/:slug" element={<PrivateRoute element={<CreateCategories />} />} /> */}
          {/*Collections */}
          <Route
            path="/collection"
            element={<PrivateRoute element={<ListCollections />} />}
          />
          <Route
            path="/collection/create"
            element={<PrivateRoute element={<CreateCollections />} />}
          />

          <Route
            path="/collection/update/:id"
            element={<PrivateRoute element={<CollectionUpdate />} />}
          />
          <Route
            path="/collection/:id"
            element={<PrivateRoute element={<CollectionRead />} />}
          />

          {/* Contact Requests */}
          <Route
            path="/contact-requests"
            element={<PrivateRoute element={<ContactRequests />} rolesAllowed={['Admin']} />}
          />
          <Route
            path="/contact-requests/:id"
            element={<PrivateRoute element={<ViewContactRequest />} rolesAllowed={['Admin']} />}
          />
          {/* Our Works */}
          <Route
            path="/our-works"
            element={<PrivateRoute element={<ListWorks />} rolesAllowed={['Admin']} />}
          />
          <Route
            path="/our-works/add"
            element={<PrivateRoute element={<AddWorks />} rolesAllowed={['Admin']} />}
          />
          <Route
            path="/our-works/:slug"
            element={<PrivateRoute element={<ViewWorks />} rolesAllowed={['Admin']} />}
          />
          <Route
            path="/our-works/update/:slug"
            element={<PrivateRoute element={<UpdateWorks />} rolesAllowed={['Admin']} />}
          />
          {/* SEO Content */}
          <Route
            path="/seo-content"
            element={<PrivateRoute element={<SeoContent />} rolesAllowed={['Admin']} />}
          />
          <Route
            path="/seo-content/update/:id"
            element={<PrivateRoute element={<UpdateSeo />} rolesAllowed={['Admin']} />}
          />
          {/* setting */}
          {/* Push Notifications */}
          <Route
            path="/settings/general-settings"
            element={<PrivateRoute element={<GeneralSettings />} rolesAllowed={['Admin']} />}
          />

          <Route
            path="/settings/integrations"
            element={<PrivateRoute element={<IntegrationSettings />} rolesAllowed={['Admin']} />}
          />

          <Route
            path="/settings/push-notification/manage"
            element={<PrivateRoute element={<ManagePushNotifications />}  rolesAllowed={['Admin']}  />}
          />
          {/* eBook Subscribers */}
          <Route
            path="/e-book-subscribers"
            element={<PrivateRoute element={<EbookSubscribers />} rolesAllowed={['Admin']} />}
          />
          {/* Openings  */}
          <Route
            path="/career/openings"
            element={<PrivateRoute element={<Openings />} rolesAllowed={['Admin']} />}
          />
          <Route
            path="/career/post-job"
            element={<PrivateRoute element={<PostJob />} rolesAllowed={['Admin']} />}
          />
          {/* User Management */}
          {/* Roles */}
          <Route
            path="/user-management/roles"
            element={<PrivateRoute element={<ListRoles />} rolesAllowed={['Admin']} />}
          />
          <Route
            path="/user-management/roles/manage"
            element={<PrivateRoute element={<Manage />} rolesAllowed={['Admin']} />}
          />
          <Route
            path="/user-management/roles/edit"
            element={<PrivateRoute element={<Edit />} rolesAllowed={['Admin']} />}
          />
          <Route
            path="/user-management/roles/create"
            element={<PrivateRoute element={<CreateRole />} rolesAllowed={['Admin']} />}
          />
          <Route
            path="/user-management/roles/update/:id"
            element={<PrivateRoute element={<CreateRole />} rolesAllowed={['Admin']} />}
          />
          {/* Permissions */}
          <Route
            path="/user-management/permissions/roleForm"
            element={<PrivateRoute element={<RoleForm />} rolesAllowed={['Admin']} />}
          />
          <Route
            path="/user-management/permissions"
            element={<PrivateRoute element={<ListPermissions />} rolesAllowed={['Admin']} />}
          />
          <Route
            path="/user-management/permissions/create"
            element={<PrivateRoute element={<CreatePermission />} rolesAllowed={['Admin']} />}
          />
          <Route
            path="/user-management/permission/update/:id"
            element={<PrivateRoute element={<CreatePermission />} rolesAllowed={['Admin']} />}
          />
          <Route
            path="/user-management/permissions/ManagePermission"
            element={<PrivateRoute element={<ManagePermission />} rolesAllowed={['Admin']} />}
          />
          {/* PermissionsGroup */}
          <Route
            path="/user-management/permissions-group/create"
            element={<PrivateRoute element={<PermissionGroupForm />} rolesAllowed={['Admin']} />}
          />
          <Route
            path="/user-management/permissions-group"
            element={<PrivateRoute element={<ListPermissionGroup />} rolesAllowed={['Admin']} />}
          />
          <Route
            path="/user-management/permissions-group/update/:id"
            element={<PrivateRoute element={<PermissionGroupForm />} rolesAllowed={['Admin']} />}
          />
          {/* Opinon Polls */}
          <Route
            path="/opinion-polls"
            element={<PrivateRoute element={<OpinonPolls />} rolesAllowed={['Admin']} />}
          />
          <Route
            path="/opinion-polls/create"
            element={<PrivateRoute element={<CreateOpinionPoll />} rolesAllowed={['Admin']} />}
          />
          <Route
            path="/opinion-polls/update/:id"
            element={<PrivateRoute element={<CreateOpinionPoll />} rolesAllowed={['Admin']} />}
          />
          {/* user */}
          <Route path="/admin" element={<PrivateRoute element={<User />} rolesAllowed={['Admin']} />} />
          {/* pages */}

          <Route
            path="/pages/create"
            element={<PrivateRoute element={<CreatePage />} rolesAllowed={['Admin']} />}
          />
          <Route
            path="/pages/update/:id"
            element={<PrivateRoute element={<CreatePage />} rolesAllowed={['Admin']} />}
          />
          <Route
            path="/pages"
            element={<PrivateRoute element={<ListPage />} rolesAllowed={['Admin']} />}
          />
          <Route
            path="/tags"
            element={<PrivateRoute element={<TagsManage />} />}
          />
          <Route
            path="/story/create"
            element={<PrivateRoute element={<CreateStory />} />}
          />
          <Route
            path="/live-blog/update/:id"
            element={<PrivateRoute element={<CreateLiveBlog />} />}
          />
          <Route
            path="/live-blog/create"
            element={<PrivateRoute element={<CreateLiveBlog />} />}
          />
          <Route
            path="/web-story/create"
            element={<PrivateRoute element={<CreateWebStory />} />}
          />
          <Route
            path="/web-story/update/:id"
            element={<PrivateRoute element={<CreateWebStory />} />}
          />
          {/* <Route
            path="/web-story"
            element={<PrivateRoute element={<ListWebStory />} />}
          /> */}
          <Route
            path="/web-story"
            element={<PrivateRoute element={<ListWebStory />} />}
          />

          <Route
            path="/story-report"
            element={<PrivateRoute element={<StoryReport />} rolesAllowed={['Admin']} />}
          />
          <Route
            path="/author-report"
            element={<PrivateRoute element={<AuthorReport />} rolesAllowed={['Admin']} />}
          />

          <Route
            path="/story/update/:id"
            element={<PrivateRoute element={<CreateStory />} />}
          />
          <Route
            path="/story"
            element={<PrivateRoute element={<ListStory />} />}
          />
          <Route
            path="/breaking-news"
            element={<PrivateRoute element={<BreakingNews />} rolesAllowed={['Admin']} />}
          />
          <Route
            path="/story/view/:id"
            element={<PrivateRoute element={<ViewStory />} />}
          />
          <Route
            path="/story/view"
            element={<PrivateRoute element={<ListStory />} />}
          />
          <Route
            path="/video-story"
            element={<PrivateRoute element={<ListVideoStory />} />}
          />
          <Route
            path="/video-story/create"
            element={<PrivateRoute element={<CreateVideoStory />} />}
          />
          <Route
            path="/video-story/update/:id"
            element={<PrivateRoute element={<CreateVideoStory />} />}
          />
          <Route
            path="/photo-story"
            element={<PrivateRoute element={<ListPhotoStory />} />}
          />

          <Route
            path="/photo-story/create"
            element={<PrivateRoute element={<CreatePhotoStory />} />}
          />
          <Route
            path="/photo-story/update/:id"
            element={<PrivateRoute element={<CreatePhotoStory />} />}
          />
          <Route
            path="/rss-feed"
            element={<PrivateRoute element={<RssFeed />} rolesAllowed={['Admin']} />}
          />
          <Route
            path="/rss-listing"
            element={<PrivateRoute element={<RssListing />} rolesAllowed={['Admin']} />}
          />
          <Route
            path="/common-master/:type"
            element={<PrivateRoute element={<CommonMaster />} rolesAllowed={['Admin']} />}
          />
          <Route
            path="/rss-breaking"
            element={<PrivateRoute element={<RssBreaking />} rolesAllowed={['Admin']} />}
          />
          <Route
            path="/edited-rss"
            element={<PrivateRoute element={<EditedRss />} rolesAllowed={['Admin']} />}
          />
          <Route
            path="/rss-breaking/edit/:id"
            element={<PrivateRoute element={<RssBreakingEdit />} rolesAllowed={['Admin']} />}
          />
          <Route
            path="/raftaar-rss-breaking"
            element={<PrivateRoute element={<RaftaarRssBreaking />} rolesAllowed={['Admin']} />}
          />
          <Route
            path="/rss-breaking-news/list"
            element={<PrivateRoute element={<RssBreakingNewsList />} rolesAllowed={['Admin']} />}
          />
          <Route
            path="/rss-breaking-news/create/:id"
            element={<PrivateRoute element={<RssBreakingNewsCreate />} rolesAllowed={['Admin']} />}
          />
          <Route
            path="/reports/author-performance"
            element={<PrivateRoute element={<AuthorPerformance />} />}
          />
          <Route
            path="/reports/author"
            element={<PrivateRoute element={<AuthorStory />} />}
          />
          <Route
            path="/reports/story-by-author"
            element={<PrivateRoute element={<StoryByAuthor />} />}
          />

        </Route>
      </Routes>
    </Router>
  );
};

// const AppRoutes = ({ isAuthenticated }) => {
//   return (
//     <HashRouter>
//       <Routes>
//         if {!isAuthenticated && (
//           <Route path="/login" element={<Login />} />
//         )}
//         else{(
//           <Route path="/" element={<Main />}>
//             <Route path="/dashboard" element={<Dashboard />} />

//             <Route path="/users/change-password" element={<ChangePassword />} />
//             <Route path="/users/profile" element={<Profile />} />

//             {/* Settings */}
//             <Route path="/settings/contacts" element={<Contacts />} />
//             <Route path="/settings/social-links" element={<SocialLinks />} />

//             {/* Contact Requests */}
//             <Route path="/contact-requests" element={<ContactRequests />} />

//             {/* Our Works */}
//             <Route path="/our-works" element={<ListWorks />} />

//             {/* SEO Content */}
//             <Route path="/seo-content" element={<SeoContent />} />
//             <Route path="/seo-content/update/:id" element={<UpdateSeo />} />

//             {/* eBook Subscribers */}
//             <Route path="/e-book-subscribers" element={<EbookSubscribers />} />

//             {/* Openings  */}
//             <Route path="/career/openings" element={<Openings />} />
//             <Route path="/career/post-job" element={<PostJob />} />

//           </Route>
//         )}
//       </Routes>
//     </HashRouter>
//   );
// }

export default AppRoutes;
