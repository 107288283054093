import React, { useEffect, useState } from 'react'
import { IoAddCircleOutline } from "react-icons/io5";
import { MdDelete } from "react-icons/md";
import config from "../../../config";
import { toast } from "react-toastify";
import axios from 'axios';

const GeneralSettings = () => {
    const [socialOptions, setSocialOptions] = useState([]);
    const [isLoading, setIsLoading] = useState(false)
    const [settings, setSettings] = useState({})


    const getSettings = async () => {
        const url = `${config.apiUrl}/general-settings`
        const response = await fetch(url)
        const data = await response?.json()

        setSettings(data)

        setSocialOptions(data?.socialOptions)
    }

    useEffect(() => {
        const fetchData = async () => {
            getSettings()
        }
        fetchData()
    }, [])

    const handleAdd = () => {
        setSocialOptions([...socialOptions, { name: '', link: '' }]);
    };

    const handleDelete = (index) => {
        setSocialOptions(socialOptions.filter((option, i) => i !== index));
    };

    const handleNameChange = (index, name) => {
        setSocialOptions(
            socialOptions.map((option, i) => {
                if (i === index) {
                    return { ...option, name };
                }
                return option;
            })
        );
    };

    const handleLinkChange = (index, link) => {
        setSocialOptions(
            socialOptions.map((option, i) => {
                if (i === index) {
                    return { ...option, link };
                }
                return option;
            })
        );
    };

    const handleImageChange = async (event) => {
        if (!event?.target?.files?.[0]) return

        await uploadImageToS3(event?.target?.files?.[0], 'publisherLogo')
    };

    const handleFaviconChange = async (event) => {
        if (!event?.target?.files?.[0]) return

        await uploadImageToS3(event.target.files[0], 'favicon');
    };

    const handleCmsLogoChange = async (event) => {
        if (!event?.target?.files?.[0]) return

        await uploadImageToS3(event.target.files[0], 'cmsLogo');
    }

    const saveSettings = async () => {
        try {
            setIsLoading(true)
            const url = `${config.apiUrl}/general-settings`
            const res = await fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ ...settings, socialOptions })
            })

            const data = await res.json()
            if (data?._id) {
                setSettings({})
                await getSettings();
                setTimeout(() => {
                    setIsLoading(false)
                    toast.success('settings updated..!')
                }, 500)
            }
        }
        catch (error) {
            console.error("Error: " + error?.message)
        }
    }

    const uploadImageToS3 = async (image, fieldName) => {
        axios.post(
            `${config?.apiUrl}/upload-files/image/s3`,
            { files: image },
            {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            }
        )
            .then(res => {
                const imagePath = config.awsBaseUrl + '/' + res?.data?.uploadedFiles?.[0]?.file_path
                setSettings({ ...settings, [fieldName]: imagePath })
            })
            .catch(error => {
                console.error("Error: " + error.message)
            })
    };

    if (isLoading) {
        return <>
            <div className="spinner story-form-spinner"></div>
        </>
    }

    return (
        <div className="content">
            <div className="content">
                <div className="card col-md-8 m-auto p-30">

                    <div className='setting-container'>
                        <div className='setting-container-left'>
                            <h3 className='fw-bold'>General Setting</h3>
                            <p>Control the general setting and configurations of your website</p>
                        </div>

                        <div className='setting-container-right'>
                            <div class="form-group">
                                <label for="publicationTitle" class="fw-bold">Publication Title</label>
                                <input type="text" class="form-control" id="publicationTitle" placeholder="Enter publication title" value={settings?.publicationTitle} onChange={(e) => { setSettings({ ...settings, publicationTitle: e?.target?.value }) }} />
                            </div>

                            <div class="form-group">
                                <label for="description" class="fw-bold">Description</label>
                                <input type="text" class="form-control" id="description" placeholder="Enter Description" value={settings?.description} onChange={(e) => { setSettings({ ...settings, description: e?.target?.value }) }} />
                            </div>

                            <div class="form-group">
                                <label for="copyRight" class="fw-bold">Google Analytics Id</label>
                                <input type="text" class="form-control" id="googleAnalyticsId" placeholder="Google Analytics Id" value={settings?.googleAnalyticsId} onChange={(e) => { setSettings({ ...settings, googleAnalyticsId: e?.target?.value }) }} />
                            </div>

                            <div class="form-group">
                                <label for="copyRight" class="fw-bold">Google Analytics Id For Webstories</label>
                                <input type="text" class="form-control" id="googleAnalyticsIdWebstories" placeholder="Google Analytics Id For Webstories" value={settings?.googleAnalyticsIdWebstories} onChange={(e) => { setSettings({ ...settings, googleAnalyticsIdWebstories: e?.target?.value }) }} />
                            </div>

                            <div class="form-group">
                                <label for="copyRight" class="fw-bold">Copy Right</label>
                                <input type="text" class="form-control" id="copyRight" placeholder="Enter Copy Right" value={settings?.copyRight} onChange={(e) => { setSettings({ ...settings, copyRight: e?.target?.value }) }} />
                            </div>

                            <div class="form-check">
                                <div>
                                    <input class="form-check-input" type="checkbox" id="enablePhotoStories" checked={settings?.enablePhotoStories} onChange={(e) => { setSettings({ ...settings, enablePhotoStories: e?.target?.checked }) }} />
                                    <p className='fw-bold'>Enable Photo Stories</p>
                                </div>
                            </div>

                            <div class="form-check">
                                <div>
                                    <input class="form-check-input" type="checkbox" id="enableVideoStories" checked={settings?.enableVideoStories} onChange={(e) => { setSettings({ ...settings, enableVideoStories: e?.target?.checked }) }} />
                                    <p className='fw-bold'>Enable Video Stories</p>
                                </div>
                            </div>

                            <div class="form-check">
                                <div>
                                    <input class="form-check-input" type="checkbox" id="enableFacebookSharing" checked={settings?.enableFacebookSharing} onChange={(e) => { setSettings({ ...settings, enableFacebookSharing: e?.target?.checked }) }} />
                                    <p className='fw-bold'>Enable Facebook Sharing</p>
                                </div>
                            </div>

                            <div class="form-check">
                                <div>
                                    <input class="form-check-input" type="checkbox" id="enableInstagramSharing" checked={settings?.enableInstagramSharing} onChange={(e) => { setSettings({ ...settings, enableInstagramSharing: e?.target?.checked }) }} />
                                    <p className='fw-bold'>Enable Instagram Sharing</p>
                                </div>
                            </div>

                            <div class="form-check">
                                <div>
                                    <input class="form-check-input" type="checkbox" id="enableTwitterSharing" checked={settings?.enableTwitterSharing} onChange={(e) => { setSettings({ ...settings, enableTwitterSharing: e?.target?.checked }) }} />
                                    <p className='fw-bold'>Enable Twitter Sharing</p>
                                </div>
                            </div>

                            {/* <div class="form-group">
                                <label for="androidNotificatonStyleI" class="fw-bold">Android Notification Style</label>
                                <select className='form-control'
                                    value={settings?.androidNotificationStyle}
                                    onChange={(e) => { setSettings({ ...settings, androidNotificationStyle: e?.target?.value }) }}
                                >
                                    <option value='Individual'>Individual</option>
                                    <option value='Individual-2'>Individual-2</option>
                                    <option value='Individual-3'>Individual-3</option>
                                    <option value='Individual-4'>Individual-4</option>
                                </select>
                            </div> */}

                            <div class="form-group">
                                <label for="instantAdsAutoplaced" class="fw-bold">Instant Articles Ad - Autoplaced</label>
                                <input type="text" class="form-control" id="instantAdsAutoplaced" placeholder="Instant Articles Ad - Autoplaced" value={settings?.instantAdsAutoplaced} onChange={(e) => { setSettings({ ...settings, instantAdsAutoplaced: e?.target?.value }) }} />
                            </div>


                            <div class="form-group">
                                <label for="instantAdsFirst" class="fw-bold">Instant Articles Ad - First</label>
                                <input type="text" class="form-control" id="instantAdsFirst" placeholder="Instant Articles Ad - First" value={settings?.instantAdsFirst} onChange={(e) => { setSettings({ ...settings, instantAdsFirst: e?.target?.value }) }} />
                            </div>


                            <div class="form-group">
                                <label for="additionalSitemaps" class="fw-bold">Additional Urls of sitemap</label>
                                <input type="text" class="form-control" id="additionalSitemaps" placeholder="Enter additional sitemaps" value={settings?.additionalSitemaps} onChange={(e) => { setSettings({ ...settings, additionalSitemaps: e?.target?.value }) }} />
                            </div>


                            <div className="form-group">
                                <h3 className='fw-bold'>Publisher Logo</h3>
                                {settings?.publisherLogo && (
                                    <div className='mt-20'>
                                        <img src={settings?.publisherLogo} alt="Image Preview" />
                                        {/* <button className='ml-20' onClick={handleImageRemove}>Remove Image</button> */}
                                    </div>
                                )}

                                {
                                    settings?.publisherLogo &&
                                    <p className='mt-30'>{"Logo Url:    "}<a className='fw-bold' href={settings?.publisherLogo} target="_blank">{settings?.publisherLogo?.slice(0, 60) + '...'}</a></p>
                                }

                                <input className='mt-20' type="file" accept="image/*" onChange={handleImageChange} />
                            </div>

                            <div className="form-group">
                                <h3 className='fw-bold'>Favicon</h3>
                                {settings?.favicon && (
                                    <div className='mt-20'>
                                        <img src={settings?.favicon} alt="Image Preview" />
                                        {/* <button className='ml-20' onClick={handleImageRemove}>Remove Image</button> */}
                                    </div>
                                )}

                                {
                                    settings?.favicon &&
                                    <p className='mt-30'>{"Favicon Url:    "}<a className='fw-bold' href={settings?.favicon} target="_blank">{settings?.favicon?.slice(0, 60) + '...'}</a></p>
                                }

                                <input type="file" accept=".ico" onChange={handleFaviconChange} />
                            </div>

                            <div className="form-group">
                                <h3 className='fw-bold'>CMS Logo</h3>
                                {settings?.cmsLogo && (
                                    <div className='mt-20' style={{height: '100px', width: '100px'}}>
                                        <img src={settings?.cmsLogo} alt="Image Preview" />
                                        {/* <button className='ml-20' onClick={handleImageRemove}>Remove Image</button> */}
                                    </div>
                                )}

                                {
                                    settings?.cmsLogo &&
                                    <p className='mt-30'>{"CMS Logo Url:    "}<a className='fw-bold' href={settings?.cmsLogo} target="_blank">{settings?.cmsLogo?.slice(0, 60) + '...'}</a></p>
                                }

                                <input type="file" accept="image/*" onChange={handleCmsLogoChange} />
                            </div>
                        </div>

                    </div>


                    <div className='setting-container mt-50'>
                        <div className='setting-container-left'>
                            <h3 className='fw-bold'>Home SEO</h3>
                            <p>Control websites Homepage SEO metadata</p>
                        </div>

                        <div className='setting-container-right'>
                            <div class="form-group">
                                <label for="pageTitle" class="fw-bold">Page Title</label>
                                <input type="text" class="form-control" id="pageTitle" placeholder="Enter page title" value={settings?.pageTitle} onChange={(e) => { setSettings({ ...settings, pageTitle: e?.target?.value }) }} />
                            </div>

                            <div class="form-group">
                                <label for="metaTitle" class="fw-bold">Meta Title</label>
                                <input type="text" class="form-control" id="metaTitle" placeholder="Enter meta title" value={settings?.metaTitle} onChange={(e) => { setSettings({ ...settings, metaTitle: e?.target?.value }) }} />
                            </div>

                            <div class="form-group">
                                <label for="metaDescription" class="fw-bold">Meta Description</label>
                                <input type="text" class="form-control" id="metaDescription" placeholder="Enter meta description" value={settings?.metaDescription} onChange={(e) => { setSettings({ ...settings, metaDescription: e?.target?.value }) }} />
                            </div>

                            <div class="form-group">
                                <label for="metaKeyWords" class="fw-bold">Meta KeyWords (comma separated)</label>
                                <textarea class="form-control" id="metaKeyWords" placeholder="Enter Meta Keywords" value={settings?.metaKeywords} onChange={(e) => { setSettings({ ...settings, metaKeywords: e?.target?.value?.split(',') }) }} />
                            </div>
                        </div>
                    </div>


                    <div className='setting-container mt-50'>
                        <div className='setting-container-left'>
                            <h3 className='fw-bold'>Social Links</h3>
                            <p>Add the social media details of the publisher</p>
                        </div>

                        <div className='setting-container-right'>
                            <div className='flex-row'>
                                <h3 className='fw-bold'>Social Networks</h3>
                                <h3 className='fw-bold ml-80'>Links</h3>
                            </div>
                            {socialOptions.map((option, index) => (
                                <div key={index} className='flex-row mt-10'>
                                    <select
                                        className='form-control'
                                        style={{ width: '50%' }}
                                        value={option.name}
                                        onChange={(e) => handleNameChange(index, e.target.value)}
                                    >
                                        <option value=''>select</option>
                                        <option value='facebook'>Facebook</option>
                                        <option value='twitter'>Twitter</option>
                                        <option value='youtube'>Youtube</option>
                                        <option value='whatsapp'>Whatsapp</option>
                                        <option value='instagram'>Instagram</option>
                                    </select>

                                    <input
                                        type="text"
                                        className="form-control ml-10"
                                        id="socialLink"
                                        placeholder="Enter link"
                                        value={option.link}
                                        onChange={(e) => handleLinkChange(index, e.target.value)}
                                    />

                                    <MdDelete size={40} className='cursor-pointer ml-10' onClick={() => handleDelete(index)} />
                                </div>
                            ))}
                            <IoAddCircleOutline size={40} className='cursor-pointer' onClick={handleAdd} />
                        </div>
                    </div>



                    <div className='text-align-center mt-50 settings-save' >
                        <button className="btn btn-primary" style={{ width: '10%' }} onClick={(e) => { e?.preventDefault(); saveSettings() }}>Save</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default GeneralSettings