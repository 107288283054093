import React, { useContext, useEffect, useRef, useState } from "react";
import usePageshook from "./hook";
import { Link } from "react-router-dom";
import Pagination from "../../common/pagination";
import moment from "moment";
import { UpdateDataApi, CreateDataApi, GetListApi } from "../../common/api/api";
import { apiEndpoints } from "../../common/api/apiEndpoints";
import Loader from "../../common/utils/loader";
import _ from "lodash";
import { CheckAccess } from "../../common/utils/roleAccess";
import Swal from "sweetalert2";
import AppContext from "../../appContext";
import { ConvertArrayTOString } from "../../common/utils/arrayToString";
import config from "../../../config";
import axios from "axios";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { MdRestoreFromTrash } from "react-icons/md";
import $ from 'jquery';
import 'daterangepicker';
import { useForm } from "react-hook-form";
import { toast } from 'react-toastify';

const animatedComponents = makeAnimated();


const ListStory = () => {
    const { register, handleSubmit, getValues, setValue, watch, formState: { errors }, } = useForm();

    const [template, setTemplate] = useState('');
    const [publishDate, setPublishDate] = useState('');
    const [updatedDate, setUpdatedDate] = useState('');
    const [section, setSection] = useState('');
    const [author, setAuthor] = useState('');
    const [loggedUser, setLoggedUser] = useState(null)
    const [filterSections, setFilterSections] = useState(null)
    const [filterSectionsNames, setFilterSectionsNames] = useState(null)
    const [rowId, setRowId] = useState(null)
    const [currentFilters, setCurrentFilters] = useState({})
    const pageSize = 50
    const [storyFilters, setStoryFilters] = useState([])
    const [selectedFilter, setSelectedFilter] = useState(null)
    const [savedFilterclick, setSavedFilterclick] = useState(false)
    const [filterCreating, setFilterCreating] = useState(false)
    const [filterUpdating, setFilterUpdating] = useState(false)

    const color = {
        All: 'black',
        Open: '#3d5a80',
        Submitted: '#98c1d9',
        Approved: '#e0fbfc',
        Scheduled: '#ee6c4d',
        Published: '#00a8e8'
    }

    const {
        totalPages,
        storiesList,
        handleDelete,
        currentPage,
        setCurrentPage,
        handlePageChange,
        navigate,
        fetchOurStory,
        loading,
        setLoading,
        setStoriesList,
        authorList,
        sections,
        authorsName,
        filterFields,
        setFilterFields,
        setTotalPages,
        searchWord,
        setSearchWord,
        currentTab,
        setCurrentTab,
        storiesCount,
        setStoriesCount,
        populateFilterData,
        setPopulateFilterData
    } = usePageshook();

    const { setAppData } = useContext(AppContext);
    const [isTab, setIsTab] = useState("All");
    const userData = localStorage.getItem("user");
    const user = userData ? JSON.parse(userData) : null;
    useEffect(() => {
        setAppData("");

        const userData = localStorage.getItem("user");
        const user = userData ? JSON.parse(userData) : null;

        setLoggedUser(user)

        // getSections()
    }, []);

    useEffect(() => {
        setCurrentTab(isTab)
    }, [isTab])

    const fetchStoryFilters = async () => {
        const data = await GetListApi(`story-filters?created_by=${user?._id}&status=Active`)
        setStoryFilters(data?.data)
    }

    useEffect(() => {
        const storyFilters = JSON.parse(localStorage.getItem('storyFilters'))
        setCurrentFilters(storyFilters)
        fetchStoryFilters()
    }, [])

    useEffect(() => {
        // if (filterFields?.template) setTemplate(filterFields?.template)
        // if (filterFields?.section) setSection(filterFields?.section)
        // if (filterFields?.updatedDate) setUpdatedDate(filterFields?.updatedDate)
        // if (filterFields?.publishDate) setPublishDate(filterFields?.publishDate)
        // if (filterFields?.author) setAuthor(filterFields?.author)

        // if (filterFields?.template)  
        setTemplate(filterFields?.template || '')
        // if (filterFields?.section) 
        setSection(filterFields?.section || null)
        // if (filterFields?.updatedDate) 
        setUpdatedDate(filterFields?.updatedDate || '')
        // if (filterFields?.publishDate) 
        setPublishDate(filterFields?.publishDate || '')
        // if (filterFields?.author) 
        setAuthor(filterFields?.author || '')
    }, [filterFields])

    const getSections = (sectionName) => {
        axios.get(`${config.apiUrl}/section/with/parent-chain`, { params: { status: "Active", ...(sectionName && { searchKeyWord: sectionName }) } })
            .then((response) => {
                setFilterSections(response?.data?.sections)

                let names = response?.data?.sections?.map(section => ({ "value": section?._id, "label": section?.parent_chain }))
                names = [{ value: null, label: 'none' }, ...names]
                setFilterSectionsNames(names)
            });
    }

    useEffect(() => {
        if (template !== '') setFilterFields({ ...filterFields, template })
        if (section !== '') setFilterFields({ ...filterFields, section })
        if (author !== '') setFilterFields({ ...filterFields, author })
        if (updatedDate !== '') setFilterFields({ ...filterFields, updatedDate })
        if (publishDate !== '') setFilterFields({ ...filterFields, publishDate })
    }, [template, section, author, updatedDate, publishDate])

    useEffect(() => {
        if (savedFilterclick) {
            const filters = {}

            // if (selectedFilter?.template)
            filters.template = selectedFilter?.template

            // if (selectedFilter?.updated_date) {
            filters.updatedDate = selectedFilter?.updated_date
            setUpdatedDate(selectedFilter?.updated_date)
            // }

            // if (selectedFilter?.published_date) {
            filters.publishDate = selectedFilter?.published_date
            setPublishDate(selectedFilter?.published_date)
            // }

            // if (selectedFilter?.section) 
            filters.section = selectedFilter?.section

            // if (selectedFilter?.author) 
            filters.author = selectedFilter?.author

            setValue('filterName', selectedFilter?.name || '')
            setFilterFields(filters)
            setValue('applyFilters', true)
        }
    }, [selectedFilter, savedFilterclick])

    useEffect(() => {
        if (watch('applyFilters')) {
            setValue('applyFilters', false)
            filterButtonClick('', '', '')
        }
    }, [filterFields])

    const filterButtonClick = async ({ status = '', story_author = '', story_type = '' }) => {
        setStoriesList([])
        setLoading(true)
        fetchOurStory(status, {}, '', story_type, story_author)
        setCurrentPage(1)
        setStoriesCount(0)

        setCurrentFilters({ ...filterFields })
        // localStorage.setItem('storyFilters', JSON.stringify({ ...filterFields }))

        return

        let url = `${config.apiUrl}/stories?page=1&pageSize=50&pagination=true&isBackend=true`

        if (status !== "All") url += `&status=${status?.toLowerCase()}`
        if (status === '' && isTab !== 'All') url += `&status=${isTab?.toLowerCase()}`

        if (template && template !== 'select story type') url += `&story_type=${template}`
        if (story_type) url += `&story_type=${story_type}`

        if (author && author !== 'select author') url += `&authors.label=${author}`
        if (story_author) url += `&authors.label=${story_author}`

        if (filterFields?.section?.value) url += `&section=${filterFields?.section?.value}`

        if (publishDate) {
            let publish_date = new Date(publishDate).toISOString()
            url += `&publishDate=${publish_date}`
        }

        if (updatedDate) {
            let updated_date = new Date(updatedDate).toISOString()
            url += `&updateDate=${updated_date}`
        }

        if (searchWord) url += `&q=${searchWord}`

        if (loggedUser?.user_role?.name === 'Guest Contributor') {
            url += `&authorId=${loggedUser?._id}`
        }

        const response = await fetch(url)
        const data = await response.json()

        setStoriesList(data?.stories)
        setTotalPages(data?.pages);
        setStoriesCount(roundStoriesCount(data?.count))

        setLoading(false)
    }

    const roundStoriesCount = count => {
        if (count >= 1000) {
            return (count / 1000)?.toFixed(1) + 'k';
        }
        return count?.toString();
    }

    const getChildStoryId = async (story) => {
        setLoading(true)

        try {
            let childStoryId = null

            if (story?.child_story_id) {
                childStoryId = story?.child_story_id
            }
            else if (story.status === 'published' || (story.status === 'scheduled' && story?.publish_date <= new Date().toISOString())) {
                const response = await fetch(`${config.apiUrl}/stories/copy-story/${story?._id}`)
                const responseData = await response.json()
                const copyStory = responseData?.story

                const cards = copyStory?.cards
                let newCards = []

                for (let card of cards) {
                    delete card._id
                    delete card.id
                    const response = await CreateDataApi(`card/create`, card);

                    newCards.push(response?.savedCard?._id)
                }

                copyStory.cards = newCards

                delete copyStory._id
                delete copyStory.id
                copyStory.is_child_story = true
                copyStory.parent_story_id = story._id
                copyStory.status = 'open'
                copyStory.slug = copyStory?.slug + '-child-story'
                copyStory.title = copyStory.title + ' '

                const childStory = await CreateDataApi(`stories/create-child-story`, copyStory);

                await UpdateDataApi(`${apiEndpoints?.storiesById(story?._id)}`, { child_story_id: childStory?.story?._id })

                childStoryId = childStory?.story?._id
            }

            if (story.status !== 'published' && !(story?.status === 'scheduled' && story?.publish_date <= new Date().toISOString())) {
                childStoryId = story?._id
            }

            const url = story?.story_type === 'Text Story' ? `/story/update/${childStoryId}` : story?.story_type === 'Web Story' ? `/web-story/update/${childStoryId}` : story?.story_type === 'Photo Story' ? `/photo-story/update/${childStoryId}` : `/video-story/update/${childStoryId}`
            setLoading(false)
            navigate(url)
        }
        catch (error) {
            setLoading(false)
            console.error(error)
        }
    }

    const checkStoryIsPublished = (story) => {
        if (story?.status === 'scheduled') {
            const currentTime = new Date().toISOString()
            const publishTime = story?.publish_date

            if (publishTime && publishTime <= currentTime) {
                return true
            }
        }
        return false
    }


    const restoreStory = async (storyId) => {
        if (!storyId) return
        setLoading(true)
        const userData = localStorage.getItem("user");
        const user = userData ? JSON.parse(userData) : null;

        await UpdateDataApi(
            `${apiEndpoints.storiesById(storyId)}`,
            {
                status: 'open',
                restored_by: user?._id,
                restored_on: new Date().toISOString()
            }
        );
        fetchOurStory("deleted")
    }

    const storyAuthorClicked = (author) => {
        setAuthor(author?.[0]?.label);
        setFilterFields(prevFields => ({ ...prevFields, author: author?.[0]?.label }));
        filterButtonClick({ status: '', story_author: author?.[0]?.label, story_type: '' });
        setFlag2(true)
    };

    const storyTypeClicked = (story_type) => {
        setTemplate(story_type);
        setFilterFields(prevFields => ({ ...prevFields, template: story_type }));
        filterButtonClick({ status: '', story_author: '', story_type: story_type });
        setFlag2(true)
    };

    const [flag, setFlag] = useState(false)

    const [flag2, setFlag2] = useState(false)

    const removeFilter = (key) => {
        setLoading(true)
        let tempFilterFields = { ...filterFields }
        delete tempFilterFields[key]
        setFilterFields({ ...tempFilterFields })
        setFlag(true)

        if (key === 'template') setTemplate('')
        if (key === 'updatedDate') {
            setUpdatedDate('');
            let inputElement = $('input[name="daterangeUpdate"]');
            inputElement.val('');
        }

        if (key === 'publishDate') {
            setPublishDate('');
            let inputElement = $('input[name="daterangePublish"]');
            inputElement.val('');
        }

        if (key === 'section') setSection('')
        if (key === 'author') setAuthor('')
    }

    useEffect(() => {
        if (flag2) {
            setCurrentFilters({ ...filterFields })
            setFlag2(false)
        }
        if (filterFields && flag) {
            setCurrentFilters({ ...filterFields })
            // localStorage.setItem('storyFilters', JSON.stringify({ ...filterFields }))
            fetchOurStory()
            setFlag(false)
        }

    }, [flag, filterFields])


    useEffect(() => {
        const inputElement = $('input[name="daterangeUpdate"]');

        $('input[name="daterangeUpdate"]').daterangepicker({
            locale: {
                format: 'DD/MM/YYYY'
            },
            autoUpdateInput: false
        }, function (start, end, label) {
            let selectedDateRange = `${start.format('DD/MM/YYYY')} - ${end.format('DD/MM/YYYY')}`
            inputElement.val(selectedDateRange);

            selectedDateRange = `${start.format('YYYY-MM-DD')} - ${end.format('YYYY-MM-DD')}`
            setUpdatedDate(selectedDateRange);
            // setFilterFields({ ...filterFields, updatedDate: selectedDateRange })
        });
    }, []);

    useEffect(() => {
        const inputElement = $('input[name="daterangePublish"]');

        $('input[name="daterangePublish"]').daterangepicker({
            locale: {
                format: 'DD/MM/YYYY'
            },
            autoUpdateInput: false
        }, function (start, end, label) {
            let selectedDateRange = `${start.format('DD/MM/YYYY')} - ${end.format('DD/MM/YYYY')}`
            inputElement.val(selectedDateRange);

            selectedDateRange = `${start.format('YYYY-MM-DD')} - ${end.format('YYYY-MM-DD')}`
            setPublishDate(selectedDateRange);
            // setFilterFields({ ...filterFields, publishDate: selectedDateRange })
        });
    }, []);

    // if (loading) {
    //     return <Loader />
    // }

    const handleRejectStory = async (id) => {
        if (!id) return

        const response = await UpdateDataApi(
            `${apiEndpoints.unpublishStory(id)}`,
            { status: 'open' }
        );

        if (response) {
            const timeline = await CreateDataApi(apiEndpoints?.timeline, {
                story_id: response?._id,
                action_by: user?._id,
                action_on: moment().format("MMMM DD YYYY, h:mm:ss a"),
                action_type: 'open',
            });
            await filterButtonClick(isTab)
        }
    };

    const saveFiltersToDB = async (data, createNew = false) => {
        try {
            const filters = {}

            if (filterFields?.template) filters.template = filterFields?.template
            if (filterFields?.section) filters.section = filterFields?.section
            if (filterFields?.author) filters.author = filterFields?.author
            if (updatedDate) filters.updated_date = updatedDate
            if (publishDate) filters.published_date = publishDate

            const keys = Object.keys(filters)

            if (!keys?.length) {
                toast.error('Please select atleast one filter')
                return
            }

            if (createNew) setFilterCreating(true)
            else setFilterUpdating(true)

            filters.name = getValues('filterName')
            filters.userId = user?._id

            const result = (selectedFilter && !createNew) ? await UpdateDataApi(`story-filters/${selectedFilter?._id}`, filters) : await CreateDataApi('story-filters', filters)

            await fetchStoryFilters()
        }
        catch (error) {
            toast.error(error?.message)
        }
        finally {
            setFilterCreating(false)
            setFilterUpdating(false)
        }
    }

    const deleteSavedFilters = async (id) => {
        if (!id) {
            toast.error('something went wrong')
            return
        }

        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then((result) => {
            if (result.isConfirmed) {
                axios
                    .delete(`${config.apiUrl}/story-filters/${id}`)
                    .then((response) => {
                        toast.success('Deleted Successfully')
                        fetchStoryFilters()
                    })
                    .catch((error) => {
                        toast.error('something went wrong')
                        console.error("Error deleting item:", error?.message);
                    });
            }
        });
    }

    const closeCanvas = () => {
        const closeButton = document.getElementById('offcanvasCloseButton');
        console.log({ closeButton })
        if (closeButton) {
            closeButton.click();
        }
    }

    return (
        <div style={{ backgroundColor: '#f9f9f9' }}>
            <div className="offcanvas offcanvas-end" tabindex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
                <div className="offcanvas-header">
                    <button type="button" id="offcanvasCloseButton" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                    <h5 id="offcanvasRightLabel">Filters</h5>
                    <button onClick={filterButtonClick} className="btn btn-primary btn-round btn-sm" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight">Apply Filters</button>
                </div>
                <div className="offcanvas-body">
                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                        <li className="nav-item" style={{ width: "49%" }} role="presentation">
                            <button className="nav-link active" style={{ width: "100%" }} id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true">Options</button>
                        </li>
                        <li className="nav-item" style={{ width: "50%" }} role="presentation">
                            <button className="nav-link" style={{ width: "100%" }} id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="false">Saved</button>
                        </li>
                    </ul>
                    <div className="tab-content" id="myTabContent">
                        <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                            <form onSubmit={handleSubmit(saveFiltersToDB)}>
                                <div className="row mb-30 mt-30">
                                    <label htmlFor="meta_description" className="col-sm-4 col-form-label text-right">
                                        Story Template
                                    </label>
                                    <div className="col-sm-8">
                                        <select
                                            name="custom_slug"
                                            className="form-control select2"
                                            style={{ width: '100%' }}
                                            data-placeholder="Select a section"
                                            value={template}
                                            onChange={(e) => { setTemplate(e.target.value); setFilterFields({ ...filterFields, template: e?.target?.value }) }}
                                        >
                                            <option > select story type</option>
                                            <option value={'Text Story'}> Text Story</option>
                                            <option value={'Web Story'}> Web Story</option>
                                            <option value={'Photo Story'}> Photo Story</option>
                                            <option value={'Video Story'}> Video Story</option>
                                            <option value={'Live Blog'}> Live Blog</option>
                                        </select>
                                    </div>
                                </div>

                                <div className="row mb-30 mt-30">
                                    <label htmlFor="meta_description" className="col-sm-4 col-form-label text-right">
                                        Updated Date
                                    </label>
                                    <div className="col-sm-8">
                                        {/* <input
                                        type="date"
                                        name="custom_slug"
                                        className="form-control"
                                        value={updatedDate}
                                        onChange={(e) => { setUpdatedDate(e.target.value); setFilterFields({ ...filterFields, updatedDate: e.target.value }) }}
                                    /> */}
                                        <input type="text" value={updatedDate} name="daterangeUpdate" placeholder="Select Update date range" />
                                    </div>
                                </div>

                                <div className="row mb-30 mt-30">
                                    <label htmlFor="meta_description" className="col-sm-4 col-form-label text-right">
                                        Published Date
                                    </label>
                                    <div className="col-sm-8">
                                        {/* <input
                                        type="date"
                                        name="custom_slug"
                                        className="form-control"
                                        value={publishDate}
                                        onChange={(e) => { setPublishDate(e.target.value); setFilterFields({ ...filterFields, publishDate: e.target.value }) }}
                                    /> */}

                                        <input type="text" value={publishDate} name="daterangePublish" placeholder="Select publish date range" />
                                    </div>
                                </div>

                                <div className="row mb-30 mt-30">
                                    <label htmlFor="meta_description" className="col-sm-4 col-form-label text-right">
                                        Section
                                    </label>
                                    <div className="col-sm-8">

                                        <Select
                                            className={`outline-none`}
                                            name="section"
                                            closeMenuOnSelect={true}
                                            components={animatedComponents}
                                            options={filterSectionsNames}
                                            value={section}
                                            onChange={(e) => {
                                                setSection(e)
                                                setFilterFields({ ...filterFields, section: e });
                                            }}
                                            onInputChange={(inputValue) => {
                                                if (inputValue?.length > 3)
                                                    getSections(inputValue)
                                            }}
                                        ></Select>
                                    </div>
                                </div>

                                {
                                    loggedUser && loggedUser?.user_role?.name !== 'Guest Contributor' &&

                                    <div className="row mb-30 mt-30">
                                        <label htmlFor="meta_description" className="col-sm-4 col-form-label text-right">
                                            Author
                                        </label>
                                        <div className="col-sm-8">
                                            <select
                                                name="custom_slug"
                                                className="form-control select2"
                                                style={{ width: '100%' }}
                                                data-placeholder="Select a section"
                                                value={author}
                                                onChange={(e) => { setAuthor(e.target.value); setFilterFields({ ...filterFields, author: e.target.value }) }}
                                            >
                                                <option > select author</option>

                                                {authorsName.map((author, index) => (
                                                    <option key={index} value={author}>
                                                        {author}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                }

                                <div className="row mb-30 mt-30">
                                    <label htmlFor="filterName" className="col-sm-4 col-form-label text-right">Filter Name</label>
                                    <div className="col-sm-8">
                                        <input id="filterName" className="form-control " {...register('filterName', { required: 'This is required' })} />
                                        {errors?.filterName && <span className="text-danger">{errors.filterName.message}</span>}
                                    </div>
                                </div>

                                {
                                    selectedFilter &&
                                    <div className="row d-flex-center mt-10">
                                        <button onClick={(e) => { e.preventDefault(); saveFiltersToDB({}, false) }} disabled={filterUpdating} className='btn-sm btn btn-primary col-4'>
                                            {filterUpdating ? <div className="spinner-border spinner-border-sm tc-submit-loading" role="status"> </div> : `Update ${selectedFilter?.name}`}
                                        </button>
                                    </div>
                                }

                                <div className="row d-flex-center mt-40">
                                    <button disabled={filterCreating} className='btn-sm btn btn-primary col-4'>
                                        {filterCreating ? <div className="spinner-border spinner-border-sm tc-submit-loading" role="status"> </div> : "Create New"}
                                    </button>
                                </div>
                            </form>
                        </div>
                        <div className="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                            <div className="table table-responsive mt-10">
                                <table className="table table-hover">
                                    <tr>
                                        <th> Name</th>
                                        <th className="text-align-center"> Delete</th>
                                    </tr>
                                    <tbody>
                                        {
                                            storyFilters?.map((item, index) => {
                                                return (
                                                    <tr className="cursor-pointer" >
                                                        <td className="" onClick={() => { setSelectedFilter(item); setSavedFilterclick(true); closeCanvas() }}>{item?.name}</td>
                                                        <td className="text-align-center" onClick={(e) => { deleteSavedFilters(item?._id) }}> <i className="fa fa-trash"></i></td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {
                false && loading ?
                    <Loader />
                    :
                    <div className="content" style={{ backgroundColor: '#f9f9f9' }}>
                        <div className="content">
                            <div className="card col-md-12 m-auto p-30">
                                <div className="card-header">
                                    <h3 className="text-bold">Story</h3>
                                    <input
                                        type="search"
                                        placeholder="Enter title or author"
                                        className="form-control story-search-input"
                                        // onChange={(e) => {
                                        //     setSearchWord(e.target.value)
                                        //     setCurrentPage(1)
                                        //     fetchOurStory("", e?.target?.value);
                                        // }}
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter') {
                                                setSearchWord(e.target.value)
                                                setStoriesList([])
                                                setLoading(true)
                                                setCurrentPage(1)
                                                fetchOurStory('', {}, e?.target?.value);
                                            }
                                        }}
                                    />

                                    <button className="btn btn-primary btn-round btn-sm" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight" onClick={() => { if (populateFilterData === false) { setPopulateFilterData(true); getSections() } }}>Filter</button>

                                </div>

                                <div className="filters-container">
                                    {
                                        Object.keys(currentFilters || {})?.length > 0 && <p>Applied Filters: </p>
                                    }

                                    {
                                        Object.keys(currentFilters || {}).map(key => (
                                            <div>
                                                {
                                                    (currentFilters[key]?.label || currentFilters[key]) &&
                                                    <p className="current-filters ml-10">{key}: {currentFilters[key]?.label || currentFilters[key]} <span className='filter-close' onClick={() => { removeFilter(key) }}>X</span></p>
                                                }
                                            </div>
                                        ))
                                    }
                                </div>
                                <div className="card-body">
                                    <div className={`row mb-15 d-flex justify-content-around`}>
                                        <div
                                            className={
                                                isTab === "All"
                                                    ? "px-0 col-md-1 text-center active-stepper-form-head text-bold mb-0"
                                                    : "px-0 col-md-1 text-center text-bold mb-0"
                                            }
                                            style={{ borderBottom: `${isTab === 'All' ? `3px solid ${color[isTab]}` : 'none'}` }}
                                            role="button"
                                            onClick={(e) => {
                                                setIsTab("All");
                                                // fetchOurStory("");
                                                filterButtonClick({ status: 'All' })
                                            }}
                                        >
                                            All {isTab === 'All' && <span className='stories-count'>{storiesCount}</span>}
                                        </div>
                                        <div
                                            className={
                                                isTab === "Open"
                                                    ? "px-0 col-md-1 text-center active-stepper-form-head text-bold mb-0"
                                                    : "px-0 col-md-1 text-center text-bold mb-0"
                                            }
                                            style={{ borderBottom: `${isTab === 'Open' ? `3px solid ${color[isTab]}` : 'none'}` }}
                                            role="button"
                                            onClick={(e) => {
                                                setIsTab("Open");
                                                // fetchOurStory("open");
                                                filterButtonClick({ status: 'Open' })
                                            }}
                                        >
                                            Open {isTab === 'Open' && <span className='stories-count'>{storiesCount}</span>}
                                        </div>
                                        <div
                                            className={
                                                isTab === "Submitted"
                                                    ? "px-0 col-md-1 text-center active-stepper-form-head text-bold mb-0"
                                                    : "px-0 col-md-1 text-center text-bold mb-0"
                                            }
                                            style={{ borderBottom: `${isTab === 'Submitted' ? `3px solid ${color[isTab]}` : 'none'}` }}
                                            role="button"
                                            onClick={(e) => {
                                                setIsTab("Submitted");
                                                // fetchOurStory("submitted");
                                                filterButtonClick({ status: 'Submitted' })
                                            }}
                                        >
                                            Submitted {isTab === 'Submitted' && <span className='stories-count'>{storiesCount}</span>}
                                        </div>
                                        <div
                                            className={
                                                isTab === "Approved"
                                                    ? "px-0 col-md-1 text-center active-stepper-form-head text-bold mb-0"
                                                    : "px-0 col-md-1 text-center text-bold mb-0"
                                            }
                                            style={{ borderBottom: `${isTab === 'Approved' ? `3px solid ${color[isTab]}` : 'none'}` }}
                                            role="button"
                                            onClick={(e) => {
                                                setIsTab("Approved");
                                                // fetchOurStory("approved");
                                                filterButtonClick({ status: 'Approved' })
                                            }}
                                        >
                                            Approved {isTab === 'Approved' && <span className='stories-count'>{storiesCount}</span>}
                                        </div>
                                        <div
                                            className={
                                                isTab === "Published"
                                                    ? "px-0 col-md-1 text-center active-stepper-form-head text-bold mb-0"
                                                    : "px-0 col-md-1 text-center text-bold mb-0"
                                            }
                                            style={{ borderBottom: `${isTab === 'Published' ? `3px solid ${color[isTab]}` : 'none'}` }}
                                            role="button"
                                            onClick={(e) => {
                                                setIsTab("Published");
                                                // fetchOurStory("published");
                                                filterButtonClick({ status: 'Published' })
                                            }}
                                        >
                                            Published {isTab === 'Published' && <span className='stories-count'>{storiesCount}</span>}
                                        </div>
                                        <div
                                            className={
                                                isTab === "Rejected"
                                                    ? "px-0 col-sm-1 text-center active-stepper-form-head text-bold mb-0"
                                                    : "px-0 col-sm-1 text-center text-bold mb-0"
                                            }
                                            role="button"
                                            onClick={(e) => {
                                                setIsTab("Rejected");
                                                // fetchOurStory("rejected");
                                                filterButtonClick({ status: 'Rejected' })
                                            }}
                                        >
                                            Rejected {isTab === 'Rejected' && <span className='stories-count'>{storiesCount}</span>}
                                        </div>
                                        <div
                                            className={
                                                isTab === "Retract"
                                                    ? "px-0 col-sm-1 text-center active-stepper-form-head text-bold mb-0"
                                                    : "px-0 col-sm-1 text-center text-bold mb-0"
                                            }
                                            role="button"
                                            onClick={(e) => {
                                                setIsTab("Retract");
                                                // fetchOurStory("Retract");
                                                filterButtonClick({ status: 'Retract' })
                                            }}
                                        >
                                            Retract {isTab === 'Retract' && <span className='stories-count'>{storiesCount}</span>}
                                        </div>
                                        <div
                                            className={
                                                isTab === "Scheduled"
                                                    ? "px-0 col-sm-1 text-center active-stepper-form-head text-bold mb-0"
                                                    : "px-0 col-sm-1 text-center text-bold mb-0"
                                            }
                                            style={{ borderBottom: `${isTab === 'Scheduled' ? `3px solid ${color[isTab]}` : 'none'}` }}
                                            role="button"
                                            onClick={(e) => {
                                                setIsTab("Scheduled");
                                                // fetchOurStory("scheduled");
                                                filterButtonClick({ status: 'Scheduled' })
                                            }}
                                        >
                                            Scheduled {isTab === 'Scheduled' && <span className='stories-count'>{storiesCount}</span>}
                                        </div>

                                        <div
                                            className={
                                                isTab === "Deleted"
                                                    ? "px-0 col-sm-1 text-center active-stepper-form-head text-bold mb-0"
                                                    : "px-0 col-sm-1 text-center text-bold mb-0"
                                            }
                                            role="button"
                                            onClick={(e) => {
                                                setIsTab("Deleted");
                                                // fetchOurStory("deleted");
                                                filterButtonClick({ status: 'Deleted' })
                                            }}
                                        >
                                            Deleted {isTab === 'Deleted' && <span className='stories-count'>{storiesCount}</span>}
                                        </div>
                                    </div>

                                    <div className="table-responsive">
                                        <table className="table table-hover">
                                            <tbody>
                                                {loading ? (
                                                    <Loader />
                                                ) : (
                                                    storiesList?.map((story, index) => {
                                                        return (
                                                            <tr key={story?._id}>
                                                                <td style={{ lineHeight: '0.8em', widht: '95%' }} >

                                                                    <div style={{ display: 'flex', justifyContent: 'left', }}>
                                                                        <p className="text-capitalize" style={{ backgroundColor: "grey", padding: '3px', textTransform: 'capitalize', fontSize: '10px', fontWeight: '500', color: 'white', borderRadius: '2px' }}>
                                                                            {checkStoryIsPublished(story) ? 'PUBLISHED' : story?.status.toString().toUpperCase()}
                                                                        </p>
                                                                        <p className="story-type" onClick={() => { storyTypeClicked(story?.story_type) }} style={{ marginLeft: '10px', backgroundColor: "#DCDCDC", padding: '3px', textTransform: 'capitalize', fontSize: '10px', fontWeight: '500', color: 'black', borderRadius: '2px' }}>
                                                                            {story?.story_type === 'Live Blog' ? "Live Blog" : story?.story_type?.toLowerCase()?.split(' ')?.[0]?.toUpperCase()}
                                                                        </p>
                                                                        {
                                                                            story?.story_type === 'Live Blog' &&
                                                                            <p className="" style={{ marginLeft: '10px', backgroundColor: "#ef5350", padding: '3px', textTransform: 'capitalize', fontSize: '10px', fontWeight: '500', color: 'white', borderRadius: '2px' }}>
                                                                                {story?.is_live ? 'Live' : 'Offline'}
                                                                            </p>
                                                                        }

                                                                    </div>

                                                                    <p
                                                                        // onClick={() => { if (CheckAccess('story', 'update')) getChildStoryId(story) }}
                                                                        onMouseLeave={(e) => e.target.style.color = 'initial'}
                                                                        style={{ cursor: 'pointer', fontSize: '1.3rem', lineHeight: '1.4' }}
                                                                    >
                                                                        <a href={story?.story_type === 'Text Story' ? `/story/update/${story?._id}` : story?.story_type === 'Web Story' ? `/web-story/update/${story?._id}` : story?.story_type === 'Photo Story' ? `/photo-story/update/${story?._id}` : story?.story_type === 'Live Blog' ? `/live-blog/update/${story?._id}` : `/video-story/update/${story?._id}`} target={story?.story_type === 'Live Blog' ? '_blank' : ''}>
                                                                            {story?.title}
                                                                        </a>

                                                                    </p>

                                                                    <div style={{ display: 'flex', justifyContent: 'left', fontSize: '1rem', height: '1.2em' }}>

                                                                        <p className="text-capitalize story-author" style={{ color: '#5f6978' }} onClick={() => { storyAuthorClicked(story?.authors) }}>
                                                                            <svg style={{ marginRight: '5px' }} data-test-id="user" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" width="16" fill="#5f6978" height="16" viewBox="0 0 24 24"><path fill="transparent" d="M.03.13h24v24h-24z"></path><path d="M18.18 14.27A3.69 3.69 0 0 1 20.43 16a3.91 3.91 0 0 1 .57 2v1a2.15 2.15 0 0 1-.67 1.58 2.17 2.17 0 0 1-1.58.67H5.28a2.17 2.17 0 0 1-1.58-.65A2.15 2.15 0 0 1 3 19v-1a4.13 4.13 0 0 1 .6-2.11 3.74 3.74 0 0 1 2.25-1.65l1.72-.49a6.2 6.2 0 0 1-1.48-2.5 6.45 6.45 0 0 1-.28-1.83A6.15 6.15 0 0 1 12 3.27a6.16 6.16 0 0 1 6.19 6.18 6.45 6.45 0 0 1-.28 1.83 6.09 6.09 0 0 1-1.48 2.5zM19.91 19v-1a2.72 2.72 0 0 0-.57-1.67 2.76 2.76 0 0 0-1.47-1l-2.46-.71a6.09 6.09 0 0 1-6.75 0l-2.46.71a2.79 2.79 0 0 0-1.48 1A2.71 2.71 0 0 0 4.16 18v1a1.13 1.13 0 0 0 1.12 1.12h13.5a1.07 1.07 0 0 0 .79-.33 1.1 1.1 0 0 0 .34-.79zM12 4.39a4.86 4.86 0 0 0-2.53.69 5.18 5.18 0 0 0-1.82 1.84 5.07 5.07 0 0 0 0 5.07 5.26 5.26 0 0 0 1.85 1.84 5 5 0 0 0 5.06 0A5.18 5.18 0 0 0 16.41 12a4.89 4.89 0 0 0 .68-2.54 4.88 4.88 0 0 0-.68-2.53 5.1 5.1 0 0 0-1.85-1.84 4.83 4.83 0 0 0-2.56-.7z"></path></svg>
                                                                            {story?.authors?.length > 0
                                                                                ? ConvertArrayTOString(
                                                                                    story?.authors,
                                                                                    "collection"
                                                                                )
                                                                                : "-"}
                                                                        </p>



                                                                        <p style={{ color: '#5f6978', marginLeft: '15px' }}>
                                                                            <svg style={{ marginRight: '5px' }} width="16" height="16" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#5F6978"><path d="M12.18 3.22a8.52 8.52 0 0 1 4.36 1.18 8.81 8.81 0 0 1 3.18 3.18 8.42 8.42 0 0 1 1.18 4.36 8.4 8.4 0 0 1-1.18 4.36 8.66 8.66 0 0 1-3.18 3.18 8.64 8.64 0 0 1-8.72 0 8.74 8.74 0 0 1-3.18-3.18 8.4 8.4 0 0 1-1.18-4.36 8.42 8.42 0 0 1 1.18-4.36A8.89 8.89 0 0 1 7.82 4.4a8.52 8.52 0 0 1 4.36-1.18zm7.59 8.72a7.4 7.4 0 0 0-1-3.81 7.61 7.61 0 0 0-6.59-3.78 7.35 7.35 0 0 0-3.79 1A7.64 7.64 0 0 0 4.59 12a7.39 7.39 0 0 0 1 3.8 7.61 7.61 0 0 0 6.59 3.78 7.39 7.39 0 0 0 3.8-1A7.66 7.66 0 0 0 19.75 12zM14.54 15a.44.44 0 0 0 .31.09.42.42 0 0 0 .28-.16l.29-.42a.43.43 0 0 0 .08-.3.35.35 0 0 0-.16-.27l-2.49-1.83V7a.41.41 0 0 0-.12-.3.44.44 0 0 0-.3-.12h-.5a.4.4 0 0 0-.29.12.38.38 0 0 0-.13.3v5.63a.33.33 0 0 0 .18.31z"></path></svg>
                                                                            {moment(isTab === 'Published' ? story?.publish_date : story?.updated_on).format("DD MMMM YYYY, hh:mm A")}
                                                                        </p>

                                                                        {
                                                                            story?.status === 'scheduled' && story?.publish_date >= new Date().toISOString() &&
                                                                            <p style={{ color: '#5f6978', marginLeft: '15px' }}>
                                                                                <svg style={{ marginRight: '5px' }} id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16" height="16" fill="var(--mono-4)"><title>calendar</title><path d="M18.23,5.07a1.71,1.71,0,0,1,1.71,1.71V19.35a1.71,1.71,0,0,1-1.71,1.72H5.66a1.71,1.71,0,0,1-1.72-1.72V6.78A1.71,1.71,0,0,1,5.66,5.07H7.37V3.21a.38.38,0,0,1,.13-.3.38.38,0,0,1,.3-.13h.29a.38.38,0,0,1,.3.13.42.42,0,0,1,.13.3V5.07h6.85V3.21a.38.38,0,0,1,.13-.3.38.38,0,0,1,.3-.13h.29a.38.38,0,0,1,.3.13.42.42,0,0,1,.13.3V5.07ZM5.66,6.21a.54.54,0,0,0-.41.16.56.56,0,0,0-.16.41V8.5H18.8V6.78a.56.56,0,0,0-.16-.41.54.54,0,0,0-.41-.16ZM18.23,19.92a.54.54,0,0,0,.41-.16.53.53,0,0,0,.16-.41V9.64H5.09v9.71a.53.53,0,0,0,.16.41.54.54,0,0,0,.41.16Z"></path></svg>
                                                                                {moment(story?.publish_date).format("DD MMMM YYYY, hh:mm A")}
                                                                            </p>
                                                                        }

                                                                    </div>
                                                                </td>


                                                                <td className="" style={{ alignContent: 'center', textAlign: 'center' }}>

                                                                    {CheckAccess("story", "delete") && isTab !== 'Deleted' && (
                                                                        <div className="list-story-vertical-ellipse">
                                                                            <svg onClick={() => { setRowId(rowId === null ? story?._id : null) }} id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="#5f6978">
                                                                                <defs></defs>
                                                                                <path fill="none" d="M0 0h24v24H0z" id="Bounding_Boxes" data-name="Bounding Boxes"></path>
                                                                                <path d="M12 8a2 2 0 1 0-2-2 2 2 0 0 0 2 2zm0 2a2 2 0 1 0 2 2 2 2 0 0 0-2-2zm0 6a2 2 0 1 0 2 2 2 2 0 0 0-2-2z" id="Outline"></path>
                                                                            </svg>
                                                                            <div className="list-menus-parent" style={{ display: `${rowId === story?._id ? 'initial' : 'none'}` }}>
                                                                                <ul className="list-menus">

                                                                                    {
                                                                                        isTab === 'Published' &&
                                                                                        // <li><a href={story?.story_type === 'Web Story' ? `${config.frontendUrl / ampstories / webstory / ${story?.slug}}` : `${config.frontendUrl}/${story?.slug_preview}`} target="_blank">Go To Story</a></li>
                                                                                        <li>
                                                                                            <a
                                                                                                href={
                                                                                                    story?.story_type === 'Web Story'
                                                                                                        ? `${config.frontendUrl}/ampstories/webstory/${story?.slug}`
                                                                                                        : `${config.frontendUrl}/${story?.slug_preview}`
                                                                                                }
                                                                                                target="_blank"
                                                                                                onClick={() => { setRowId(null) }}
                                                                                            >
                                                                                                Go To Story
                                                                                            </a>
                                                                                        </li>
                                                                                    }

                                                                                    <li
                                                                                        onClick={(e) => {
                                                                                            e?.preventDefault();
                                                                                            setRowId(null)
                                                                                            handleDelete(story?.id || story?._id, story?.child_story_id, story?.parent_story_id);
                                                                                        }}
                                                                                    >
                                                                                        Delete
                                                                                    </li>
                                                                                    {/* <li>Clone</li> */}

                                                                                    {
                                                                                        isTab === 'Rejected' &&
                                                                                        <li onClick={() => { handleRejectStory(story?._id) }}>
                                                                                            Un-Reject
                                                                                        </li>
                                                                                    }
                                                                                    {
                                                                                        isTab === 'Retract' &&
                                                                                        <li onClick={() => { handleRejectStory(story?._id) }}>
                                                                                            Un-Retract
                                                                                        </li>
                                                                                    }
                                                                                </ul>
                                                                            </div>
                                                                        </div>

                                                                    )}
                                                                    {CheckAccess("story", "create") && isTab === 'Deleted' && (
                                                                        <div className="timeline-footer text-right"
                                                                            style={{ marginLeft: '20px', cursor: 'pointer' }}
                                                                            title="Restore"
                                                                            onClick={(e) => {
                                                                                e?.preventDefault();
                                                                                restoreStory(story?._id);
                                                                            }}
                                                                        >
                                                                            <MdRestoreFromTrash size={20} />
                                                                        </div>
                                                                    )}

                                                                </td>
                                                            </tr>
                                                        );
                                                    })
                                                )}
                                            </tbody>
                                            {/* {
                                               !loading &&  !storiesList?.length && 
                                                <div className="d-flex-center mt-40">NO DATA</div>
                                            } */}
                                        </table>
                                        {!loading && totalPages && storiesList?.length > 0 ? (
                                            <div className="pagination-container">
                                                <div className="pagination-count">
                                                    <p>Results: {(currentPage - 1) * pageSize + 1} - {Math.min(currentPage * pageSize, storiesList?.length)} of {storiesCount}</p>
                                                </div>
                                                <Pagination
                                                    currentPage={currentPage}
                                                    totalPages={totalPages}
                                                    onPageChange={handlePageChange}
                                                />
                                            </div>
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            }
        </div >
    );
};

export default ListStory;
