import React from 'react';
import './style.css';

const TableLoader = ({ count }) => {
  const rows = Array.from({ length: count });

  return (
    <tbody className="loading-skeleton">
      {rows.map((_, rowIndex) => (
        <tr key={rowIndex}>
          {Array.from({ length: count }).map((_, colIndex) => ( // Adjusted to match the number of columns
            <td className={`${colIndex===0 ? "td-loade-first-col":"td-loader" } `} key={colIndex}>
              <span>&nbsp;</span>
            </td>
          ))}
        </tr>
      ))}
    </tbody>
  );
};

export default TableLoader;
