import React, { useState,useEffect } from 'react';
import { DateRangePicker } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';

const MyDateRangePicker = ({setDateRange,onAction=()=>{},defaultValue = null}) => {
  const [internalDateRange, setInternalDateRange] = useState({ startDate: null, endDate: null });

  const handleDateChange = (value) => {

    const formatDate = (date) => {
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0'); 
      const year = date.getFullYear();
      return `${year}-${month}-${day}`;
    };
  if (value) {
    const formattedStartDate = formatDate(value[0]);
    const formattedEndDate = formatDate(value[1]);

    // console.log("Formatted Start Date:", formattedStartDate); 
    // console.log("Formatted End Date:", formattedEndDate); 
    setDateRange((prev)=>({...prev,
      startDate:formattedStartDate,
      endDate:formattedEndDate}))
      setInternalDateRange({ startDate: formattedStartDate, endDate: formattedEndDate });

    onAction()
    // console.log("if onAction is working..")
  } else {
  
    setDateRange((prev)=>({...prev,
      startDate:"",
      endDate:""}))
      onAction()
      // console.log("else onAction is working..")
      setInternalDateRange({ startDate: '', endDate: '' });
  }
  };

  useEffect(() => {
    if ((internalDateRange.startDate && internalDateRange.endDate) || (internalDateRange.startDate==="" && internalDateRange.endDate==="")) {
      onAction();
    }
  }, [internalDateRange, onAction]);
  return (
    <div>
      <DateRangePicker onChange={handleDateChange}  defaultValue={defaultValue} format="dd/MM/yyyy"  />
    </div>
  );
};

export default MyDateRangePicker;


