import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { Link, useParams } from "react-router-dom";
import * as bootstrap from 'bootstrap';
import Swal from "sweetalert2";
import config from "../../config";
import { CreateDataApi, GetListApi, UpdateDataApi } from "./api/api";
import axios from "axios";
import Pagination from "./pagination";

const user = JSON.parse(localStorage.getItem('user'))

const ListRssFeed = () => {
    const { register, handleSubmit, control, formState: { errors }, watch, getValues, setValue, reset } = useForm();
    const [data, setData] = useState([])
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPages, setTotalPages] = useState(0)
    const [totalCount, setTotalCount] = useState(0)
    const [pageSize, setPageSize] = useState(50)

    const { type } = useParams()
    const formatedType = type?.slice(0, 1)?.toUpperCase() + type?.slice(1)?.toLocaleLowerCase()

    const fetchData = async () => {
        const data = await GetListApi(`common-masters`, { filter: { status: 'active', type }, page: currentPage })
        setData(data?.data)
        setTotalPages(data?.totalPages)
        setTotalCount(data?.totalCount)
    }

    useEffect(() => {
        const getData = async () => {
            await fetchData()
        }
        if (currentPage) getData()
    }, [currentPage, type])

    const onSubmit = async () => {
        try {
            const values = getValues()

            const payload = {
                name: values?.name,
                type: type,
                userId: user?._id
            }

            let data = {}

            if (values?.updateId) {
                data = await UpdateDataApi(`common-masters/${values?.updateId}`, payload)
            }
            else {
                data = await CreateDataApi('common-masters', payload)
            }

            if (data?._id) {
                reset()
                toast.success(`${formatedType} ${values?.updateId ? 'updated' : 'saved'} !`);
                document.querySelector('[data-bs-dismiss="offcanvas"]').click();
                await fetchData()
            }
            else {
                toast.error('someting went wrong..')
            }
        }
        catch (error) {
            console.error(error)
        }
    }

    const addNewMaster = () => {
        reset()
        const offcanvas = new bootstrap.Offcanvas(document.getElementById('offcanvasRight'))
        offcanvas.show()
    }

    const setUpdateItem = (data) => {
        setValue('name', data?.name)
        setValue('updateId', data?._id)
        const offcanvas = new bootstrap.Offcanvas(document.getElementById('offcanvasRight'))
        offcanvas.show()
    }

    const deleteMaster = async (id) => {
        if (!id) return

        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then((result) => {
            if (result.isConfirmed) {
                axios
                    .delete(`${config.apiUrl}/common-masters/${id}`)
                    .then((response) => {
                        toast.success('Deleted Successfully')
                        fetchData();
                    })
                    .catch((error) => {
                        toast.error('something went wrong')
                        console.error("Error deleting item:", error?.message);
                    });
            }
        });
    }

    const handlePageChange = (page) => {
        setCurrentPage(page)
    }

    return (
        <div style={{ backgroundColor: '#f9f9f9' }}>
            <div className="offcanvas offcanvas-end" tabindex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
                <div className="offcanvas-header">
                    <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                    <h5 id="offcanvasRightLabel" >{formatedType}</h5>
                    <h1></h1>
                </div>

                <div className="offcanvas-body">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="row mb-30 mt-30">
                            <label htmlFor="name" className="col-sm-4 col-form-label text-right">{formatedType}</label>
                            <div className="col-sm-8">
                                <input
                                    className="form-control"
                                    type="text"
                                    {...register("name", { required: "This is required" })}
                                    placeholder={`Enter ${formatedType}`}
                                />
                                {errors.name && <span className="text-danger">{errors.name.message}</span>}
                            </div>
                        </div>

                        <div className="row mb-30 mt-60" style={{ display: 'flex', justifyContent: 'center' }}>
                            <button className="btn btn-primary" type="submit" style={{ width: '50%' }}>
                                {`${watch('updateId') ? 'Update' : 'Save'} ${formatedType}`}
                            </button>
                        </div>
                    </form>
                </div>

            </div>


            <div className="content" style={{ backgroundColor: '#f9f9f9' }}>
                <div className="content">
                    <div className="card col-md-12 m-auto p-30">

                        <div className="card-header">
                            <h3 className="text-bold">{formatedType}</h3>
                            <input
                                type="search"
                                placeholder="search..."
                                className="form-control story-search-input"
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {

                                    }
                                }}
                            />
                            <button className="btn btn-primary btn-round btn-sm" type="button" onClick={(e) => { e.preventDefault(); addNewMaster() }}>Add {formatedType}</button>
                        </div>

                        <div className="card-body">
                            <div className="table-responsive">
                                <table className="table table-hover capitalize-text">
                                    <thead>
                                        <tr>
                                            <th className="col-1">#</th>
                                            <th className="col-9 text-align-center">{formatedType}</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            (data || [])?.map((item, index) => (
                                                <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td className="col-9 text-align-center">{item?.name}</td>
                                                    <td>
                                                        <Link onClick={(e) => { e.preventDefault(); setUpdateItem(item) }}  >
                                                            <i className="fa fa-edit f-16 text-c-green"></i>
                                                        </Link>
                                                        <Link onClick={(e) => { e.preventDefault(); deleteMaster(item?._id) }}>
                                                            <i className="fa fa-trash ms-3 f-16 text-c-red"></i>
                                                        </Link>
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>

                                {
                                    totalPages > 0 &&
                                    <div className="pagination-container">
                                        <div className="pagination-count">
                                            <p>Results: {(currentPage - 1) * pageSize + 1} - {Math.min(currentPage * pageSize, totalCount)} of {totalCount}</p>
                                        </div>
                                        <Pagination
                                            currentPage={currentPage}
                                            totalPages={totalPages}
                                            onPageChange={handlePageChange}
                                        />
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
};

export default ListRssFeed;
