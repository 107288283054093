import {
  Link,
  useNavigate,
  // useParams,
  useSearchParams,
} from "react-router-dom";
import SyncSelect from "../../../comman/select";
import MyDateRangePicker from "../../../comman/DateRangePicker";
import { useState, useEffect } from "react";
import config from "../../../config";

import TableLoader from "../../common/TableLoader";
const AuthorStory = () => {
  const navigate = useNavigate();
  // const { id } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const initialAuthor = { id: searchParams.get("author") };

  const [selectedAuthor, setSelectedAuthor] = useState(initialAuthor);
  const [dateRange, setDateRange] = useState({
    startDate: searchParams.get("startDate"),
    endDate: searchParams.get("endDate"),
  });

  const [isLoading, setIsLoading] = useState(true);
  const [authors, setAuthors] = useState([]);
  const [authorStoryData, setAuthourStoryData] = useState([]);
  const [defalutAuthor, setDefaultAuthor] = useState([]);

  const [isXlLoading, setIsXlLoading] = useState(false);
  const storyTypes = [
    { type: "Text Story", color: "bg-success" },
    { type: "Video Story", color: "bg-primary" },
    { type: "Web Story", color: "bg-secondary" },
    { type: "Photo Story", color: "bg-danger" },
    { type: "Live Story", color: "bg-warning" },
  ];

  const getStoryCount = (stories, storyType) => {
    const story = stories.find((s) => s.storyType === storyType);
    return story ? story.count : 0;
  };
  const getAllStoryCount = (Submittedstories, Publishedstories, Rejectedstories, storyType) => {
    // Default counts as 0
    const submittedCount =
      Submittedstories && Array.isArray(Submittedstories)
        ? Submittedstories.find((s) => s.storyType === storyType)?.count || 0
        : 0;

    const publishedCount =
      Publishedstories && Array.isArray(Publishedstories)
        ? Publishedstories.find((p) => p.storyType === storyType)?.count || 0
        : 0;

    const rejectedCount =
      Rejectedstories && Array.isArray(Rejectedstories)
        ? Rejectedstories.find((r) => r.storyType === storyType)?.count || 0
        : 0;

    // Return the sum of counts
    return submittedCount + publishedCount + rejectedCount;
  };

  const getCountForEachType = (data, key, story_type) => {
    let count = 0;

    data?.map(item => {
      item?.[key]?.map(type => {
        if (type?.storyType === story_type) count += type?.count
      })
    })

    return count
  }

  const getAllAuthors = async () => {
    try {
      const url = `${config.apiUrl}/reports/all-author`;
      const response = await fetch(url);
      const data = await response.json();

      const transformedAuthors = data.users.map((author) => ({
        value: author._id,
        label: author.name,
      }));

      setAuthors([
        { value: null, label: "Select Author Name" },
        ...transformedAuthors,
      ]);
      const defaultOption = transformedAuthors.filter(option => searchParams.get('author')?.includes(option.value));
      setDefaultAuthor(defaultOption)
    } catch (error) {
      console.error("Error fetching authors:", error);
    }
  };

  const getAuthorStory = async () => {
    setIsLoading(true);
    let url = `${config.apiUrl}/reports/author?`;

    if (dateRange?.startDate && dateRange?.endDate) {
      url += `&startDate=${dateRange.startDate}&endDate=${dateRange.endDate}`;
    }
    if (searchParams.get('author')) url += `&author=${searchParams.get('author')}`;

    const response = await fetch(url);
    const data = await response.json();

    setAuthourStoryData(data || []);
    setIsLoading(false);
  };

  const onExportSheet = async () => {
    setIsXlLoading(true);
    let url = `${config.apiUrl}/reports/author/xl?`;

    if (dateRange?.startDate && dateRange?.endDate) {
      url += `&startDate=${dateRange.startDate}&endDate=${dateRange.endDate}`;
    }
    if (searchParams.get('author')) url += `&author=${searchParams.get('author')}`;

    const response = await fetch(url);
    const blob = await response.blob();
    const sheeturl = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = sheeturl;
    link.setAttribute('download', 'AuthorPerformance.xlsx'); // File name
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);

    setIsXlLoading(false);
  };
  const onNavigateHandle = async (type, date, status) => {
    let url = `/reports/story-by-author?author=${searchParams.get('author')}`;

    if (type) {
      url += `&storyType=${type?.split(' ')[0]}`;
    }
    if (date) {
      url += `&date=${date}`;
    }
    if (status) {
      url += `&status=${status}`;
    }
    // console.log("check url->",url);

    navigate(url);
  };
  useEffect(() => {
    // console.log("Updating URL and fetching data");

    // Get the current URL parameters
    const currentParams = new URLSearchParams(window.location.search);

    // Update only if necessary to avoid unnecessary URL changes
    let shouldUpdateParams = false;

    // Update startDate in the URL
    if (dateRange?.startDate) {
      if (currentParams.get("startDate") !== dateRange.startDate) {
        currentParams.set("startDate", dateRange.startDate);
        shouldUpdateParams = true;
      }
    } else if (currentParams.has("startDate")) {
      currentParams.delete("startDate");
      shouldUpdateParams = true;
    }

    // Update endDate in the URL
    if (dateRange?.endDate) {
      if (currentParams.get("endDate") !== dateRange.endDate) {
        currentParams.set("endDate", dateRange.endDate);
        shouldUpdateParams = true;
      }
    } else if (currentParams.has("endDate")) {
      currentParams.delete("endDate");
      shouldUpdateParams = true;
    }

    // Handle selected author
    if (selectedAuthor?.id) {
      if (currentParams.get("author") !== selectedAuthor.id) {
        currentParams.set("author", selectedAuthor.id);
        shouldUpdateParams = true;
      }
    } else if (currentParams.has("author")) {
      currentParams.delete("author");
      shouldUpdateParams = true;
    }
    // Update the URL only if necessary
    if (shouldUpdateParams) {
      setSearchParams(currentParams);
    }

    // Fetch data
    getAuthorStory();
    getAllAuthors();
  }, [dateRange, selectedAuthor, setSearchParams]);

  console.log("check selectedAuthor=>>>", selectedAuthor);
  return (
    <>
      <div className="content">
        <div className="card cus-card-h">
          <div className="card-body">
            <div className="row">
              <div className="col-md-2">
                <strong>Date Range</strong>
                <MyDateRangePicker setDateRange={setDateRange}
                  defaultValue={dateRange.startDate ? [new Date(dateRange?.startDate), new Date(dateRange?.endDate)] : null}

                  format="dd/MM/yyyy" />
              </div>
              <div className="col-md-2">
                <SyncSelect
                  option={authors}
                  label={"Author Name"}
                  defaultOption={defalutAuthor}
                  setQuery={setSelectedAuthor}
                />
              </div>

              <div className="col-md-2 align-self-end">
                <button className="btn btn-success" onClick={onExportSheet}>
                  <i className="fa fa-file-excel-o"></i> {isXlLoading ? "Exporting..." : "Export"}
                </button>
              </div>
            </div>
            <table className="table table-bordered mt-4">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Date</th>
                  <th>Submitted</th>
                  <th>Published</th>
                  {/* <th>Rejected</th> */}
                </tr>
              </thead>
              {

                !isLoading ? (authorStoryData?.length > 0 && (
                  <tbody>
                    {authorStoryData.map((item, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{item?.date}</td>

                        <td className="cursor-pointer">
                          {/* submitted */}
                          <div className="d-flex gap-2">
                            {storyTypes.map(({ type, color }) => (
                              <div
                                key={`${item.date}-${type}-submitted`}
                                onClick={() =>
                                  onNavigateHandle(type, item?.date, "submitted")
                                }
                              >
                                <span className={`badge ${color}`}>
                                  {type} (
                                  {getAllStoryCount(
                                    item.submittedStories || [], [], [],
                                    // item.publishedStories || [],
                                    // item.rejectedStories || [],
                                    type
                                  )}
                                  )
                                </span>
                              </div>
                            ))}
                          </div>
                        </td>
                        {/* published */}
                        <td className="cursor-pointer">
                          <div className="d-flex gap-2">
                            {storyTypes.map(({ type, color }) => (
                              <div
                                key={`${item.date}-${type}-published`}
                                onClick={() =>
                                  onNavigateHandle(type, item?.date, "published")
                                }
                              >
                                <span className={`badge ${color}`}>
                                  {type} (
                                  {getStoryCount(
                                    item.publishedStories || [],
                                    type
                                  )}
                                  )
                                </span>
                              </div>
                            ))}
                          </div>
                        </td>
                        {/* Rejected */}
                        {/* <td className="cursor-pointer">
                        <div className="d-flex gap-2">
                          {storyTypes.map(({ type, color }) => (
                            <div
                              key={`${item.date}-${type}-rejected`}
                              onClick={() =>
                                onNavigateHandle(type, item?.date, "rejected")
                              }
                            >
                              <span className={`badge ${color}`}>
                                {type} (
                                {getStoryCount(
                                  item.rejectedStories || [],
                                  type
                                )}
                                )
                              </span>
                            </div>
                          ))}
                        </div>
                      </td> */}
                      </tr>
                    ))}

                    <tr className="fw-bold">
                      <td>{authorStoryData?.length + 1}</td>
                      <td>{'Total'}</td>

                      <td className="">
                        {/* submitted */}
                        <div className="d-flex gap-2">
                          {storyTypes.map(({ type, color }) => (
                            <div key={`${type}-submitted`} >
                              <span className={`badge ${color}`}>
                                {type} (
                                {getCountForEachType(
                                  authorStoryData,
                                  'submittedStories',
                                  type
                                )}
                                )
                              </span>
                            </div>
                          ))}
                        </div>
                      </td>
                      {/* published */}
                      <td className="cursor-pointer">
                        <div className="d-flex gap-2">
                          {storyTypes.map(({ type, color }) => (
                            <div key={`${type}-published`}>
                              <span className={`badge ${color}`}>
                                {type} (
                                {getCountForEachType(
                                  authorStoryData,
                                  'publishedStories',
                                  type
                                )}
                                )
                              </span>
                            </div>
                          ))}
                        </div>
                      </td>
                    </tr>
                  </tbody>
                )) : (
                  <TableLoader count={6} />
                )}
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default AuthorStory;
