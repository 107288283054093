import { useContext, useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import { useForm } from "react-hook-form";
import { Offcanvas } from "react-bootstrap";
import { CreateDataApi, UpdateDataApi, DeleteApi, GetListApi } from "../../../common/api/api";
import { apiEndpoints } from "../../../common/api/apiEndpoints";
import { useNavigate, useParams } from "react-router-dom";
import AppContext from "../../../appContext";
import moment from "moment";
import Swal from "sweetalert2";
import DatePicker from "react-datepicker";
import DateTimeInput from "../../../common/date-time";
import config from "../../../../config";
import saveToLogs from "../../../common/utils/saveToLogs";

const PublishForm = (props) => {
  const {
    handleCloseCanvas,
    openCanvas,
    storyId,
    setStoryStatus,
    fetchTimeline,
    getValues,
    setLoading,
    formData,
    onAutoSaveStory
  } = props;
  const {
    formState: { errors },
    setValue,
  } = useForm();
  const navigate = useNavigate();
  const { id } = useParams();
  const { setAppData } = useContext(AppContext);
  const [hours, setHours] = useState();
  const [minutes, setMinutes] = useState();

  const [startDate, setStartDate] = useState();
  const [publishNow, setIsPublishNow] = useState(false);
  const [publisLater, setIsPublishLater] = useState(false);
  const userData = localStorage.getItem("user");
  const user = userData ? JSON.parse(userData) : null;

  const [retainPublishTime, setRetainPublishTime] = useState(false)
  const [generalSettings, setGeneralSettings] = useState({});

  const getchGeneralSettings = async () => {
    const result = await GetListApi(apiEndpoints?.listGeneralSettings)
    setGeneralSettings(result)
  }

  useEffect(() => {
    const getData = async () => {
      await getchGeneralSettings()
    }
    getData()
  }, [])

  const onPublish = async () => {
    await onAutoSaveStory()

    let httpMethod = null
    let url = null
    let logsPayload = null
    let logsStoryId = null

    const publish_later_date = moment(startDate, 'MMMM DD, YYYY [at] h:mm:ss A').toISOString()

    let publishDate = ''

    if (retainPublishTime) {
      publishDate = formData?.publish_date
    }
    else if (publisLater) {
      publishDate = publish_later_date
    }
    else {
      publishDate = new Date().toISOString()
    }

    let childStory = false

    if (id) {
      try {
        const response = await fetch(`${config.apiUrl}/stories/copy-story/${id}`)
        const responseData = await response.json()
        const story = responseData?.story

        if (story?.parent_story_id) {
          const cards = story?.cards
          let newCards = []

          for (let card of cards) {
            delete card._id
            const response = await CreateDataApi('card/create', card)
            newCards.push(response?.savedCard?._id)
          }

          childStory = true
          const parentId = story.parent_story_id
          delete story.id
          delete story._id

          story.parent_story_id = null
          story.is_child_story = null
          story.child_story_id = null
          story.status = publisLater ? 'scheduled' : 'published'
          story.publish_date = publisLater ? publishDate : publishNow ? new Date().toISOString() : story?.publish_date

          story.cards = newCards
          story.slug = story?.slug.split('-child-story')?.[0]
          story.title = story?.title?.trim()
          story.is_editing_by = null
          story.updated_on = new Date().toISOString()

          httpMethod = 'PUT'
          url = `stories/update-child-story/${parentId}`
          logsPayload = story
          logsStoryId = parentId

          await DeleteApi(`${apiEndpoints.storiesById(id)}`, {})

          const updatedStory = await UpdateDataApi(`stories/update-child-story/${parentId}`, story)
         
          if (!publisLater && generalSettings?.enableFacebookSharing) await CreateDataApi(apiEndpoints.createFbFeed(parentId))

          const timeline = await CreateDataApi(apiEndpoints?.timeline, {
            story_id: story?.parent_story_id,
            cloned_story_id: id,
            action_by: user?._id,
            action_on: moment().format("MMMM DD YYYY, h:mm:ss a"),
            action_type: "published",
          });

          navigate('/story')
        }
      }
      catch (error) {
        const errorDetails = {
          message: error.message,
          stack: error.stack
        }

        await saveToLogs({
          source: 'onsubmit',
          payload: logsPayload,
          error_message: errorDetails,
          url: url,
          http_method: httpMethod,
          story_id: logsStoryId,
          log_type: logsPayload?.story_type
        })

        Swal.fire({
          title: "Something went wrong!",
          text: "An error occurred. Please try reloading the page.",
          icon: "error",
          showCancelButton: true,
          cancelButtonText: "Cancel",
          confirmButtonText: "Reload Page",
        }).then((result) => {
          if (result.isConfirmed) {
            const updateStoryId = localStorage.getItem('updateStoryId')
           
            if (updateStoryId) window.location.href = `/web-story/update/${updateStoryId}`
            else window.location.href = `/story`
          }
        });

        console.error(error)
      }
    }

    if (!publishNow && !publisLater && !retainPublishTime) {
      Swal.fire({
        title: "Please select publish now or publish later",
        icon: "warning",
      });
      return;
    }

    let payload = {
      publish_now: publishNow,
      publish_later: publisLater,
      status: publisLater ? "scheduled" : publishNow ? 'published' : formData?.status,
      publish_date: publishDate,
      is_editing_by: null,
      updated_on: new Date().toISOString()
    };

    httpMethod = 'PUT'
    url = `${apiEndpoints.storiesById(id || storyId)}`
    logsPayload = payload
    logsStoryId = id || storyId

    if (publishNow || publisLater || retainPublishTime) {
      if (publisLater && !startDate) {
        Swal.fire({
          title: "Please select date or time!",
          icon: "warning",
        });
        return;
      }

      if (formData && formData?.is_breaking_news) {
        if (!formData?.breaking_title || !formData?.breaking_description) {
          Swal.fire({
            title: "Please select breaking title or description!",
            icon: "warning",
          });
          return;
        }
      }

      setLoading(true);

      const response = await UpdateDataApi(
        `${apiEndpoints.storiesById(id || storyId)}`,
        payload
      );

      if (!publisLater && generalSettings?.enableFacebookSharing) await CreateDataApi(apiEndpoints.createFbFeed(id || storyId))

      console.log({ responseWebOnPublish: response, id, storyId })

      if (response) {
        const timeline = await CreateDataApi(apiEndpoints?.timeline, {
          story_id: response?._id,
          action_by: user?._id,
          action_on: moment().format("MMMM DD YYYY, h:mm:ss a"),
          action_type: "published",
        });
        await fetchTimeline(`${response?._id}`);
        setLoading(false);
        setValue("story_id", response?._id);
        setStoryStatus(response?.status);
        setAppData("");
        navigate("/story");
        handleCloseCanvas();
      }
    } else {
      await saveToLogs({
        source: 'onsubmit',
        payload: logsPayload,
        error_message: { message: 'on publish failed' },
        url: url,
        http_method: httpMethod,
        story_id: logsStoryId,
        log_type: logsPayload?.story_type
      })


      Swal.fire({
        title: "Something went wrong!",
        text: "An error occurred. Please try reloading the page.",
        icon: "error",
        showCancelButton: true,
        cancelButtonText: "Cancel",
        confirmButtonText: "Reload Page",
      }).then((result) => {
        if (result.isConfirmed) {
          const updateStoryId = localStorage.getItem('updateStoryId')
          
          if (updateStoryId) window.location.href = `/web-story/update/${updateStoryId}`
            else window.location.href = `/story`
        }
      });


      // Swal.fire({
      //   title: "Please select publish now or publish later!",
      //   icon: "warning",
      // });
    }
  };

  return (
    <div>
      <Offcanvas show={openCanvas} onHide={handleCloseCanvas} placement="end">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            <button
              type="submit"
              className={`btn btn-primary`}
              onClick={(e) => {
                e?.preventDefault();
                onPublish();
              }}
            >
              Publish
            </button>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <form>
          <div className={`publish`}>
            <h4 className="fw-bold">Are you ready to publish?</h4>
            <p>
              Publish the story now or schedule it to be published later. Once
              the story is published, it will be available in all public places.
            </p>
            <div className="mb-5">
              <Form.Check
                inline
                className={`switchInput`}
                id={`inline-1`}
                label="Publish Now"
                name="publish_now"
                type={`radio`}
                onChange={(e) => {
                  setIsPublishLater(false);
                  setRetainPublishTime(false)
                  setIsPublishNow(e?.target?.checked);
                }}
                checked={publishNow}
              />

              <Form.Check
                inline
                className={`switchInput`}
                label="Publish Later"
                name="publish_now"
                type={`radio`}
                id={`inline-2`}
                onChange={(e) => {
                  setIsPublishNow(false);
                  setRetainPublishTime(false)
                  setIsPublishLater(e?.target?.checked);
                }}
                checked={publisLater}
              />

              {
                formData?.publish_date &&
                <Form.Check
                  block
                  className={`switchInput`}
                  label="Retain Publish Time"
                  name="retain_publish_time"
                  type={`checkbox`}
                  id={`inline-3`}
                  onClick={(e) => {
                    setIsPublishLater(false)
                    setIsPublishNow(false)
                    setRetainPublishTime(!retainPublishTime)
                  }}
                  checked={retainPublishTime}
                  style={{ marginTop: '30px' }}
                />
              }
            </div>

            {publisLater && (
              <div className="mt-5">
                <h4 className="fw-bold">Schedule a time to publish:</h4>
                <p>
                  This story will be published automatically within five minutes
                  of specified time.
                </p>
                <div className="col-sm-12 px-0">
                  <div className="input-group">
                    <DateTimeInput
                      setStartDate={setStartDate}
                      startDate={startDate}
                    />
                    {/* <DatePicker
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                      showTimeSelect
                      // excludeTimes={[
                      //   setHours(setMinutes(new Date(), 0), 17),
                      //   setHours(setMinutes(new Date(), 30), 18),
                      //   setHours(setMinutes(new Date(), 30), 19),
                      //   setHours(setMinutes(new Date(), 30), 17),
                      // ]}
                      dateFormat="MMMM d, yyyy h:mm aa"
                    /> */}
                    {/* <input
                      type="datetime-local"
                      className="form-control pull-right"
                      // id={`datepicker`}
                      name="publish_date"
                      {...register("publish_date")}
                    /> */}
                  </div>
                </div>
              </div>
            )}
          </div>
        </form>
      </Offcanvas>
    </div>
  );
};

export default PublishForm;
