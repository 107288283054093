import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { GetListApi } from "../../common/api/api";
import Swal from "sweetalert2";
import axios from "axios";
import config from "../../../config";
import { toast } from "react-toastify";
import Pagination from "../../common/pagination";

const List = () => {
  const [rssBreakingNews, setRssBreakingNews] = useState([
    { name: 'test1', _id: 'id1' },
    { name: 'test2', _id: 'id2' },
  ])

  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(0)
  const [totalCount, setTotalCount] = useState(0)
  const [pageSize, setPageSize] = useState(50)

  const handleDelete = (id) => {
    if (!id) return
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(`${config.apiUrl}/rss-breaking-news/${id}`)
          .then(async (response) => {
            await fetchRssBrakingNews();
            toast.success('deleted')
          })
          .catch((error) => {
            console.error("Error deleting item:", error?.message);
          });
      }
    });
  }

  const handleEdit = (id) => {
    window.open(`/rss-breaking-news/create/${id}`, '_blank')
  }

  const fetchRssBrakingNews = async () => {
    const data = await GetListApi('rss-breaking-news', { status: 'active', page: currentPage, pageSize })
    setRssBreakingNews(data?.data)
    setTotalPages(data?.totalPages)
    setTotalCount(data?.totalCount)
  }

  useEffect(() => {
    const getData = async () => {
      await fetchRssBrakingNews()
    }
    getData()
  }, [currentPage])

  const handlePageChange = (page) => {
    setCurrentPage(page)
  }

  return (
    <div style={{ backgroundColor: '#f9f9f9' }}>
      <div className="content" style={{ backgroundColor: '#f9f9f9' }}>
        <div className="content">
          <div className="card col-md-12 m-auto p-30">

            <div className="card-header">
              <h3 className="text-bold">Rss Breaking News</h3>
              <input
                type="search"
                placeholder="search..."
                className="form-control story-search-input"
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {

                  }
                }}
              />
              <button onClick={() => { handleEdit('new') }} className="btn btn-primary"> Add New </button>
            </div>

            <div className="card-body">
              <div className="table-responsive">
                <table className="table table-hover capitalize-text">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Links</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      rssBreakingNews?.map((news, index) => (
                        <tr key={index}>
                          <td>{news?.name || '-'}</td>
                          <td>
                            <a href={`${config.apiUrl}/rss-breaking-news/rss/${news?.name?.toLowerCase()?.replace(/ /g, '-')}.rss`} target="_blank">Rss</a>
                            <span className="ml-2 mr-2"> |</span>
                            <a href={`${config.apiUrl}/rss-breaking-news/sitemap/${news?.name?.toLowerCase()?.replace(/ /g, '-')}.xml`} target="_blank">Sitemap</a>
                          </td>
                          <td>
                            <Link onClick={(e) => { e.preventDefault(); handleEdit(news?._id) }}  >
                              <i className="fa fa-edit f-16 text-c-green"></i>
                            </Link>
                            <Link onClick={(e) => { e.preventDefault(); handleDelete(news?._id) }}>
                              <i className="fa fa-trash ms-3 f-16 text-c-red"></i>
                            </Link>
                          </td>
                        </tr>
                      ))
                    }
                  </tbody>
                </table>

                {
                  totalPages > 0 &&
                  <div className="pagination-container">
                    <div className="pagination-count">
                      <p>Results: {(currentPage - 1) * pageSize + 1} - {Math.min(currentPage * pageSize, totalCount)} of {totalCount}</p>
                    </div>
                    <Pagination
                      currentPage={currentPage}
                      totalPages={totalPages}
                      onPageChange={handlePageChange}
                    />
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div >
  );
};

export default List;
