import React, { useState } from "react";
import AppRoutes from "./components/Routes";
import "react-toastify/dist/ReactToastify.css";
import "./assets/assets/vendor_components/bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/bootstrap-extend.css";
import "./assets/css/master_style.css";
import "./assets/css/skins/_all-skins.css";
import "react-quill/dist/quill.snow.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-datepicker/dist/react-datepicker.css";

import AppContext from "./components/appContext";
import { ToastContainer } from "react-toastify";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import './style.css'
const queryClient = new QueryClient();
// import $ from 'jquery';
// import './assets/js/pages/dashboard.js';
// import './assets/assets/vendor_components/jquery/dist/jquery.js';
// import './assets/assets/vendor_components/jquery-ui/jquery-ui.min.js'
// import './assets/assets/vendor_components/popper/dist/popper.min.js';
// import './assets/assets/vendor_components/bootstrap/dist/js/bootstrap.js';
// import './assets/assets/vendor_components/raphael/raphael.min.js';
// import './assets/assets/vendor_components/morris.js/morris.min.js';
// import './assets/js/template.js';
// import './assets/js/demo.js';
function App() {
  const [appData, setAppData] = useState("");
  const [searchItem, setSearchItem] = useState("");

  const [showEditorNotes, setShowEditorNotes] = useState(false);

  return (
    <div className="App">
      <QueryClientProvider client={queryClient}>
        <AppContext.Provider
          value={{
            setAppData,
            appData,
            showEditorNotes,
            setShowEditorNotes,
            searchItem,
            setSearchItem,
          }}
        >
          <AppRoutes />
          <ToastContainer />
        </AppContext.Provider>
      </QueryClientProvider>
    </div>
  );
}

export default App;
