import { useSearchParams } from "react-router-dom";
import SyncSelect from "../../../comman/select";
import { useState, useEffect } from "react";
import config from "../../../config";
import TableLoader from "../../common/TableLoader";
import moment from "moment";
const StoryByAuthor = () => {

  const [searchParams, setSearchParams] = useSearchParams();
  const initialAuthor = { id: searchParams.get("author") };
  const initialStatus = { id: searchParams.get("status") };
  const initialStory = { id: searchParams.get("storyType") };
  const initialDate = { id: searchParams.get("date") };

  const [selectedDate, setSelectedDate] = useState(initialDate);
  const [selectedStoryType, setSelectedStoryType] = useState(initialStory);
  const [selectedAuthor, setSelectedAuthor] = useState(initialAuthor);
  const [selectedStatus, setSelectedStatus] = useState(initialStatus);

  const [authors, setAuthors] = useState([]);

  const [defalutAuthor, setDefaultAuthor] = useState([]);
  const [defalutStroryType, setDefalutStroryType] = useState([]);
  const [defalutStatusType, setDefalutStatusType] = useState([]);
  const [defalutDate, setdefalutDate] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const [authourStoryData, setAuthourStoryData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const Story = [
    { value: null, label: "Select Story Type" },
    { value: "Text", label: "Text Story" },
    { value: "Video", label: "Video Story" },
    { value: "Web", label: "Web Story" },
    { value: "Photo", label: "Photo Story" },
    { value: "Live", label: "Live Story" },
  ];

  const Status = [
    { value: null, label: "Select Status" },
    { value: "submitted", label: "Submitted" },
    { value: "published", label: "Published" },
    { value: "rejected", label: "Rejected" },
  ];

  const getAllAuthors = async () => {
    try {
      const url = `${config.apiUrl}/reports/all-author`;
      const response = await fetch(url);
      const data = await response.json();

      const transformedAuthors = data.users.map((author) => ({
        value: author._id,
        label: author.name,
      }));

      setAuthors([
        { value: null, label: "Select Author Name" },
        ...transformedAuthors,
      ]);
      const defaultOption = transformedAuthors.filter((option) =>
        selectedAuthor.id.includes(option.value)
      );
      setDefaultAuthor(defaultOption);
    } catch (error) {
      console.error("Error fetching authors:", error);
    }
  };
  const getAuthorStory = async () => {
    setIsLoading(true);
    let url = `${config.apiUrl}/reports/story-by-author?`;

    if (selectedDate?.id) {
      url += `&date=${selectedDate?.id}`;
    }
    if (selectedAuthor?.id) url += `&author=${selectedAuthor.id}`;
    if (selectedStoryType?.id) url += `&story_type=${selectedStoryType.id}`;
    if (selectedStatus?.id) url += `&status=${selectedStatus.id}`;
    const response = await fetch(url);
    const data = await response.json();

    setAuthourStoryData(data?.stories || []);
    setTotalCount(data?.totalCount || 0);
    setIsLoading(false);
  };

  useEffect(() => {

    // Get the current URL parameters
    const currentParams = new URLSearchParams(window.location.search);

    // Flag to determine if URL parameters need to be updated
    let shouldUpdateParams = false;

    // Handle selected story type
    if (selectedStoryType?.id) {
      if (currentParams.get("storyType") !== selectedStoryType.id) {
        currentParams.set("storyType", selectedStoryType.id);
        shouldUpdateParams = true;
      }

      const selectedStoryTypes = Story.filter((option) =>
        selectedStoryType.id.includes(option.value)
      );
      setDefalutStroryType(selectedStoryTypes);
    } else if (currentParams.has("storyType")) {
      currentParams.delete("storyType");
      shouldUpdateParams = true;
    }

    // Handle selected status
    if (selectedStatus?.id) {
      if (currentParams.get("status") !== selectedStatus.id) {
        currentParams.set("status", selectedStatus.id);
        shouldUpdateParams = true;
      }

      const selectedStatusType = Status.filter((option) =>
        selectedStatus.id.includes(option.value)
      );
      setDefalutStatusType(selectedStatusType);
    } else if (currentParams.has("status")) {
      currentParams.delete("status");
      shouldUpdateParams = true;
    }
    // Handle selected author
    if (selectedAuthor?.id) {
      if (currentParams.get("author") !== selectedAuthor.id) {
        currentParams.set("author", selectedAuthor.id);
        shouldUpdateParams = true;
      }
    } else if (currentParams.has("author")) {
      currentParams.delete("author");
      shouldUpdateParams = true;
    }
    // Handle selected date
    if (selectedDate?.id) {
      if (currentParams.get("date") !== selectedDate.id) {
        currentParams.set("date", selectedDate.id);
        shouldUpdateParams = true;
      }

      setdefalutDate(selectedDate.id);
    } else if (currentParams.has("date")) {
      currentParams.delete("date");
      shouldUpdateParams = true;
    }

    // Update the URL only if changes are detected
    if (shouldUpdateParams) {
      setSearchParams(currentParams);
    }

    // Fetch data after parameters are updated
    getAuthorStory();
  }, [selectedAuthor, selectedStoryType, selectedStatus, selectedDate]);

  useEffect(() => {
    getAllAuthors();
  }, []);

  // console.log("check this data=>", authourStoryData);

  return (
    <>
      <div className="content">
        <div className="card cus-card-h">
          <div className="card-body">
            <div className="row">
              <div className="col-12 col-md-9 ">
                <div className="row">
                  {/* <div className="col-md-3">
                    <strong >Date</strong>
                    <input type="date" className="form-control" defaultValue={defalutDate}/>
              
                  
                  </div> */}
                  <div className="col-md-3">
                    <SyncSelect
                      option={authors}
                      label={"Author Name"}
                      setQuery={setSelectedAuthor}
                      defaultOption={defalutAuthor}
                    />
                  </div>
                  <div className="col-md-3">
                    <SyncSelect
                      option={Story}
                      label={"Story Type"}
                      setQuery={setSelectedStoryType}
                      defaultOption={defalutStroryType}
                    />
                  </div>
                  <div className="col-md-3">
                    <SyncSelect
                      option={Status}
                      label={"Status"}
                      setQuery={setSelectedStatus}
                      defaultOption={defalutStatusType}
                    />
                  </div>
                  {/* <div className="col-md-2 align-self-end" >
                <button className="btn btn-success"><i className="fa fa-file-excel-o"></i> Export</button>
              </div> */}
                </div>
              </div>
              <div className="col-12 col-md-3 text-end">
                <h2>
                  <strong>Total Count</strong>
                </h2>
                <h6> {totalCount}</h6>
              </div>
            </div>

            <table className="table table-bordered mt-4">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Title</th>
                  {/* <th>Submitted</th> */}
                </tr>
              </thead>
              {!isLoading ? (authourStoryData?.length > 0 && (
                <tbody>
                  {authourStoryData?.map((item, index) => (
                    <tr>
                      <td>{index + 1}</td>
                      <td>
                        {item?.title}
                        <br />

                        <div
                          style={{
                            display: "flex",
                            justifyContent: "left",
                            fontSize: "1rem",
                            height: "1.2em",
                          }}
                        >
                          <p
                            className="text-capitalize story-author"
                            style={{ color: "rgb(95, 105, 120)" }}
                          >
                            <svg
                              data-test-id="user"
                              data-name="Layer 1"
                              xmlns="http://www.w3.org/2000/svg"
                              width={16}
                              fill="#5f6978"
                              height={16}
                              viewBox="0 0 24 24"
                              style={{ marginRight: 5 }}
                            >
                              <path fill="transparent" d="M.03.13h24v24h-24z" />
                              <path d="M18.18 14.27A3.69 3.69 0 0 1 20.43 16a3.91 3.91 0 0 1 .57 2v1a2.15 2.15 0 0 1-.67 1.58 2.17 2.17 0 0 1-1.58.67H5.28a2.17 2.17 0 0 1-1.58-.65A2.15 2.15 0 0 1 3 19v-1a4.13 4.13 0 0 1 .6-2.11 3.74 3.74 0 0 1 2.25-1.65l1.72-.49a6.2 6.2 0 0 1-1.48-2.5 6.45 6.45 0 0 1-.28-1.83A6.15 6.15 0 0 1 12 3.27a6.16 6.16 0 0 1 6.19 6.18 6.45 6.45 0 0 1-.28 1.83 6.09 6.09 0 0 1-1.48 2.5zM19.91 19v-1a2.72 2.72 0 0 0-.57-1.67 2.76 2.76 0 0 0-1.47-1l-2.46-.71a6.09 6.09 0 0 1-6.75 0l-2.46.71a2.79 2.79 0 0 0-1.48 1A2.71 2.71 0 0 0 4.16 18v1a1.13 1.13 0 0 0 1.12 1.12h13.5a1.07 1.07 0 0 0 .79-.33 1.1 1.1 0 0 0 .34-.79zM12 4.39a4.86 4.86 0 0 0-2.53.69 5.18 5.18 0 0 0-1.82 1.84 5.07 5.07 0 0 0 0 5.07 5.26 5.26 0 0 0 1.85 1.84 5 5 0 0 0 5.06 0A5.18 5.18 0 0 0 16.41 12a4.89 4.89 0 0 0 .68-2.54 4.88 4.88 0 0 0-.68-2.53 5.1 5.1 0 0 0-1.85-1.84 4.83 4.83 0 0 0-2.56-.7z" />
                            </svg>
                            raftaar
                          </p>
                          <p
                            style={{
                              color: "rgb(95, 105, 120)",
                              margin: "0",
                              marginLeft: 15,
                            }}
                          >
                            <svg
                              width={16}
                              height={16}
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              fill="#5F6978"
                              style={{ marginRight: 5 }}
                            >
                              <path d="M12.18 3.22a8.52 8.52 0 0 1 4.36 1.18 8.81 8.81 0 0 1 3.18 3.18 8.42 8.42 0 0 1 1.18 4.36 8.4 8.4 0 0 1-1.18 4.36 8.66 8.66 0 0 1-3.18 3.18 8.64 8.64 0 0 1-8.72 0 8.74 8.74 0 0 1-3.18-3.18 8.4 8.4 0 0 1-1.18-4.36 8.42 8.42 0 0 1 1.18-4.36A8.89 8.89 0 0 1 7.82 4.4a8.52 8.52 0 0 1 4.36-1.18zm7.59 8.72a7.4 7.4 0 0 0-1-3.81 7.61 7.61 0 0 0-6.59-3.78 7.35 7.35 0 0 0-3.79 1A7.64 7.64 0 0 0 4.59 12a7.39 7.39 0 0 0 1 3.8 7.61 7.61 0 0 0 6.59 3.78 7.39 7.39 0 0 0 3.8-1A7.66 7.66 0 0 0 19.75 12zM14.54 15a.44.44 0 0 0 .31.09.42.42 0 0 0 .28-.16l.29-.42a.43.43 0 0 0 .08-.3.35.35 0 0 0-.16-.27l-2.49-1.83V7a.41.41 0 0 0-.12-.3.44.44 0 0 0-.3-.12h-.5a.4.4 0 0 0-.29.12.38.38 0 0 0-.13.3v5.63a.33.33 0 0 0 .18.31z" />
                            </svg>
                            {moment(item?.timeline_createdAt).format("DD MMMM YYYY, hh:mm A")}
                          </p>
                          <p
                            style={{
                              color: "rgb(95, 105, 120)",
                              margin: "0",
                              marginLeft: 15,
                            }}
                          >
                            current status: {item?.status === 'scheduled' && item?.publish_date <= new Date()?.toISOString() ? 'published' : item?.status}
                          </p>

                          <p
                            style={{
                              color: "rgb(95, 105, 120)",
                              margin: "0",
                              marginLeft: 15,
                            }}
                          >
                            <svg
                              width={16}
                              height={16}
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              fill="#5F6978"
                              style={{ marginRight: 5 }}
                            >
                              <path d="M12.18 3.22a8.52 8.52 0 0 1 4.36 1.18 8.81 8.81 0 0 1 3.18 3.18 8.42 8.42 0 0 1 1.18 4.36 8.4 8.4 0 0 1-1.18 4.36 8.66 8.66 0 0 1-3.18 3.18 8.64 8.64 0 0 1-8.72 0 8.74 8.74 0 0 1-3.18-3.18 8.4 8.4 0 0 1-1.18-4.36 8.42 8.42 0 0 1 1.18-4.36A8.89 8.89 0 0 1 7.82 4.4a8.52 8.52 0 0 1 4.36-1.18zm7.59 8.72a7.4 7.4 0 0 0-1-3.81 7.61 7.61 0 0 0-6.59-3.78 7.35 7.35 0 0 0-3.79 1A7.64 7.64 0 0 0 4.59 12a7.39 7.39 0 0 0 1 3.8 7.61 7.61 0 0 0 6.59 3.78 7.39 7.39 0 0 0 3.8-1A7.66 7.66 0 0 0 19.75 12zM14.54 15a.44.44 0 0 0 .31.09.42.42 0 0 0 .28-.16l.29-.42a.43.43 0 0 0 .08-.3.35.35 0 0 0-.16-.27l-2.49-1.83V7a.41.41 0 0 0-.12-.3.44.44 0 0 0-.3-.12h-.5a.4.4 0 0 0-.29.12.38.38 0 0 0-.13.3v5.63a.33.33 0 0 0 .18.31z" />
                            </svg>
                            {moment(item?.status === 'published' || item?.status === 'scheduled' ? item?.publish_date : item?.updated_on).format("DD MMMM YYYY, hh:mm A")}
                          </p>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              )) : (
                <TableLoader count={6} />
              )}
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default StoryByAuthor;
