import { useNavigate, useSearchParams } from "react-router-dom";
import SyncSelect from "../../../comman/select";
import MyDateRangePicker from "../../../comman/DateRangePicker";
import { useState, useEffect } from "react";
import config from "../../../config";
import TableLoader from "../../common/TableLoader";

const AuthorPerformance = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const initialStartDate = searchParams.get("startDate");
  const initialEndDate = searchParams.get("endDate");
  const initialAuthor = { id: searchParams.get("author") };
  const initialStory = { id: searchParams.get("storyType") };
  const [selectedStoryType, setSelectedStoryType] = useState(initialStory);
  const [selectedAuthor, setSelectedAuthor] = useState(initialAuthor);
  const [dateRange, setDateRange] = useState({
    startDate: initialStartDate,
    endDate: initialEndDate
  });
  const [authorPerformanceList, setAuthorPerformanceList] = useState([]);
  const [totalCounts, setTotalCounts] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [totalCount, setTotalCount] = useState(0);
  const [authors, setAuthors] = useState([]);
  const [defalutAuthor, setDefaultAuthor] = useState([]);
  const [defalutStroryType, setDefalutStroryType] = useState([]);
  const [isXlLoading, setIsXlLoading] = useState(false);
  const Story = [
    { value: null, label: "Select Story Type" },
    { value: "Text", label: "Text Story" },
    { value: "Video", label: "Video Story" },
    { value: "Web", label: "Web Story" },
    { value: "Photo", label: "Photo Story" },
    { value: "Live", label: "Live Story" },
  ];

  const getAuthorPerformance = async () => {
    setIsLoading(true);
    let url = `${config.apiUrl}/reports/author-performance?`;

    if (dateRange?.startDate && dateRange?.endDate) {
      url += `&startDate=${dateRange.startDate}&endDate=${dateRange.endDate}`;
    }
    if (selectedAuthor?.id) url += `&author=${selectedAuthor.id}`;
    if (selectedStoryType?.id) url += `&story_type=${selectedStoryType.id}`;

    const response = await fetch(url);
    const data = await response.json();

    setTotalCount(data?.[0]?.totalCount || 0);
    setAuthorPerformanceList(data?.[0]?.stories || []);
    setTotalCounts(data?.[0]?.counts ||{});
    setIsLoading(false);
  };

  const onExportSheet = async () => {
    setIsXlLoading(true);
    let url = `${config.apiUrl}/reports/author-performance/xl?`;

    if (dateRange?.startDate && dateRange?.endDate) {
      url += `&startDate=${dateRange.startDate}&endDate=${dateRange.endDate}`;
    }
    if (selectedAuthor?.id) url += `&author=${selectedAuthor.id}`;
    if (selectedStoryType?.id) url += `&story_type=${selectedStoryType.id}`;



    const response = await fetch(url);
    const blob = await response.blob();
    const sheeturl = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = sheeturl;
    link.setAttribute('download', 'AuthorPerformance.xlsx'); // File name
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);

    setIsXlLoading(false);
  };
  const getAllAuthors = async () => {
    try {
      const url = `${config.apiUrl}/reports/all-author`;
      const response = await fetch(url);
      const data = await response.json();

      const transformedAuthors = data.users.map((author) => ({
        value: author._id,
        label: author.name,
      }));

      setAuthors([{ value: null, label: "Select Author Name" }, ...transformedAuthors]);
      const defaultOption = transformedAuthors.filter(option => selectedAuthor.id.includes(option.value));
      setDefaultAuthor(defaultOption)
    } catch (error) {
      console.error("Error fetching authors:", error);
    }
  };

  useEffect(() => {
    // console.log("Updating URL and fetching author performance");

    // Get the current URL parameters
    const currentParams = new URLSearchParams(window.location.search);

    // Flag to determine if URL parameters need to be updated
    let shouldUpdateParams = false;

    // Handle date range: startDate
    if (dateRange?.startDate) {
      if (currentParams.get("startDate") !== dateRange.startDate) {
        currentParams.set("startDate", dateRange.startDate);
        shouldUpdateParams = true;
      }
    } else if (currentParams.has("startDate")) {
      currentParams.delete("startDate");
      shouldUpdateParams = true;
    }

    // Handle date range: endDate
    if (dateRange?.endDate) {
      if (currentParams.get("endDate") !== dateRange.endDate) {
        currentParams.set("endDate", dateRange.endDate);
        shouldUpdateParams = true;
      }
    } else if (currentParams.has("endDate")) {
      currentParams.delete("endDate");
      shouldUpdateParams = true;
    }

    // Handle selected author
    if (selectedAuthor?.id) {
      if (currentParams.get("author") !== selectedAuthor.id) {
        currentParams.set("author", selectedAuthor.id);
        shouldUpdateParams = true;
      }
    } else if (currentParams.has("author")) {
      currentParams.delete("author");
      shouldUpdateParams = true;
    }

    // Handle selected story type
    if (selectedStoryType?.id) {
      if (currentParams.get("storyType") !== selectedStoryType.id) {
        currentParams.set("storyType", selectedStoryType.id);
        shouldUpdateParams = true;
      }

      const selectedStoryTypes = Story.filter((option) =>
        selectedStoryType.id.includes(option.value)
      );
      setDefalutStroryType(selectedStoryTypes);
    } else if (currentParams.has("storyType")) {
      currentParams.delete("storyType");
      shouldUpdateParams = true;
    }

    // Update the URL only if changes are detected
    if (shouldUpdateParams) {
      setSearchParams(currentParams);
    }

    // Fetch data after parameters are updated

    getAuthorPerformance();
  }, [dateRange, selectedAuthor, selectedStoryType, setSearchParams]);

  const OnNavigateHandle = async (item) => {
    // console.log("user navogate data->>",item)
    let url = `/reports/author?author=${item?.authorId}`;

    if (dateRange?.startDate && dateRange?.endDate) {
      url += `&startDate=${dateRange.startDate}&endDate=${dateRange.endDate}`;
    }

    // if (selectedStoryType?.id) url += `&story_type=${selectedStoryType.id}`;
    // console.log("check url=>",url)
    navigate(url)
  }

  // Fetch authors on initial render
  useEffect(() => {
    getAllAuthors();
  }, []);
  // console.log("check selectedAuthor=>>>", selectedAuthor);
  return (
    <>
      <div className="content">
        <div className="card cus-card-h">
          <div className="card-body">
            <div className="row">
              <div className="col-md-2">
                <strong>Date Range</strong>
                <MyDateRangePicker setDateRange={setDateRange}
                  defaultValue={dateRange.startDate ? [new Date(dateRange?.startDate), new Date(dateRange?.endDate)] : null}
                  format="dd/MM/yyyy"
                />
              </div>
              <div className="col-md-2">
                <SyncSelect
                  option={authors}
                  label={"Author Name"}
                  setQuery={setSelectedAuthor}
                  defaultOption={defalutAuthor}
                />
              </div>
              <div className="col-md-2">
                <SyncSelect
                  option={Story}
                  label={"Story Type"}
                  defaultOption={defalutStroryType}
                  setQuery={setSelectedStoryType}
                />
              </div>
              <div className="col-md-2 align-self-end">
                <button className="btn btn-success" onClick={onExportSheet}>
                  <i className="fa fa-file-excel-o"></i> {isXlLoading ? "Exporting..." : "Export"}
                </button>
              </div>
              <div className="col-md-4 align-self-end">
                <div>
                  <strong>Total Count</strong>
                  <h6>{totalCount}</h6>
                </div>
              </div>
            </div>
            <table className="table table-bordered mt-4">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Author Name</th>
                  <th>Submitted</th>
                  <th>Published</th>
                  <th>Rejected</th>
                  <th>Total</th>
                  <th>% Published</th>
                </tr>
              </thead>

              {!isLoading ? (authorPerformanceList.length > 0 && (
                <tbody>
                  {authorPerformanceList.map((item, index) => {
                    const submitted = item?.statuses?.submitted || 0;
                    const published = item?.statuses?.published || 0;
                    const rejected = item?.statuses?.rejected || 0;
                    const publishedPercentage = item?.statuses?.total > 0 ? ((published / item?.statuses?.total) * 100).toFixed(2) : 0;
                    return (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td onClick={() => OnNavigateHandle(item)} className="cursor-pointer">
                          {item.authorName}
                        </td>
                        <td>{submitted}</td>
                        <td>{published}</td>
                        <td>{rejected}</td>
                        <td>{item?.statuses?.total}</td>
                        <td>{publishedPercentage}</td>
                      </tr>
                    );
                  })}

                  <tr className="fw-bold">
                    <td>{authorPerformanceList?.length + 1}</td>
                    <td className="cursor-pointer"> Total </td>
                    <td>{totalCounts?.totalSubmitted}</td>
                    <td>{totalCounts?.totalPublished}</td>
                    <td>{totalCounts?.totalRejected}</td>
                    <td>{totalCounts?.totalTotal}</td>
                    <td>{totalCounts?.totalTotal > 0 ? ((totalCounts?.totalPublished / totalCounts?.totalTotal) * 100).toFixed(2) : '0.00'}</td>
                  </tr>
                </tbody>
              )) : (
                <TableLoader count={6} />
              )}
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default AuthorPerformance;
