import { useEffect, useState } from "react";
import Loader from "../../common/utils/loader";
import { CheckAccess } from "../../common/utils/roleAccess";
import moment from "moment";
import { CreateDataApi, GetListApi } from "../../common/api/api";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { Controller, useForm } from "react-hook-form";
import $ from 'jquery';
import 'daterangepicker';
import config from "../../../config";
import { toast } from "react-toastify";
import { apiEndpoints } from "../../common/api/apiEndpoints";
import Pagination from "../../common/pagination";
const animatedComponents = makeAnimated();

const EditedRss = () => {
    const { control, handleSubmit, watch, getValues, setValue } = useForm();

    const [loading, setLoading] = useState(false)
    const [integrationSettings, setIntegrationSettings] = useState({})
    const [rssBreaking, setRssBreaking] = useState([])
    const [rssFeedOptions, setRssFeedOptions] = useState([])
    const [sourceOptions, setSourceOptions] = useState([])
    const [genreOptions, setGenreOptions] = useState([])
    const [cities, setCities] = useState([])
    const [states, setStates] = useState([])
    const [countries, setCountries] = useState([])
    const [continents, setContinents] = useState([])
    const [stateWiseCities, setStateWiseCities] = useState(null)
    const [continentWiseCountries, setContinentWiseCountries] = useState(null)
    const [fbPostingId, setFbPostingId] = useState({})
    const [igPostingId, setIgPostingId] = useState({})
    const [twPostingId, setTwPostingId] = useState({})
    const [isSocialPosting, setIsSocialPosting] = useState({})
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPages, setTotalPages] = useState(0)
    const [totalCount, setTotalCount] = useState(0)
    const [pageSize, setPageSize] = useState(50)

    const fetchIntegrationSettings = async () => {
        const result = await GetListApi(`integration-settings`)
        setIntegrationSettings(result)
    }

    const fetchRssBreaking = async (query = '') => {
        setRssBreaking([])
        setLoading(true)
        const result = await GetListApi(`rss-breaking-stories${query}`)
        setRssBreaking(result?.data)
        setTotalPages(result?.totalPages)
        setTotalCount(result?.totalCount)
        setLoading(false)
    }

    const fetchRssFeeds = async () => {
        const result = await GetListApi('rss-feed')
        const options = result?.data?.map(item => ({ label: item?.name, value: item?.name }))
        setRssFeedOptions(options)
    }

    const fetchCommonMasters = async () => {
        const result = await GetListApi('common-masters')
        const sources = result?.data?.filter(item => item?.type === 'source')
        const genres = result?.data.filter(item => item?.type === 'genre')

        setSourceOptions(sources?.map(item => ({ label: item?.name, value: item?.name })))
        setGenreOptions(genres?.map(item => ({ label: item?.name, value: item?.name })))
    }

    const fetchCountriesData = async (data) => {
        data = data?.find(item => item?.name === "stateCityDatastateCityData")?.data
        const states = Object.keys(data)?.map(item => ({ label: item, value: item })).sort((a, b) => a.label.localeCompare(b.label));

        const stateWiseCities = { All: [{ label: 'All', value: 'All' }] }

        Object.keys(data)?.map((key) => {
            const cities = data[key]?.map(item => ({ value: item, label: item })).sort((a, b) => a.label.localeCompare(b.label))
            stateWiseCities[key] = cities
        })

        setStates([{ label: 'All', value: 'All' }, ...states]);
        setStateWiseCities(stateWiseCities)
    }

    const fetchContinetsData = async (data) => {
        data = data?.find(item => item?.name === "continentCountryData")?.data

        const continentsData = Object.keys(data)?.map(item => ({ label: item, value: item })).sort((a, b) => a.label.localeCompare(b.label));

        const ContinentCountryData = { All: [{ label: 'All', value: 'All' }] }

        Object.keys(data)?.map((key) => {
            const countries = data[key]?.map(item => ({ value: item, label: item })).sort((a, b) => a.label.localeCompare(b.label))
            ContinentCountryData[key] = countries
        })

        setContinents([{ label: 'All', value: 'All' }, ...continentsData]);
        setContinentWiseCountries(ContinentCountryData)
    }

    useEffect(() => {
        const tempState = watch('state')?.value
        if (tempState) {
            const temp = [{ label: 'All', value: 'All' }, ...stateWiseCities[tempState]]
            setCities(temp)
        }
    }, [watch('state')])

    useEffect(() => {
        const tempContinent = watch('continent')?.value
        if (tempContinent) {
            const temp = [{ label: 'All', value: 'All' }, ...continentWiseCountries[tempContinent]]
            setCountries(temp)
        }
    }, [watch('continent')])

    useEffect(() => {
        const getData = async () => {
            const data = (await (await fetch(`${config.apiUrl}/utilities`)).json())?.data
            fetchCountriesData(data)
            fetchContinetsData(data)
        }
        getData()
    }, [])


    useEffect(() => {
        const getData = async () => {
            await fetchIntegrationSettings()
            await fetchRssFeeds()
            await fetchCommonMasters()
        }
        getData()
    }, [])

    useEffect(() => {
        const getData = async () => {
            await filterButtonClick()
        }
        if (currentPage) getData()
    }, [currentPage])

    useEffect(() => {
        const inputElement = $('input[name="daterangeCreated"]');

        $('input[name="daterangeCreated"]').daterangepicker({
            locale: {
                format: 'DD/MM/YYYY'
            },
            autoUpdateInput: false
        }, function (start, end, label) {
            let selectedDateRange = `${start.format('DD/MM/YYYY')} - ${end.format('DD/MM/YYYY')}`
            inputElement.val(selectedDateRange);

            selectedDateRange = `${start.format('YYYY-MM-DD')} - ${end.format('YYYY-MM-DD')}`
            setValue('createdDate', selectedDateRange)
        });
    }, []);

    const filterButtonClick = async (title = '') => {
        const values = getValues()
        let query = ""

        Object.keys(values)?.map((key, index) => {
            if (key !== 'createdDate' && values?.[key]?.value) {
                if (!query) query = `?${key}=${values?.[key]?.value}`
                else query += `&${key}=${values?.[key]?.value}`

                setValue(`filters.${key}`, values?.[key]?.value)
            }
        })

        if (values?.createdDate) {
            query += !query ? query = '?' : '&'
            query += `startDate=${values?.createdDate?.split(' - ')?.[0]}`
            query += `&endDate=${values?.createdDate?.split(' - ')?.[1]}`

            setValue(`filters.startDate`, values?.createdDate?.split(' - ')?.[0])
            setValue(`filters.endDate`, values?.createdDate?.split(' - ')?.[1])
        }

        if (title) {
            setValue('title', title)
            if (!query) query = `?title=${title}`
            else query += `&title=${title}`
        }

        query = query + (query ? '&' : '?') + `page=${currentPage}&pageSize=${pageSize}`
        await fetchRssBreaking(query)
    }


    const removeFilter = async (key = '') => {
        if (!key) return
        setValue(`filters.${key}`, null)
        setValue(key, null)
        await filterButtonClick()
    }

    const shareToFacebook = async (id, setLoading = false) => {
        if (setLoading) setFbPostingId(prev => ({ ...prev, [id]: true }))

        const result = await CreateDataApi(apiEndpoints.rssFacebookPost(id))

        if (setLoading) {
            const tempRssBreaking = rssBreaking.map(item => {
                if (item?._id === id) {
                    return { ...item, facebook_post_id: result?.id, };
                }
                return item;
            });

            setRssBreaking(tempRssBreaking);
            setFbPostingId(prev => ({ ...prev, [id]: false }))
        }

        return result?.id
    }

    const shareToInstagram = async (id, setLoading = false) => {
        if (setLoading) setIgPostingId(prev => ({ ...prev, [id]: true }))
        const result = await CreateDataApi(apiEndpoints.rssInstagramPost(id))

        if (setLoading) {
            const tempRssBreaking = rssBreaking.map(item => {
                if (item?._id === id) {
                    return {
                        ...item, instagram_post_id: result?.id, instagram_permalink: result?.permalink,
                    };
                }
                return item;
            });

            setRssBreaking(tempRssBreaking);
            setIgPostingId(prev => ({ ...prev, [id]: false }))
        }

        return result
    }

    const shareToTwitter = async (id, setLoading = false) => {
        if (setLoading) setTwPostingId(prev => ({ ...prev, [id]: true }))
        const result = await CreateDataApi(apiEndpoints.rssTwitterPost(id))

        if (setLoading) {
            const tempRssBreaking = rssBreaking.map(item => {
                if (item?._id === id) {
                    return { ...item, twitter_post_id: result?.id };
                }
                return item;
            });

            setRssBreaking(tempRssBreaking);
            setTwPostingId(prev => ({ ...prev, [id]: false }))
        }

        return result?.id
    }

    const shareToAllSocial = async (id) => {
        if (!id) {
            toast.console.warn('Id not found');
            return;
        }

        setIsSocialPosting(prev => ({ ...prev, [id]: true }))

        const [fbId, igResponse, twId] = await Promise.all([
            shareToFacebook(id),
            shareToInstagram(id),
            shareToTwitter(id)
        ]);

        const tempRssBreaking = rssBreaking.map(item => {
            if (item?._id === id) {
                return {
                    ...item,
                    facebook_post_id: fbId,
                    instagram_post_id: igResponse?.id,
                    instagram_permalink: igResponse?.permalink,
                    twitter_post_id: twId
                };
            }
            return item;
        });

        setRssBreaking(tempRssBreaking);

        setIsSocialPosting(prev => ({ ...prev, [id]: false }))
    }

    const navigateToFacebook = (id) => {
        const parts = id?.split('_');
        const url = `https://facebook.com/${parts?.[0]}/posts/${parts?.[1]}`;
        window.open(url, '_blank');
    };

    const navigateToInstagram = (url) => {
        window.open(url, '_blank');
    }

    const navigateToTwitter = (id) => {
        const userName = integrationSettings?.twitterSharing?.userName
        const url = `https://x.com/${userName}/status/${id}`
        window.open(url, '_blank');
    }

    const handlePageChange = (page) => {
        setCurrentPage(page)
    }

    return (
        <div style={{ backgroundColor: '#f9f9f9' }}>
            <div className="offcanvas offcanvas-end" tabIndex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
                <div className="offcanvas-header">
                    <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                    <h5 id="offcanvasRightLabel">RSS</h5>
                    <button onClick={() => { filterButtonClick() }} className="btn btn-primary btn-round btn-sm" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight">Apply Filters</button>
                </div>
                <div className="offcanvas-body">
                    <div className="tab-content" id="myTabContent">
                        <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                            <div className="row mb-30 mt-30">
                                <label htmlFor="meta_description" className="col-sm-4 col-form-label text-right">
                                    Name
                                </label>
                                <div className="col-sm-8">
                                    <Controller
                                        name="name"
                                        control={control}
                                        render={({ field }) => (
                                            <Select
                                                {...field}
                                                className="outline-none"
                                                closeMenuOnSelect={true}
                                                components={animatedComponents}
                                                options={rssFeedOptions}
                                            />
                                        )}
                                    />
                                </div>
                            </div>

                            <div className="row mb-30 mt-30">
                                <label htmlFor="meta_description" className="col-sm-4 col-form-label text-right">
                                    Source
                                </label>
                                <div className="col-sm-8">
                                    <Controller
                                        name="source"
                                        control={control}
                                        render={({ field }) => (
                                            <Select
                                                {...field}
                                                className="outline-none"
                                                closeMenuOnSelect={true}
                                                components={animatedComponents}
                                                options={sourceOptions}
                                            />
                                        )}
                                    />
                                </div>
                            </div>


                            <div className="row mb-30 mt-30">
                                <label htmlFor="meta_description" className="col-sm-4 col-form-label text-right">
                                    Genre
                                </label>
                                <div className="col-sm-8">
                                    <Controller
                                        name="genre"
                                        control={control}
                                        render={({ field }) => (
                                            <Select
                                                {...field}
                                                className="outline-none"
                                                closeMenuOnSelect={true}
                                                components={animatedComponents}
                                                options={genreOptions}
                                            />
                                        )}
                                    />
                                </div>
                            </div>

                            <div className="row mb-30 mt-30">
                                <label htmlFor="meta_description" className="col-sm-4 col-form-label text-right">
                                    State
                                </label>
                                <div className="col-sm-8">
                                    <Controller
                                        name="state"
                                        control={control}
                                        render={({ field }) => (
                                            <Select
                                                {...field}
                                                className="outline-none"
                                                closeMenuOnSelect={true}
                                                components={animatedComponents}
                                                options={states}
                                            />
                                        )}
                                    />
                                </div>
                            </div>

                            <div className="row mb-30 mt-30">
                                <label htmlFor="meta_description" className="col-sm-4 col-form-label text-right">
                                    City
                                </label>
                                <div className="col-sm-8">
                                    <Controller
                                        name="city"
                                        control={control}
                                        render={({ field }) => (
                                            <Select
                                                {...field}
                                                className="outline-none"
                                                closeMenuOnSelect={true}
                                                components={animatedComponents}
                                                options={cities}
                                            />
                                        )}
                                    />
                                </div>
                            </div>

                            <div className="row mb-30 mt-30">
                                <label htmlFor="meta_description" className="col-sm-4 col-form-label text-right">
                                    Continent
                                </label>
                                <div className="col-sm-8">
                                    <Controller
                                        name="continent"
                                        control={control}
                                        render={({ field }) => (
                                            <Select
                                                {...field}
                                                className="outline-none"
                                                closeMenuOnSelect={true}
                                                components={animatedComponents}
                                                options={continents}
                                            />
                                        )}
                                    />
                                </div>
                            </div>

                            <div className="row mb-30 mt-30">
                                <label htmlFor="meta_description" className="col-sm-4 col-form-label text-right">
                                    Country
                                </label>
                                <div className="col-sm-8">
                                    <Controller
                                        name="country"
                                        control={control}
                                        render={({ field }) => (
                                            <Select
                                                {...field}
                                                className="outline-none"
                                                closeMenuOnSelect={true}
                                                components={animatedComponents}
                                                options={countries}
                                            />
                                        )}
                                    />
                                </div>
                            </div>

                            <div className="row mb-30 mt-30">
                                <label htmlFor="daterangeCreated" className="col-sm-4 col-form-label text-right">
                                    Created Date
                                </label>
                                <div className="col-sm-8">
                                    <input type="text" name="daterangeCreated" placeholder="Select Created Date Range" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="content" style={{ backgroundColor: '#f9f9f9' }}>
                <div className="content">
                    <div className="card col-md-12 m-auto p-30">
                        <div className="card-header">
                            <h3 className="text-bold">Edited Rss</h3>
                            <input
                                type="search"
                                placeholder="Enter title"
                                className="form-control story-search-input"
                                onKeyDown={async (e) => {
                                    if (e.key === 'Enter') {
                                        await filterButtonClick(e.target.value)
                                    }
                                }}
                            />

                            <button className="btn btn-primary btn-round btn-sm" type="button" onClick={() => { window.open('/rss-breaking', '_blank'); }}>Add More</button>

                            <button className="btn btn-primary btn-round btn-sm" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight" onClick={() => { }}>Filter</button>
                        </div>

                        <div className="filters-container">
                            <p>Applied Filters: </p>
                            {Object.keys(watch('filters') || {}).map((key) => (
                                watch('filters')[key]?.label || watch('filters')[key] && (
                                    <p className="current-filters ml-10">
                                        {key}: {watch('filters')[key]?.label || watch('filters')[key]}
                                        <span className='filter-close' onClick={() => removeFilter(key)}>X</span>
                                    </p>
                                )
                            ))}
                        </div>

                        <div className="table-responsive">
                            <table className="table table-hover">
                                <tbody>
                                    {!rssBreaking?.length > 0 || loading ? (
                                        <Loader />
                                    ) : (
                                        rssBreaking?.map((story, index) => {
                                            return (
                                                <tr key={story?._id}>
                                                    <td style={{ lineHeight: '0.8em', widht: '95%' }} >
                                                        <div className="row">
                                                            <div
                                                                className="col-10"
                                                                onMouseLeave={(e) => e.target.style.color = 'initial'}
                                                                style={{ cursor: 'pointer', fontSize: '1.3rem', lineHeight: '1.4' }}
                                                            >
                                                                <a href={`/rss-breaking/edit/${story?.breakingDetails?._id}`} target='_blank'>
                                                                    {story?.title}
                                                                </a>
                                                            </div>

                                                            <div className="col-2 row " style={{ visibility: 'hidden' }}>
                                                                {
                                                                    !story?.facebook_post_id && !story?.instagram_post_id && !story?.twitter_post_id &&
                                                                    <div>
                                                                        <button className="btn btn-small btn-primary" onClick={() => { shareToAllSocial(story?._id) }}>
                                                                            {isSocialPosting[story?._id] ? <div className="spinner-border spinner-border-sm tc-submit-loading" role="status"> </div> : 'Social Share'}
                                                                        </button>
                                                                    </div>
                                                                }

                                                                {
                                                                    (story?.facebook_post_id || story?.instagram_post_id || story?.twitter_post_id) &&
                                                                    <div className=" edited-rss-social">
                                                                        <div className="">
                                                                            {
                                                                                fbPostingId[story?._id]
                                                                                    ?
                                                                                    <div className="spinner-border spinner-border-sm tc-submit-loading" role="status" />
                                                                                    :
                                                                                    <svg onClick={() => { story?.facebook_post_id ? navigateToFacebook(story?.facebook_post_id) : shareToFacebook(story?._id, true) }} xmlns="http://www.w3.org/2000/svg" width={16} height={16} viewBox="0 0 320 512"><path d="M80 299.3V512H196V299.3h86.5l18-97.8H196V166.9c0-51.7 20.3-71.5 72.7-71.5c16.3 0 29.4 .4 37 1.2V7.9C291.4 4 256.4 0 236.2 0C129.3 0 80 50.5 80 159.4v42.1H14v97.8H80z" /></svg>
                                                                            }
                                                                        </div>

                                                                        <div className="">
                                                                            {
                                                                                igPostingId[story?._id]
                                                                                    ?
                                                                                    <div className="spinner-border spinner-border-sm tc-submit-loading" role="status" />
                                                                                    :
                                                                                    <svg onClick={() => { story?.instagram_permalink ? navigateToInstagram(story?.instagram_permalink) : shareToInstagram(story?._id, true) }} xmlns="http://www.w3.org/2000/svg" width={16} height={16} viewBox="0 0 448 512"><path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z" /></svg>
                                                                            }
                                                                        </div>

                                                                        <div className="">
                                                                            {
                                                                                twPostingId[story?._id]
                                                                                    ?
                                                                                    <div className="spinner-border spinner-border-sm tc-submit-loading" role="status" />
                                                                                    :
                                                                                    <svg onClick={() => { story?.twitter_post_id ? navigateToTwitter(story?.twitter_post_id) : shareToTwitter(story?._id, true) }} xmlns="http://www.w3.org/2000/svg" width={16} height={16} viewBox="0 0 512 512"><path d="M459.4 151.7c.3 4.5 .3 9.1 .3 13.6 0 138.7-105.6 298.6-298.6 298.6-59.5 0-114.7-17.2-161.1-47.1 8.4 1 16.6 1.3 25.3 1.3 49.1 0 94.2-16.6 130.3-44.8-46.1-1-84.8-31.2-98.1-72.8 6.5 1 13 1.6 19.8 1.6 9.4 0 18.8-1.3 27.6-3.6-48.1-9.7-84.1-52-84.1-103v-1.3c14 7.8 30.2 12.7 47.4 13.3-28.3-18.8-46.8-51-46.8-87.4 0-19.5 5.2-37.4 14.3-53 51.7 63.7 129.3 105.3 216.4 109.8-1.6-7.8-2.6-15.9-2.6-24 0-57.8 46.8-104.9 104.9-104.9 30.2 0 57.5 12.7 76.7 33.1 23.7-4.5 46.5-13.3 66.6-25.3-7.8 24.4-24.4 44.8-46.1 57.8 21.1-2.3 41.6-8.1 60.4-16.2-14.3 20.8-32.2 39.3-52.6 54.3z" /></svg>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </div>

                                                            <div style={{ display: 'flex', justifyContent: 'left', fontSize: '1rem', height: '1.2em', marginTop: '8px' }}>
                                                                <p className="slsd-col">
                                                                    <span className="fw-500 mr-2">Name:</span>
                                                                    {story?.feedDetails?.name}
                                                                </p>

                                                                <p className="slsd-col ml-15">
                                                                    <span className="fw-500 mr-2">Created Date:</span>
                                                                    {moment(story?.created_on).format("DD MMMM YYYY, hh:mm A")}
                                                                </p>

                                                                {/* <p className="slsd-col ml-15">
                                                                <span className="fw-500 mr-2">Status:</span>
                                                                {story?.status}
                                                            </p> */}

                                                                <p className="slsd-col ml-15">
                                                                    <span className="fw-500 mr-2">Genre:</span>
                                                                    {story?.feedDetails?.genre || '-'}
                                                                </p>

                                                                <p className="slsd-col ml-15">
                                                                    <span className="fw-500 mr-2">Source:</span>
                                                                    {story?.feedDetails?.source || '-'}
                                                                </p>

                                                                <p className="slsd-col ml-15">
                                                                    <span className="fw-500 mr-2">Type:</span>
                                                                    {story?.feedDetails?.type}
                                                                </p>

                                                                {
                                                                    story?.feedDetails?.type === 'national' &&
                                                                    <p className="slsd-col ml-15">
                                                                        <span className="fw-500 mr-2">State:</span>
                                                                        {story?.feedDetails?.state || '-'}
                                                                    </p>
                                                                }

                                                                {
                                                                    story?.feedDetails?.type === 'national' &&
                                                                    <p className="slsd-col ml-15">
                                                                        <span className="fw-500 mr-2">City:</span>
                                                                        {story?.feedDetails?.city || '-'}
                                                                    </p>
                                                                }

                                                                {
                                                                    story?.feedDetails?.type === 'international' &&
                                                                    <p className="slsd-col ml-15">
                                                                        <span className="fw-500 mr-2">Continent:</span>
                                                                        {story?.feedDetails?.continent || '-'}
                                                                    </p>
                                                                }

                                                                {
                                                                    story?.feedDetails?.type === 'international' &&
                                                                    <p className="slsd-col ml-15">
                                                                        <span className="fw-500 mr-2">Country:</span>
                                                                        {story?.feedDetails?.country || '-'}
                                                                    </p>
                                                                }

                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            );
                                        })
                                    )}
                                </tbody>
                            </table>

                            {
                                totalPages > 0 &&
                                <div className="pagination-container">
                                    <div className="pagination-count">
                                        <p>Results: {(currentPage - 1) * pageSize + 1} - {Math.min(currentPage * pageSize, totalCount)} of {totalCount}</p>
                                    </div>
                                    <Pagination
                                        currentPage={currentPage}
                                        totalPages={totalPages}
                                        onPageChange={handlePageChange}
                                    />
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
};

export default EditedRss;
