const Loader = () => {
  return (
    <>
        <div id="loader">
          <div className="d-flex flex-column align-items-center">
            <div className="spinner-grow text-gold"></div>
            <span className="text-gold mt-1">Loading...</span>
          </div>
        </div>
    </>
  );
};

export default Loader;
