import Offcanvas from "react-bootstrap/Offcanvas";
import React from "react";
import Modal from "react-bootstrap/Modal";
import { Badge, Card } from "react-bootstrap";
import moment from "moment";

const Timeline = (props) => {
  const { showTimeline, setShowTimeline, timelines } = props;
  return (
    <div>
      <Offcanvas
        // className="story-canvas"
        show={showTimeline}
        placement="end"
        onHide={() => setShowTimeline(false)}
      >
        <Offcanvas.Header
          closeButton
          className={`flex-row-reverse justify-content-end`}
        >
          <Offcanvas.Title className={`mb-0 ms-3 text-uppercase`}>
            <h4 className="text-bold mb-0 timeline-heading">Timeline</h4>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          {timelines?.map((user, key) => {
            return (
              <Card key={key} className={`shadow-none bg-gray`}>
                <Card.Body>
                  <Card.Title className="text-bold text-dark p-0">
                    {user?.action_by?.name}
                  </Card.Title>
                  <Card.Subtitle className="mb-2 text-muted">
                    {moment(user?.action_on)?.format(
                      "MMMM DD YYYY, hh:mm:ss a"
                    )}
                  </Card.Subtitle>
                  <Badge className={`text-capitalize`}>{user?.action_type}</Badge>
                </Card.Body>
              </Card>
            );
          })}
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
};

export default Timeline;
