import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import Select from 'react-select'
import makeAnimated from "react-select/animated";
import { toast } from "react-toastify";
import config from "../../../config";
import { Link } from "react-router-dom";
import * as bootstrap from 'bootstrap';
import Swal from "sweetalert2";
import axios from "axios";
import { CreateDataApi, GetListApi } from "../../common/api/api";
import Pagination from "../../common/pagination";

const country_state_city = require('country-state-city')
const animatedComponents = makeAnimated();
const user = JSON.parse(localStorage.getItem('user'))

const ListRssFeed = () => {
    const { register, handleSubmit, control, formState: { errors }, watch, getValues, setValue } = useForm();

    const [isScrapping, setIsScrapping] = useState(false)
    const [selectedRssType, setSelectedRssType] = useState(null)
    const rssTypes = [{ label: 'National', value: 'national' }, { label: 'International', value: 'international' }]

    const categoryList = ['source', 'genre']
    const categories = categoryList?.map(item => ({ label: item, value: item }))
    const [selectedCategory, setSelectedCategory] = useState({})

    const [selectedState, setSelectedState] = useState(null)
    const [states, setStates] = useState([])

    const [selectedCity, setSelectedCity] = useState(null)
    const [cities, setCities] = useState([])

    const [selectedContinent, setSelectedContinent] = useState(null)
    const [continents, setContinents] = useState([])

    const [selectedCountry, setSelectedCountry] = useState(null)
    const [countries, setCountries] = useState([])

    const [rssFeedList, setRssFeedList] = useState(null)

    const [updateRssFeedId, setUpdateRssFeedId] = useState(null)

    const [stateWiseCities, setStateWiseCities] = useState({})
    const [continentWiseCountries, setContinentWiseCountries] = useState({})

    const [sources, setSources] = useState(null)
    const [selectedSource, setSelectedSource] = useState([])

    const [genres, setGenres] = useState(null)
    const [selectedGenere, setSelectedGenre] = useState([])

    const [currentPage, setCurrentPage] = useState(1)
    const [totalPages, setTotalPages] = useState(0)
    const [totalCount, setTotalCount] = useState(0)
    const [pageSize, setPageSize] = useState(50)

    const onSubmit = async () => {
        const values = getValues()

        const data = {
            type: values?.rss_type?.value,
            state: values?.state?.value,
            city: values?.city?.value,
            continent: values?.continent?.value,
            country: values?.country?.value,
            name: values?.name,
            url: values?.url,
            category: values?.category?.value,
            source: values?.source?.value,
            genre: values?.genre?.value,
            frequency: values?.frequency
        }

        const url = `${config.apiUrl}/rss-feed${updateRssFeedId ? ('/' + updateRssFeedId) : ''}`

        const response = await fetch(url, {
            method: updateRssFeedId ? 'PUT' : "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ data, user: { id: user?._id, name: user?.name } })
        })

        const responseData = await response.json()

        if (responseData?._id) {
            toast.success("Rss Feed Saved !");
            document.querySelector('[data-bs-dismiss="offcanvas"]').click();
            await fetchRssFeedList()
        }
        else {
            toast.error('someting went wrong..')
        }
    }

    const fetchRssFeedList = async () => {
        const url = `${config.apiUrl}/rss-feed?page=${currentPage}&pageSize=${pageSize}`
        const response = await fetch(url)
        const data = await response?.json()
        setRssFeedList(data?.data)
        setTotalPages(data?.totalPages)
        setTotalCount(data?.totalCount)
    }

    const fetchCountriesData = async (data) => {
        data = data?.find(item => item?.name === "stateCityDatastateCityData")?.data
        const states = Object.keys(data)?.map(item => ({ label: item, value: item })).sort((a, b) => a.label.localeCompare(b.label));

        const stateWiseCities = {}

        Object.keys(data)?.map((key) => {
            const cities = data[key]?.map(item => ({ value: item, label: item })).sort((a, b) => a.label.localeCompare(b.label))
            stateWiseCities[key] = cities
        })

        setStates(states)
        setStateWiseCities(stateWiseCities)
    }

    const fetchContinetsData = async (data) => {
        data = data?.find(item => item?.name === "continentCountryData")?.data

        const continentsData = Object.keys(data)?.map(item => ({ label: item, value: item })).sort((a, b) => a.label.localeCompare(b.label));

        const ContinentCountryData = {}

        Object.keys(data)?.map((key) => {
            const countries = data[key]?.map(item => ({ value: item, label: item })).sort((a, b) => a.label.localeCompare(b.label))
            ContinentCountryData[key] = countries
        })

        setContinents(continentsData);
        setContinentWiseCountries(ContinentCountryData)
    }

    useEffect(() => {
        const getData = async () => {
            const data = (await (await fetch(`${config.apiUrl}/utilities`)).json())?.data
            fetchCountriesData(data)
            fetchContinetsData(data)

            const sourcesRes = await GetListApi(`common-masters`, { filter: { status: 'active', type: 'source' }, limit: 1000 })
            setSources(sourcesRes?.data?.map(item => ({ label: item?.name, value: item?.name })))

            const genresRes = await GetListApi(`common-masters`, { filter: { status: 'active', type: 'genre' }, limit: 1000 })
            setGenres(genresRes?.data?.map(item => ({ label: item?.name, value: item?.name })))
        }
        getData()
    }, [])

    useEffect(() => {
        const getData = async () => {
            await fetchRssFeedList()
        }
        if (currentPage) getData()
    }, [currentPage])

    useEffect(() => {
        if (selectedContinent) {
            const countries = continentWiseCountries?.[selectedContinent?.value]
            setCountries(countries)
        }
    }, [selectedContinent])

    useEffect(() => {
        if (selectedState) {
            setCities(stateWiseCities[selectedState?.value])
        }
    }, [selectedState])

    const setSelectedFeed = (feed = {}) => {
        setUpdateRssFeedId(feed?._id)

        const state = feed?.state ? { label: feed.state, value: feed.state } : null
        const city = feed?.city ? { label: feed.city, value: feed.city } : null
        const continent = feed?.continent ? { label: feed.continent, value: feed.continent } : null
        const country = feed?.country ? { label: feed.country, value: feed.country } : null
        const category = feed?.category ? { label: feed.category, value: feed.category } : null
        const source = feed?.source ? { label: feed.source, value: feed.source } : null
        const genre = feed?.genre ? { label: feed.genre, value: feed.genre } : null

        setValue('rss_type', feed?.type)
        setValue('state', state)
        setValue('city', city)
        setValue('continent', continent)
        setValue('country', country)
        setValue('name', feed?.name)
        setValue('genre', feed?.genre)
        setValue('url', feed?.url)
        setValue('category', category)
        setValue('genre', feed?.genre)
        setValue('source', source)
        setValue('frequency', feed?.frequency)

        setSelectedRssType(rssTypes?.find(item => item?.value === feed?.type) || null)
        setSelectedState(state)
        setSelectedCity(city)
        setSelectedContinent(continent)
        setSelectedCountry(country)
        setSelectedCategory(categories.find(item => item?.value === feed?.category) || null)
        setSelectedSource(sources.find(item => item?.value === feed?.source) || null)
        setSelectedGenre(genres.find(item => item?.value === feed?.genre) || null)

        const offcanvas = new bootstrap.Offcanvas(document.getElementById('offcanvasRight'))
        offcanvas.show()
    }

    const addNewFeed = () => {
        setSelectedFeed({})
    }

    const deleteFeed = async (id) => {
        if (!id) return

        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then((result) => {
            if (result.isConfirmed) {
                axios
                    .delete(`${config.apiUrl}/rss-feed/${id}`)
                    .then((response) => {
                        fetchRssFeedList();
                    })
                    .catch((error) => {
                        console.error("Error deleting item:", error?.message);
                    });
            }
        });
    }

    const scrapAllRssFeeds = async () => {
        setIsScrapping(true)
        const body = { user: { _id: user?._id } }
        try {
            const response = await CreateDataApi(`rss-feed/scrap-all-feeds`, body)
            console.log({ response })

            if (response?.message) {
                toast.success('Scrapped all feeds..')
            }
        }
        catch (error) {
            toast.error(error?.message)
        }
        finally {
            setIsScrapping(false)
        }
    }

    const handlePageChange = (page) => {
        setCurrentPage(page)
    }

    return (
        <div style={{ backgroundColor: '#f9f9f9' }}>
            <div className="offcanvas offcanvas-end" tabindex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
                <div className="offcanvas-header">
                    <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                    <h5 id="offcanvasRightLabel" >Rss Feed</h5>
                    <h1></h1>
                </div>

                <div className="offcanvas-body">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="row mb-30 mt-30">
                            <label htmlFor="rss_type" className="col-sm-4 col-form-label text-right">Rss Type</label>
                            <div className="col-sm-8">
                                <Controller
                                    name="rss_type"
                                    control={control}
                                    // rules={{ required: "RSS Type is required" }}
                                    render={({ field }) => (
                                        <Select
                                            {...field}
                                            components={animatedComponents}
                                            options={rssTypes}
                                            value={selectedRssType}
                                            onChange={(e) => {
                                                setValue('rss_type', e)
                                                setSelectedRssType(e)
                                            }}
                                        />
                                    )}
                                />
                                {errors.rss_type && <span className="text-danger">{errors.rss_type.message}</span>}
                            </div>
                        </div>

                        {selectedRssType?.value === "national" && (
                            <>
                                <div className="row mb-30 mt-30">
                                    <label htmlFor="state" className="col-sm-4 col-form-label text-right">State</label>
                                    <div className="col-sm-8">
                                        <Controller
                                            name="state"
                                            control={control}
                                            // rules={{ required: "State is required" }}
                                            render={({ field }) => (
                                                <Select
                                                    {...field}
                                                    components={animatedComponents}
                                                    options={states}
                                                    value={selectedState}
                                                    onChange={(e) => {
                                                        setValue('state', e)
                                                        setSelectedState(e)
                                                        setValue('continent', null)
                                                        setSelectedContinent(null)
                                                    }}
                                                />
                                            )}
                                        />
                                        {errors.state && <span className="text-danger">{errors.state.message}</span>}
                                    </div>
                                </div>

                                <div className="row mb-30 mt-30">
                                    <label htmlFor="city" className="col-sm-4 col-form-label text-right">City</label>
                                    <div className="col-sm-8">
                                        <Controller
                                            name="city"
                                            control={control}
                                            // rules={{ required: "City is required" }}
                                            render={({ field }) => (
                                                <Select
                                                    {...field}
                                                    components={animatedComponents}
                                                    options={cities}
                                                    value={selectedCity}
                                                    onChange={(e) => {
                                                        setValue('city', e)
                                                        setSelectedCity(e)
                                                        setValue('country', null)
                                                        setSelectedCountry(null)
                                                    }}
                                                />
                                            )}
                                        />
                                        {errors.city && <span className="text-danger">{errors.city.message}</span>}
                                    </div>
                                </div>
                            </>
                        )}

                        {selectedRssType?.value === "international" && (
                            <>
                                <div className="row mb-30 mt-30">
                                    <label htmlFor="continent" className="col-sm-4 col-form-label text-right">Continent</label>
                                    <div className="col-sm-8">
                                        <Controller
                                            name="continent"
                                            control={control}
                                            // rules={{ required: "Continent is required" }}
                                            render={({ field }) => (
                                                <Select
                                                    {...field}
                                                    components={animatedComponents}
                                                    options={continents}
                                                    value={selectedContinent}
                                                    onChange={(e) => {
                                                        setValue('continent', e)
                                                        setSelectedContinent(e)
                                                        setValue('state', null)
                                                        setSelectedState(null)
                                                    }}
                                                />
                                            )}
                                        />
                                        {errors.continent && <span className="text-danger">{errors.continent.message}</span>}
                                    </div>
                                </div>

                                <div className="row mb-30 mt-30">
                                    <label htmlFor="country" className="col-sm-4 col-form-label text-right">Country</label>
                                    <div className="col-sm-8">
                                        <Controller
                                            name="country"
                                            control={control}
                                            // rules={{ required: "Country is required" }}
                                            render={({ field }) => (
                                                <Select
                                                    {...field}
                                                    components={animatedComponents}
                                                    options={countries}
                                                    value={selectedCountry}
                                                    onChange={(e) => {
                                                        setValue('country', e)
                                                        setSelectedCountry(e)
                                                        setValue('city', null)
                                                        setSelectedCity(null)
                                                    }}
                                                />
                                            )}
                                        />
                                        {errors.country && <span className="text-danger">{errors.country.message}</span>}
                                    </div>
                                </div>
                            </>
                        )}

                        <div className="row mb-30 mt-30">
                            <label htmlFor="name" className="col-sm-4 col-form-label text-right">Name</label>
                            <div className="col-sm-8">
                                <input
                                    className="form-control"
                                    type="text"
                                    {...register("name", { required: "Name is required" })}
                                    placeholder="Name"
                                />
                                {errors.name && <span className="text-danger">{errors.name.message}</span>}
                            </div>
                        </div>

                        <div className="row mb-30 mt-30">
                            <label htmlFor="url" className="col-sm-4 col-form-label text-right">Url</label>
                            <div className="col-sm-8">
                                <input
                                    className="form-control"
                                    type="text"
                                    {...register("url", { required: "URL is required" })}
                                    placeholder="URL"
                                />
                                {errors.url && <span className="text-danger">{errors.url.message}</span>}
                            </div>
                        </div>

                        {/* <div className="row mb-30 mt-30">
                            <label htmlFor="category" className="col-sm-4 col-form-label text-right">Category</label>
                            <div className="col-sm-8">
                                <Controller
                                    name="category"
                                    control={control}
                                    // rules={{ required: "Category is required" }}
                                    render={({ field }) => (
                                        <Select
                                            {...field}
                                            components={animatedComponents}
                                            options={categories}
                                            value={selectedCategory}
                                            onChange={(e) => {
                                                setValue('category', e)
                                                setSelectedCategory(e)
                                            }}
                                        />
                                    )}
                                />
                                {errors.category && <span className="text-danger">{errors.category.message}</span>}
                            </div>
                        </div> */}


                        {/* {
                            selectedCategory?.value === 'source' && */}
                        <div className="row mb-30 mt-30">
                            <label htmlFor="source" className="col-sm-4 col-form-label text-right">Source</label>
                            <div className="col-sm-8">
                                <Controller
                                    name="source"
                                    control={control}
                                    // rules={{ required: "Category is required" }}
                                    render={({ field }) => (
                                        <Select
                                            {...field}
                                            components={animatedComponents}
                                            options={sources}
                                            value={selectedSource}
                                            onChange={(e) => {
                                                setValue('source', e)
                                                setSelectedSource(e)
                                            }}
                                        />
                                    )}
                                />
                                {errors.source && <span className="text-danger">{errors.source.message}</span>}
                            </div>
                        </div>

                        {/* } */}

                        {/* {
                            selectedCategory?.value === 'genre' && */}
                        <div className="row mb-30 mt-30">
                            <label htmlFor="genre" className="col-sm-4 col-form-label text-right">Genre</label>
                            <div className="col-sm-8">
                                <Controller
                                    name="genre"
                                    control={control}
                                    // rules={{ required: "Category is required" }}
                                    render={({ field }) => (
                                        <Select
                                            {...field}
                                            components={animatedComponents}
                                            options={genres}
                                            value={selectedGenere}
                                            onChange={(e) => {
                                                setValue('genre', e)
                                                setSelectedGenre(e)
                                            }}
                                        />
                                    )}
                                />
                                {errors.genre && <span className="text-danger">{errors.genre.message}</span>}
                            </div>
                        </div>
                        {/* } */}

                        <div className="row mb-30 mt-30">
                            <label htmlFor="frequency" className="col-sm-4 col-form-label text-right">Frequency</label>
                            <div className="col-sm-8">
                                <input
                                    className="form-control"
                                    type="number"
                                    {...register("frequency", { required: "Frequency is required" })}
                                    placeholder="Frequency (in minutes)"
                                />
                                {errors.frequency && <span className="text-danger">{errors.frequency.message}</span>}
                            </div>
                        </div>

                        <div className="row mb-30 mt-60" style={{ display: 'flex', justifyContent: 'center' }}>
                            <button className="btn btn-primary" type="submit" style={{ width: '50%' }}>
                                {updateRssFeedId ? 'Update' : 'Save'}
                            </button>
                        </div>
                    </form>
                </div>

            </div>


            <div className="content" style={{ backgroundColor: '#f9f9f9' }}>
                <div className="content">
                    <div className="card col-md-12 m-auto p-30">

                        <div className="card-header">
                            <h3 className="text-bold">Rss Feed</h3>
                            <input
                                type="search"
                                placeholder="search..."
                                className="form-control story-search-input"
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {

                                    }
                                }}
                            />

                            <button disabled={isScrapping} className="btn btn-primary btn-round btn-sm" type="button" onClick={(e) => { e.preventDefault(); scrapAllRssFeeds({}) }}>
                                {isScrapping ? <div className="spinner-border spinner-border-sm tc-submit-loading" role="status"> </div> : 'Scrap All Feeds'}
                            </button>

                            <button className="btn btn-primary btn-round btn-sm" type="button" onClick={(e) => { e.preventDefault(); addNewFeed({}) }}>Add Rss Feed</button>

                            {/* <button className="btn btn-primary btn-round btn-sm" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight" onClick={() => { setSelectedFeed({}) }}>Add Rss Feed</button> */}
                        </div>

                        <div className="card-body">
                            <div className="table-responsive">
                                <table className="table table-hover capitalize-text">
                                    <thead>
                                        <tr>
                                            <th>Rss Type</th>
                                            <th>Name</th>
                                            <th>State</th>
                                            <th>City</th>
                                            <th>Continent</th>
                                            <th>Country</th>
                                            <th>Source</th>
                                            <th>Genre</th>
                                            <th>Frequency</th>
                                            <th>Url</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            rssFeedList?.map((feed, index) => (
                                                <tr key={index}>
                                                    <td>{feed?.type || '-'}</td>
                                                    <td>{feed?.name}</td>
                                                    <td>{feed?.state || '-'}</td>
                                                    <td>{feed?.city || '-'}</td>
                                                    <td>{feed?.continent || '-'}</td>
                                                    <td>{feed?.country || '-'}</td>
                                                    <td>{feed?.source || '-'}</td>
                                                    <td>{feed?.genre || '-'}</td>
                                                    <td>{feed?.frequency || '-'}</td>
                                                    <td style={{ textTransform: 'none' }}><a href={feed?.url} target="_blank">{feed?.url?.slice(0, 20) + `${feed?.url?.length > 20 ? '...' : ''}`}</a></td>
                                                    <td>
                                                        <Link onClick={(e) => { e.preventDefault(); setSelectedFeed(feed) }}  >
                                                            <i className="fa fa-edit f-16 text-c-green"></i>
                                                        </Link>
                                                        <Link onClick={(e) => { e.preventDefault(); deleteFeed(feed?._id) }}>
                                                            <i className="fa fa-trash ms-3 f-16 text-c-red"></i>
                                                        </Link>
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>

                                {
                                    totalPages > 0 &&
                                    <div className="pagination-container">
                                        <div className="pagination-count">
                                            <p>Results: {(currentPage - 1) * pageSize + 1} - {Math.min(currentPage * pageSize, totalCount)} of {totalCount}</p>
                                        </div>
                                        <Pagination
                                            currentPage={currentPage}
                                            totalPages={totalPages}
                                            onPageChange={handlePageChange}
                                        />
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
};

export default ListRssFeed;
