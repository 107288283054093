import { useEffect, useRef } from "react";
import { useState } from "react";
import { CreateDataApi, GetDataByIdApi } from "../../common/api/api";
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import ReactQuill from "react-quill";
import axios from "axios";
import config from "../../../config";
import { toast } from "react-toastify";

const user = JSON.parse(localStorage.getItem('user'))

const ListStory = () => {
    const { register, handleSubmit, formState: { errors }, watch, getValues, setValue } = useForm();

    const params = useParams()
    const id = params?.id
    const titleCount = 90
    const subTitleCount = 90
    const cardCount = 120

    const [rssBreaking, setRssBreaking] = useState({})
    const [imageUploading, setImageUploading] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [rssBreakingStoryLoading, setRssBreakingStoryLoading] = useState(false)

    const [output1, setOutput1] = useState('');
    const containerRef = useRef(null);

    useEffect(() => {
        if (containerRef.current) {
            const htmlContent = containerRef.current.textContent;
            setOutput1(htmlContent);
        }
    }, [containerRef?.current?.innerHTML]);

    const modules = {
        toolbar: [
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            ["bold", "italic", "underline", "strike"],
            // [{ font: [] }],
            // [{ color: [] }, { background: [] }],
            [{ align: [] }],
            [{ list: "ordered" }, { list: "bullet" }],
            ["link"],
            // [{ header: '1' }, { header: '2' }, { font: [] }],
            // [{ size: [] }],
            // ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            // [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
            // ['link', 'image', 'video'],
            // ['clean'],
        ],
        clipboard: {
            matchVisual: false,
        },
    };

    const formats = [
        "header",
        "bold",
        "italic",
        "underline",
        "strike",
        "font",
        "color",
        "background",
        "align",
        "list",
        "bullet",
        "link",
        "image",
    ];

    const fetchRssBreaking = async () => {
        const result = await GetDataByIdApi(`rss-breaking/${id}`)
        setRssBreaking(result)
    }

    const fetchRssBreakingStory = async () => {
        setRssBreakingStoryLoading(true)
        const result = (await GetDataByIdApi(`rss-breaking-stories?rss_breaking_story=${id}`))?.data?.[0]

        if (result) {
            setValue('title', result?.title)
            setValue('sub_title', result?.sub_title)
            setValue('url', result?.url)
            setValue('card', result?.card)
            setValue('image', result?.image)
            setValue('facebook_hashtag', result?.facebook_hashtag)
            setValue('instagram_hashtag', result?.instagram_hashtag)
            setValue('twitter_hashtag', result?.twitter_hashtag)
        }
        setRssBreakingStoryLoading(false)
    }

    useEffect(() => {
        const getData = async () => {
            await fetchRssBreaking()
            await fetchRssBreakingStory()
        }
        if (id) getData()
    }, [id])

    const onSubmit = async (data) => {
        if (!id) return

        const payload = {}

        Object.keys(data)?.map(key => {
            payload[key] = data[key]
        })

        setIsLoading(true)

        payload.rss_breaking_id = id
        payload.userId = user?._id

        const result = await CreateDataApi('rss-breaking-stories', payload)

        if (result?.message) {
            toast.success(result?.message)
            setIsLoading(false)
        }
        else {
            toast.error('Something went wrong')
            setIsLoading(false)
        }
    };


    const uploadImageToS3 = async (image) => {
        setImageUploading(true)
        axios.post(
            `${config?.apiUrl}/upload-files/image/s3`,
            { files: image },
            {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            }
        )
            .then(res => {
                const imagePath = config.awsBaseUrl + '/' + res?.data?.uploadedFiles?.[0]?.file_path
                setValue('image', imagePath)
                setImageUploading(false)
            })
            .catch(error => {
                setImageUploading(false)
                console.error("Error: " + error.message)
            })
    };

    const handleImageChange = async (event) => {
        if (!event?.target?.files?.[0]) return
        await uploadImageToS3(event?.target?.files?.[0])
    };

    const deleteImage = () => {
        setValue('image', null)
    }

    return (
        <div className="content" style={{ backgroundColor: '#f9f9f9' }}>
            <div className="content">
                <div className="card col-md-12 m-auto p-30">
                    <div>
                        {/* <h1>pavan</h1> */}
                    </div>

                    <div className="row" >
                        <div className="col-6 p-5" style={{ border: '1px solid grey' }}>
                            <div className="d-flex">
                                <label className="form-control col-3 text-align-center">    Source  </label>
                                <input className="form-control " value={rssBreaking?.rss_feed_id?.source || '-'} readOnly={true} />
                            </div>

                            <div className="d-flex">
                                <label className="form-control col-3 text-align-center">    Genre  </label>
                                <input className="form-control " value={rssBreaking?.rss_feed_id?.genre || '-'} readOnly={true} />
                            </div>

                            <div className="d-flex">
                                <label className="form-control col-3 text-align-center">    Rss Name  </label>
                                <input className="form-control " value={rssBreaking?.rss_feed_id?.name || '-'} readOnly={true} />
                            </div>

                            <div className="d-flex">
                                <label className="form-control col-3 text-align-center">    Title  </label>
                                <input className="form-control " value={rssBreaking?.title} readOnly={true} />
                            </div>

                            <div className="d-flex">
                                <label className="form-control col-3 text-align-center">    Description  </label>

                                <div style={{ display: 'none' }} ref={containerRef} dangerouslySetInnerHTML={{ __html: rssBreaking?.description }} />
                                <div className="breaking-description" dangerouslySetInnerHTML={{ __html: output1 }} />
                            </div>

                            <div className="d-flex mt-2">
                                <label className="form-control col-3 text-align-center">    Image Url  </label>
                                <input className="form-control " value={rssBreaking?.image_url || '-'} readOnly={true} />
                            </div>

                            <div className="d-flex">
                                <label className="form-control col-3 text-align-center">    Type  </label>
                                <input className="form-control " value={rssBreaking?.rss_feed_id?.type} readOnly={true} />
                            </div>

                            <div className="d-flex">
                                <label className="form-control col-3 text-align-center">    State  </label>
                                <input className="form-control " value={rssBreaking?.rss_feed_id?.state || '-'} readOnly={true} />
                            </div>

                            <div className="d-flex">
                                <label className="form-control col-3 text-align-center">    City  </label>
                                <input className="form-control " value={rssBreaking?.rss_feed_id?.city || '-'} readOnly={true} />
                            </div>

                            <div className="d-flex">
                                <label className="form-control col-3 text-align-center">    Continent  </label>
                                <input className="form-control " value={rssBreaking?.rss_feed_id?.continent || '-'} readOnly={true} />
                            </div>

                            <div className="d-flex">
                                <label className="form-control col-3 text-align-center">    Country  </label>
                                <input className="form-control " value={rssBreaking?.rss_feed_id?.country || '-'} readOnly={true} />
                            </div>

                            <div className="d-flex">
                                <label className="form-control col-3 text-align-center">    State  </label>
                                <input className="form-control " value={rssBreaking?.rss_feed_id?.state} readOnly={true} />
                            </div>

                            <div className="d-flex">
                                <label className="form-control col-3 text-align-center">    Frequency  </label>
                                <input className="form-control " value={rssBreaking?.rss_feed_id?.frequency} readOnly={true} />
                            </div>


                            <div className="d-flex">
                                <label className="form-control col-3 text-align-center">    Story Url  </label>
                                <input className="form-control " value={rssBreaking?.story_url} readOnly={true} />
                            </div>

                            <div className="d-flex">
                                <label className="form-control col-3 text-align-center">    Rss Feed Url  </label>
                                <input className="form-control " value={rssBreaking?.rss_feed_id?.url} readOnly={true} />
                            </div>
                        </div>

                        <div className="col-6 p-5" style={{ border: '1px solid grey' }}>
                            {
                                rssBreakingStoryLoading ?
                                    <div className="text-align-center mt-30-p">
                                        <div className="spinner-border" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </div>
                                    </div>
                                    :
                                    <form onSubmit={handleSubmit(onSubmit)}>
                                        <div>
                                            {errors.title && <small className="text-danger">{errors?.title?.message}</small>}
                                            <div className="d-flex">
                                                <label className="form-control col-3 text-align-center">
                                                    Title <span className="text-danger">*</span>
                                                    <span className="ml-10">{`(${watch('title')?.length || 0}/${titleCount})`}</span>
                                                </label>

                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    maxLength={titleCount}
                                                    {...register('title', { required: 'Title is required', maxLength: titleCount, })}
                                                />
                                            </div>
                                        </div>

                                        <div>
                                            {errors.sub_title && <small className="text-danger">{errors?.sub_title?.message}</small>}
                                            <div className="d-flex">
                                                <label className="form-control col-3 text-align-center">
                                                    Sub Title <span className="text-danger">*</span>
                                                    <span className="ml-10">{`(${watch('sub_title')?.length || 0}/${subTitleCount})`}</span>
                                                </label>

                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    maxLength={subTitleCount}
                                                    {...register('sub_title', { required: 'Sub Title is required', maxLength: subTitleCount })}
                                                />
                                            </div>
                                        </div>

                                        <div>
                                            {errors.url && <small className="text-danger">{errors?.url?.message}</small>}
                                            <div className="d-flex">
                                                <label className="form-control col-3 text-align-center">
                                                    Url
                                                </label>
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    {...register('url')}
                                                />
                                            </div>
                                        </div>

                                        <div className="mb-15 mt-1 react-quill">
                                            {/* <small>card Length: {card?.length + '/' + cardCount}</small> */}
                                            {/* <ReactQuill
                                                className="quill-editor "
                                                theme="snow"
                                                value={watch('card')}
                                                onChange={(e) => {
                                                    setValue('card', e)
                                                }}
                                                modules={modules}
                                                formats={formats}
                                                placeholder="Enter Card Value"
                                            /> */}


                                            {/* {errors.card && <small className="text-danger">{errors?.card?.message}</small>} */}
                                            <div className="d-flex">
                                                <label className="form-control col-3 text-align-center">
                                                    Card
                                                </label>
                                                <div className="w-100-p">
                                                    <textarea
                                                        className="form-control"
                                                        type="text"
                                                        {...register('card', { maxLength: cardCount })}
                                                        value={watch('card')}
                                                        rows={Math.ceil((watch('card')?.length || 0) / (70))}
                                                        maxLength={cardCount}
                                                    />
                                                    <p className="fsize-15">{`characters: ${watch('card')?.length}/${cardCount}`}</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div>
                                            <div className="d-flex">
                                                <label className="form-control col-3 text-align-center">
                                                    Facebook Hashtags
                                                </label>
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    placeholder="eg: #virat_kohli"
                                                    {...register('facebook_hashtag')}
                                                />
                                            </div>
                                        </div>

                                        <div>
                                            <div className="d-flex">
                                                <label className="form-control col-3 text-align-center">
                                                    Instagram Hashtags
                                                </label>
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    placeholder="eg: @virat_kohli"
                                                    {...register('instagram_hashtag')}
                                                />
                                            </div>
                                        </div>


                                        <div>
                                            <div className="d-flex">
                                                <label className="form-control col-3 text-align-center">
                                                    Twitter Hashtags
                                                </label>
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    placeholder="eg: #virat_kohli"
                                                    {...register('twitter_hashtag')}
                                                />
                                            </div>
                                        </div>

                                        <div>
                                            <p> <span className="fw-bold">ImageUrl:</span> <a href={watch('image')} target="_blank">{watch('image')}</a></p>
                                            {
                                                watch('image') &&
                                                <button className="btn btn-primary" onClick={(e) => { e.preventDefault(); deleteImage() }}> Delete Image</button>
                                            }
                                            {
                                                watch('image') &&
                                                <div className="text-align-center">
                                                    <img className="mb-15" src={watch('image')} alt='img' width={300} height={300} />
                                                </div>
                                            }

                                            {
                                                imageUploading &&
                                                <div>
                                                    <p>Image Uploading...</p>
                                                </div>
                                            }
                                            <div className="d-flex">
                                                <label className="form-control col-3 text-align-center">
                                                    Image
                                                </label>
                                                <input className='form-control ml-5' type="file" accept="image/*" onChange={handleImageChange} />
                                            </div>
                                        </div>

                                        <div className="text-align-center mt-5">
                                            <button disabled={isLoading} className="btn btn-primary" style={{ width: '30%' }}>
                                                {isLoading ? <div className="spinner-border spinner-border-sm tc-submit-loading" role="status"> </div> : 'Submit'}
                                            </button>
                                        </div>
                                    </form>
                            }

                        </div>
                    </div >


                </div>
            </div>
        </div>
    );
};

export default ListStory;
