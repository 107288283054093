import React, { useEffect, useState } from 'react'
import { CreateDataApi, GetListApi, UpdateDataApi } from '../../common/api/api'
import { Controller, useForm } from 'react-hook-form'
import Select from 'react-select'
import makeAnimated from "react-select/animated";
import config from '../../../config';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';

const animatedComponents = makeAnimated();
const user = JSON.parse(localStorage.getItem('user'))

const Create = () => {
  const { register, handleSubmit, control, formState: { errors }, watch, getValues, setValue } = useForm();

  const params = useParams()
  const id = params?.id

  const [states, setStates] = useState([])
  const [continents, setContinents] = useState([])
  const [stateWiseCities, setStateWiseCities] = useState(null)
  const [continentWiseCountries, setContinentWiseCountries] = useState(null)
  const [rssBreakingNews, setRssBreakingNews] = useState([])
  const [totalPercentage, setTotalPercentage] = useState(0)

  const demographyOptions = [
    { value: 'All', label: 'All' },
    { value: 'national', label: 'National' },
    { value: 'international', label: 'International' }
  ]

  const [genres, setGenres] = useState([])

  const navigate = useNavigate()

  const fetchRssBreakingNews = async (id) => {
    const data = await GetListApi(`rss-breaking-news/${id}`)
    setValue('name', data?.name)
    setValue('total', data?.total)
    setRssBreakingNews(data?.sections)
    setTotalPercentage(100)

    data?.sections?.map((item, index) => {
      setValue(`genre-${index}`, item?.genre)
      setValue(`percentage-${index}`, item?.percentage)
      setValue(`demography-${index}`, item?.path?.[0])

      if (item?.path?.[0] === 'national') {
        setValue(`state-${index}`, item?.path?.[1])
        setValue(`city-${index}`, item?.path?.[2])
      }

      if (item?.path?.[0] === 'international') {
        setValue(`continent-${index}`, item?.path?.[1])
        setValue(`country-${index}`, item?.path?.[2])
      }
    })
  }

  useEffect(() => {
    const getData = async () => {
      await fetchRssBreakingNews(id)
    }

    if (id && id !== 'new') getData()
  }, [id])

  const fetchGenres = async () => {
    const genresRes = await GetListApi(`common-masters`, { filter: { status: 'active', type: 'genre' }, limit: 1000 })
    const options = [{ label: 'All', value: "All" }, ...genresRes?.data?.map(item => ({ label: item?.name, value: item?.name }))]
    setGenres(options)
  }

  const fetchCountriesData = async (data) => {
    data = data?.find(item => item?.name === "stateCityDatastateCityData")?.data
    const states = Object.keys(data)?.map(item => ({ label: item, value: item })).sort((a, b) => a.label.localeCompare(b.label));

    const stateWiseCities = { All: [{ label: 'All', value: 'All' }] }

    Object.keys(data)?.map((key) => {
      const cities = data[key]?.map(item => ({ value: item, label: item })).sort((a, b) => a.label.localeCompare(b.label))
      stateWiseCities[key] = cities
    })

    setStates([{ label: 'All', value: 'All' }, ...states]);
    setStateWiseCities(stateWiseCities)
  }

  const fetchContinetsData = async (data) => {
    data = data?.find(item => item?.name === "continentCountryData")?.data

    const continentsData = Object.keys(data)?.map(item => ({ label: item, value: item })).sort((a, b) => a.label.localeCompare(b.label));

    const ContinentCountryData = { All: [{ label: 'All', value: 'All' }] }

    Object.keys(data)?.map((key) => {
      const countries = data[key]?.map(item => ({ value: item, label: item })).sort((a, b) => a.label.localeCompare(b.label))
      ContinentCountryData[key] = countries
    })

    setContinents([{ label: 'All', value: 'All' }, ...continentsData]);
    setContinentWiseCountries(ContinentCountryData)
  }

  const getCitiesOptions = (state) => {
    if (!state || !stateWiseCities) return null
    return [{ label: 'All', value: 'All' }, ...stateWiseCities[state]]
  }

  const getCountriesOptions = (country) => {
    if (!country || !continentWiseCountries) return null
    return [{ label: 'All', value: 'All' }, ...continentWiseCountries?.[country]]
  }

  useEffect(() => {
    const getData = async () => {
      await fetchGenres()

      const data = (await (await fetch(`${config.apiUrl}/utilities`)).json())?.data
      fetchCountriesData(data)
      fetchContinetsData(data)
    }
    getData()
  }, [])

  const onSubmit = async () => {
    if (rssBreakingNews?.length === 0) {
      toast.error('add atleast one demography')
      return
    }

    if (totalPercentage !== 100) {
      toast.error('Total Percentage should be equal to 100')
      return
    }

    const values = getValues()

    const payload = {
      userId: user?.id,
      name: values?.name,
      total: Number(values?.total),
      sections: rssBreakingNews
    }

    try {
      const response = (id && id !== 'new') ? await UpdateDataApi(`rss-breaking-news/${id}`, payload) : await CreateDataApi('rss-breaking-news', payload)
      if (response) {
        toast.success('created')
        navigate('/rss-breaking-news/list')
      }
    }
    catch (error) {
      toast.error(error?.message)
    }
  }


  const addMoreNews = () => {
    const index = rssBreakingNews?.length

    setRssBreakingNews(prev => [...prev, {
      genre: "All",
      path: [
        "All"
      ],
      percentage: 0
    }])

    setValue(`demography-${index}`, 'All')
    setValue(`genre-${index}`, 'All')
    setValue(`percentage-${index}`, 0)
  }

  const getOptions = (name) => {
    if (!name) return null
    return { label: name, value: name }
  }

  const handleDelete = (index) => {
    if (index < 0 || index >= rssBreakingNews.length) return
    setTotalPercentage(prev => prev - Number(rssBreakingNews[index].percentage))

    const updatedRssBreakingNews = rssBreakingNews.filter((_, i) => i !== index);

    const values = { ...getValues() };

    for (let i = index + 1; i < rssBreakingNews.length; i++) {
      setValue(`demography-${i - 1}`, values[`demography-${i}`]);
      setValue(`continent-${i - 1}`, values[`continent-${i}`]);
      setValue(`country-${i - 1}`, values[`country-${i}`]);
      setValue(`state-${i - 1}`, values[`state-${i}`]);
      setValue(`city-${i - 1}`, values[`city-${i}`]);
      setValue(`percentage-${i - 1}`, values[`percentage-${i}`]);
    }

    setRssBreakingNews(updatedRssBreakingNews);
  }


  return (
    <div style={{ backgroundColor: '#f9f9f9' }}>
      <div className="content" style={{ backgroundColor: '#f9f9f9' }}>
        <div className="content">
          <div className="card col-md-12 m-auto p-30">

            <div className='row'>
              <div className='col-6'>
                <div className='d-flex'>
                  <lable className='form-control col-2 align-center'>Name</lable>
                  <input className='form-control' type='text' placeholder='Name'
                    {...register(`name`, { required: "This is required" })}
                  />
                </div>
                {errors?.name && <span className="text-danger">{errors?.name?.message}</span>}
              </div>

              <div className='col-3'>
                <div className='d-flex'>
                  <lable className='form-control col- align-center'>Total</lable>
                  <input className='form-control' type='Number' placeholder='Total'
                    {...register(`total`, { required: "This is required", min: { value: 0, message: "Minimum value is 0" } })}
                  />
                </div>
                {errors?.total && <span className="text-danger">{errors?.total?.message}</span>}
              </div>

              <div className='d-flex col-3'>
                <lable className='form-control col-6 align-center'>Total Percentage</lable>
                <input disabled={true} className='form-control' value={totalPercentage} type='text' placeholder='Percentage' />
              </div>
            </div>

            <div className='rss-breaking-news-container mt-5'>
              <form onSubmit={handleSubmit(onSubmit)}>
                {
                  rssBreakingNews?.map((news, index) => (
                    <div key={index} className='mt-4 d-flex col-12'>
                      {/* genre */}
                      <div className="col col-2">
                        <label>Genre</label>
                        <Controller
                          name={`genre-${index}`}
                          control={control}
                          rules={{ required: "This is required" }}
                          render={({ field }) => (
                            <Select
                              {...field}
                              value={getOptions(rssBreakingNews?.[index]?.genre)}
                              components={animatedComponents}
                              options={genres}
                              onChange={(e) => {
                                setValue(`genre-${index}`, e.value)
                                const tempRssBreakingNews = [...rssBreakingNews]
                                tempRssBreakingNews[index].genre = e.value
                                setRssBreakingNews(tempRssBreakingNews)
                              }}
                            />
                          )}
                        />
                        {errors?.[`genre-${index}`] && <span className="text-danger">{errors?.[`genre-${index}`]?.message}</span>}
                      </div>

                      {/* demography */}
                      <div className="col col-2">
                        <label>demography</label>
                        <Controller
                          name={`demography-${index}`}
                          control={control}
                          rules={{ required: "This is required" }}
                          render={({ field }) => (
                            <Select
                              {...field}
                              value={getOptions(rssBreakingNews?.[index]?.path?.[0])}
                              components={animatedComponents}
                              options={demographyOptions}
                              onChange={(e) => {
                                setValue(`demography-${index}`, e.value)
                                if (e.value === 'national') setValue(`state-${index}`, 'All')
                                if (e.value === 'international') setValue(`continent-${index}`, 'All')
                                const tempRssBreakingNews = [...rssBreakingNews]
                                tempRssBreakingNews[index].path[0] = e.value
                                tempRssBreakingNews[index].path[1] = 'All'
                                tempRssBreakingNews[index].path[2] = ''
                                setRssBreakingNews(tempRssBreakingNews)
                              }}
                            />
                          )}
                        />
                        {errors?.[`demography-${index}`] && <span className="text-danger">{errors?.[`demography-${index}`]?.message}</span>}
                      </div>


                      {/* national */}
                      {
                        news?.path?.[0] === 'national' &&
                        <div className='col col-2'>
                          <label>State</label>
                          <Controller
                            name={`state-${index}`}
                            control={control}
                            rules={{ required: "This is required" }}
                            render={({ field }) => (
                              <Select
                                {...field}
                                components={animatedComponents}
                                value={getOptions(rssBreakingNews?.[index]?.path?.[1])}
                                options={states}
                                onChange={(e) => {
                                  setValue(`state-${index}`, e.value)
                                  setValue(`city-${index}`, 'All')
                                  const tempRssBreakingNews = [...rssBreakingNews]
                                  tempRssBreakingNews[index].path[1] = e.value
                                  tempRssBreakingNews[index].path[2] = 'All'
                                  setRssBreakingNews(tempRssBreakingNews)
                                }}
                              />
                            )}
                          />
                          {errors?.[`state-${index}`] && <span className="text-danger">{errors?.[`state-${index}`]?.message}</span>}
                        </div>
                      }

                      {
                        news?.path?.[0] === 'national' && news?.path?.[1] && news?.path?.[1] !== 'All' &&
                        <div className="col col-2">
                          <label>City</label>
                          <Controller
                            name={`city-${index}`}
                            control={control}
                            rules={{ required: "This is required" }}
                            render={({ field }) => (
                              <Select
                                {...field}
                                components={animatedComponents}
                                value={getOptions(rssBreakingNews?.[index]?.path?.[2])}
                                options={getCitiesOptions(rssBreakingNews[index].path[1])}
                                onChange={(e) => {
                                  setValue(`city-${index}`, e.value)
                                  const tempRssBreakingNews = [...rssBreakingNews]
                                  tempRssBreakingNews[index].path[2] = e.value
                                  setRssBreakingNews(tempRssBreakingNews)
                                }}
                              />
                            )}
                          />
                          {errors?.[`city-${index}`] && <span className="text-danger">{errors?.[`city-${index}`]?.message}</span>}
                        </div>
                      }


                      {/* international  */}

                      {
                        news?.path?.[0] === 'international' &&
                        <div className="col col-2">
                          <label>Continent</label>
                          <Controller
                            name={`continent-${index}`}
                            control={control}
                            rules={{ required: "This is required" }}
                            render={({ field }) => (
                              <Select
                                {...field}
                                components={animatedComponents}
                                value={getOptions(rssBreakingNews?.[index]?.path?.[1])}
                                options={continents}
                                onChange={(e) => {
                                  setValue(`country-${index}`, 'All')
                                  const tempRssBreakingNews = [...rssBreakingNews]
                                  tempRssBreakingNews[index].path[1] = e.value
                                  tempRssBreakingNews[index].path[2] = 'All'
                                  setRssBreakingNews(tempRssBreakingNews)
                                }}
                              />
                            )}
                          />
                          {errors?.[`continent-${index}`] && <span className="text-danger">{errors?.[`continent-${index}`]?.message}</span>}
                        </div>
                      }

                      {
                        news?.path?.[0] === 'international' && news?.path?.[1] && news?.path?.[1] !== 'All' &&
                        <div className="col col-2">
                          <label>Country</label>
                          <Controller
                            name={`country-${index}`}
                            control={control}
                            rules={{ required: "This is required" }}
                            render={({ field }) => (
                              <Select
                                {...field}
                                components={animatedComponents}
                                value={getOptions(rssBreakingNews?.[index]?.path?.[2])}
                                options={getCountriesOptions(rssBreakingNews[index].path[1])}
                                onChange={(e) => {
                                  const tempRssBreakingNews = [...rssBreakingNews]
                                  tempRssBreakingNews[index].path[2] = e.value
                                  setRssBreakingNews(tempRssBreakingNews)
                                }}
                              />
                            )}
                          />
                          {errors?.[`country-${index}`] && <span className="text-danger">{errors?.[`country-${index}`]?.message}</span>}
                        </div>
                      }

                      {/* percentage  */}
                      <div className='col col-2'>
                        <label>Percentage</label>
                        <input className='form-control'
                          type="Number"
                          placeholder='Enter Percentage'
                          value={news.percentage}
                          {...register(`percentage-${index}`, { required: "This is required", min: { value: 0, message: "Minimum value is 0" }, max: { value: 100, message: "Maximum value is 100" } })}
                          onChange={(e) => {
                            let prevVal = news.percentage
                            setTotalPercentage(prev => {
                              const newVal = Number(prev) - prevVal + Number(e.target.value);
                              return newVal
                            });
                            setValue(`percentage-${index}`, e?.target?.value)
                            const tempRssBreakingNews = [...rssBreakingNews]
                            tempRssBreakingNews[index].percentage = e?.target?.value
                            setRssBreakingNews(tempRssBreakingNews)
                          }}
                        />
                        {errors?.[`percentage-${index}`] && <span className="text-danger">{errors?.[`percentage-${index}`]?.message}</span>}
                      </div>


                      <div className='col col-2'>
                        <label>Remove</label>
                        <div className='cursor-pointer'>
                          <i onClick={() => { handleDelete(index) }} className='fa fa-trash fs-28' />
                        </div>
                      </div>
                    </div>
                  ))
                }

                <div className='d-flex justify-center'>
                  <button className='btn btn-primary col-1 mt-4' onClick={(e) => { e.preventDefault(); addMoreNews() }} >Add More</button>
                </div>

                <div className='d-flex justify-center'>
                  <button className='btn btn-primary col-3 mt-4'> save</button>
                </div>

                {/* <div className='d-flex justify-center'>
                  <button onClick={(e) => { e.preventDefault(); onSubmit() }} className='btn btn-primary col-3 mt-4'> no validate save</button>
                </div> */}
              </form>
            </div>
          </div>
        </div>
      </div>
    </div >
  )
};


export default Create