import React, { useEffect, useState } from 'react'
import config from "../../../config";
import { toast } from "react-toastify";

const IntegrationSettings = () => {
    const [menuOpen, setMenuOpen] = useState('');
    const [editing, setEditing] = useState({})
    const [state, setState] = useState({})
    const [isLoading, setIsLoading] = useState(false)

    const handleMenuToggle = (sectionName) => {
        if (!menuOpen) setMenuOpen(sectionName);
        else setMenuOpen('')
    };

    const getSettings = async () => {
        const url = `${config.apiUrl}/integration-settings`
        const response = await fetch(url)
        const data = await response?.json()
        setState(data)
    }

    useEffect(() => {
        const fetchData = async () => {
            getSettings()
        }
        fetchData()
    }, [])


    const saveSettings = async () => {
        try {
            setIsLoading(true);

            delete state?._id
            delete state?.__v
            delete state?.createdAt
            delete state?.updatedAt

            const url = `${config.apiUrl}/integration-settings`
            const res = await fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(state)
            })

            const data = await res.json()

            if (data?._id) {
                setTimeout(() => {
                    setEditing({})
                    setIsLoading(false)
                    toast.success('integration settings updated..!')
                }, 1000)
            }
        }
        catch (error) {
            console.error("Error: " + error?.message)
        }
    }


    if (isLoading) {
        return <>
            <div className="spinner story-form-spinner"></div>
        </>
    }

    return (
        <>
            <div className="col-md-8 m-auto">
                <h1 className='integratio-heading'>Integrations</h1>
            </div>

            {/* Facebook */}
            <div className="integration-card col-md-8 m-auto p-30">
                <div className='setting-container'>
                    <div className='setting-container-left pl-50'>
                        <h3 className='fw-bold'>Facebook</h3>
                    </div>

                    <div className='setting-container-right'>
                        <div className='edit-menu'>
                            {menuOpen === 'facebook' ? (
                                <div className='integration-edit-submenu'>
                                    <div className='integration-edit-submenu-item' onClick={() => { setEditing({ ...editing, facebook: false }); handleMenuToggle('') }}>Deactive</div>
                                    <div className='integration-edit-submenu-item' onClick={() => { setEditing({ ...editing, facebook: true }); handleMenuToggle('') }}>Edit</div>
                                </div>
                            ) : null}
                            <svg onClick={(e) => { handleMenuToggle('facebook') }} id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="#5f6978"><defs></defs><path fill="none" d="M0 0h24v24H0z" id="Bounding_Boxes" data-name="Bounding Boxes"></path><path d="M12 8a2 2 0 1 0-2-2 2 2 0 0 0 2 2zm0 2a2 2 0 1 0 2 2 2 2 0 0 0-2-2zm0 6a2 2 0 1 0 2 2 2 2 0 0 0-2-2z" id="Outline"></path></svg>
                        </div>

                        <div class="form-group">
                            <label class="fw-bold">API Key</label>
                            <input type="text" className="form-control" placeholder="API Key" value={state?.facebook?.apiKey} onChange={(e) => setState({ ...state, facebook: { ...state.facebook, apiKey: e.target.value } })} readOnly={!editing?.facebook} />
                        </div>

                        <div class="form-group">
                            <label class="fw-bold">API Secret</label>
                            <input type="text" className="form-control" placeholder="API Secret" value={state?.facebook?.apiSecret} onChange={(e) => setState({ ...state, facebook: { ...state.facebook, apiSecret: e.target.value } })} readOnly={!editing?.facebook} />
                        </div>
                    </div>
                </div>
            </div>

            {/* facebook sharing */}
            <div className="integration-card col-md-8 m-auto p-30">
                <div className='setting-container'>
                    <div className='setting-container-left pl-50'>
                        <h3 className='fw-bold'>Facebook Sharing</h3>
                    </div>

                    <div className='setting-container-right'>
                        <div className='edit-menu'>
                            {menuOpen === 'facebookAutoPost' ? (
                                <div className='integration-edit-submenu'>
                                    <div className='integration-edit-submenu-item' onClick={() => { setEditing({ ...editing, 'facebookAutoPost': false }); handleMenuToggle('') }}>Deactive</div>
                                    <div className='integration-edit-submenu-item' onClick={() => { setEditing({ ...editing, 'facebookAutoPost': true }); handleMenuToggle('') }}>Edit</div>
                                </div>
                            ) : null}
                            <svg onClick={(e) => { handleMenuToggle('facebookAutoPost') }} id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="#5f6978"><defs></defs><path fill="none" d="M0 0h24v24H0z" id="Bounding_Boxes" data-name="Bounding Boxes"></path><path d="M12 8a2 2 0 1 0-2-2 2 2 0 0 0 2 2zm0 2a2 2 0 1 0 2 2 2 2 0 0 0-2-2zm0 6a2 2 0 1 0 2 2 2 2 0 0 0-2-2z" id="Outline"></path></svg>
                        </div>

                        <div class="form-group">
                            <label class="fw-bold">Facebook Page Access Token</label>
                            <input type="text" className="form-control" placeholder="Page Access Token" value={state?.['facebookAutoPost']?.pageAccessToken} onChange={(e) => setState({ ...state, 'facebookAutoPost': { ...state['facebookAutoPost'], pageAccessToken: e.target.value } })} readOnly={!editing?.['facebookAutoPost']} />
                        </div>


                        <div class="form-group">
                            <label class="fw-bold">App Id</label>
                            <input type="text" className="form-control" placeholder="App Id" value={state?.['facebookAutoPost']?.appId} onChange={(e) => setState({ ...state, 'facebookAutoPost': { ...state['facebookAutoPost'], appId: e.target.value } })} readOnly={!editing?.['facebookAutoPost']} />
                        </div>


                        <div class="form-group">
                            <label class="fw-bold">App Secret</label>
                            <input type="text" className="form-control" placeholder="App Secret" value={state?.['facebookAutoPost']?.appSecret} onChange={(e) => setState({ ...state, 'facebookAutoPost': { ...state['facebookAutoPost'], appSecret: e.target.value } })} readOnly={!editing?.['facebookAutoPost']} />
                        </div>

                        {/* <div class="form-group">
                            <label class="fw-bold">Facebook Page Id</label>
                            <input disabled={true} type="text" className="form-control" placeholder="Facebook Page Id" value={state?.['facebookAutoPost']?.pageId} onChange={(e) => setState({ ...state, 'facebookAutoPost': { ...state['facebookAutoPost'], pageId: e.target.value } })}/>
                        </div>

                        <div class="form-group">
                            <label class="fw-bold">Access Token</label>
                            <input disabled={true} type="text" className="form-control" placeholder="Access Token" value={state?.['facebookAutoPost']?.accessToken} onChange={(e) => setState({ ...state, 'facebookAutoPost': { ...state['facebookAutoPost'], accessToken: e.target.value } })}/>
                        </div> */}
                    </div>
                </div>
            </div>

             {/* Instagram sharing */}
             <div className="integration-card col-md-8 m-auto p-30">
                <div className='setting-container'>
                    <div className='setting-container-left pl-50'>
                        <h3 className='fw-bold'>Instagram Sharing</h3>
                    </div>

                    <div className='setting-container-right'>
                        <div className='edit-menu'>
                            {menuOpen === 'instagramSharing' ? (
                                <div className='integration-edit-submenu'>
                                    <div className='integration-edit-submenu-item' onClick={() => { setEditing({ ...editing, 'instagramSharing': false }); handleMenuToggle('') }}>Deactive</div>
                                    <div className='integration-edit-submenu-item' onClick={() => { setEditing({ ...editing, 'instagramSharing': true }); handleMenuToggle('') }}>Edit</div>
                                </div>
                            ) : null}
                            <svg onClick={(e) => { handleMenuToggle('instagramSharing') }} id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="#5f6978"><defs></defs><path fill="none" d="M0 0h24v24H0z" id="Bounding_Boxes" data-name="Bounding Boxes"></path><path d="M12 8a2 2 0 1 0-2-2 2 2 0 0 0 2 2zm0 2a2 2 0 1 0 2 2 2 2 0 0 0-2-2zm0 6a2 2 0 1 0 2 2 2 2 0 0 0-2-2z" id="Outline"></path></svg>
                        </div>

                        <div class="form-group">
                            <label class="fw-bold">Instagram Page Access Token</label>
                            <input type="text" className="form-control" placeholder="Page Access Token" value={state?.['instagramSharing']?.pageAccessToken} onChange={(e) => setState({ ...state, 'instagramSharing': { ...state['instagramSharing'], pageAccessToken: e.target.value } })} readOnly={!editing?.['instagramSharing']} />
                        </div>


                        <div class="form-group">
                            <label class="fw-bold">App Id</label>
                            <input type="text" className="form-control" placeholder="App Id" value={state?.['instagramSharing']?.appId} onChange={(e) => setState({ ...state, 'instagramSharing': { ...state['instagramSharing'], appId: e.target.value } })} readOnly={!editing?.['instagramSharing']} />
                        </div>


                        <div class="form-group">
                            <label class="fw-bold">App Secret</label>
                            <input type="text" className="form-control" placeholder="App Secret" value={state?.['instagramSharing']?.appSecret} onChange={(e) => setState({ ...state, 'instagramSharing': { ...state['instagramSharing'], appSecret: e.target.value } })} readOnly={!editing?.['instagramSharing']} />
                        </div>

                        {/* <div class="form-group">
                            <label class="fw-bold">Facebook Page Id</label>
                            <input disabled={true} type="text" className="form-control" placeholder="Facebook Page Id" value={state?.['facebookAutoPost']?.pageId} onChange={(e) => setState({ ...state, 'facebookAutoPost': { ...state['facebookAutoPost'], pageId: e.target.value } })}/>
                        </div>

                        <div class="form-group">
                            <label class="fw-bold">Access Token</label>
                            <input disabled={true} type="text" className="form-control" placeholder="Access Token" value={state?.['facebookAutoPost']?.accessToken} onChange={(e) => setState({ ...state, 'facebookAutoPost': { ...state['facebookAutoPost'], accessToken: e.target.value } })}/>
                        </div> */}
                    </div>
                </div>
            </div>

            {/* Twitter sharing */}
            <div className="integration-card col-md-8 m-auto p-30">
                <div className='setting-container'>
                    <div className='setting-container-left pl-50'>
                        <h3 className='fw-bold'>Twitter Sharing</h3>
                    </div>

                    <div className='setting-container-right'>
                        <div className='edit-menu'>
                            {menuOpen === 'twitterSharing' ? (
                                <div className='integration-edit-submenu'>
                                    <div className='integration-edit-submenu-item' onClick={() => { setEditing({ ...editing, 'twitterSharing': false }); handleMenuToggle('') }}>Deactive</div>
                                    <div className='integration-edit-submenu-item' onClick={() => { setEditing({ ...editing, 'twitterSharing': true }); handleMenuToggle('') }}>Edit</div>
                                </div>
                            ) : null}
                            <svg onClick={(e) => { handleMenuToggle('twitterSharing') }} id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="#5f6978"><defs></defs><path fill="none" d="M0 0h24v24H0z" id="Bounding_Boxes" data-name="Bounding Boxes"></path><path d="M12 8a2 2 0 1 0-2-2 2 2 0 0 0 2 2zm0 2a2 2 0 1 0 2 2 2 2 0 0 0-2-2zm0 6a2 2 0 1 0 2 2 2 2 0 0 0-2-2z" id="Outline"></path></svg>
                        </div>

                        <div class="form-group">
                            <label class="fw-bold">Api Key</label>
                            <input type="text" className="form-control" placeholder="Api Key" value={state?.['twitterSharing']?.apiKey} onChange={(e) => setState({ ...state, 'twitterSharing': { ...state['twitterSharing'], apiKey: e.target.value } })} readOnly={!editing?.['twitterSharing']} />
                        </div>


                        <div class="form-group">
                            <label class="fw-bold">Api Secret</label>
                            <input type="text" className="form-control" placeholder="Api Secret" value={state?.['twitterSharing']?.apiSecret} onChange={(e) => setState({ ...state, 'twitterSharing': { ...state['twitterSharing'], apiSecret: e.target.value } })} readOnly={!editing?.['twitterSharing']} />
                        </div>


                        <div class="form-group">
                            <label class="fw-bold">Access Token</label>
                            <input type="text" className="form-control" placeholder="Access Token" value={state?.['twitterSharing']?.accessToken} onChange={(e) => setState({ ...state, 'twitterSharing': { ...state['twitterSharing'], accessToken: e.target.value } })} readOnly={!editing?.['twitterSharing']} />
                        </div>

                        <div class="form-group">
                            <label class="fw-bold">Access Token Secret</label>
                            <input type="text" className="form-control" placeholder="Access Token Secret" value={state?.['twitterSharing']?.accessTokenSecret} onChange={(e) => setState({ ...state, 'twitterSharing': { ...state['twitterSharing'], accessTokenSecret: e.target.value } })} readOnly={!editing?.['twitterSharing']} />
                        </div>

                        <div class="form-group">
                            <label class="fw-bold">Twitter User Name</label>
                            <input type="text" className="form-control" placeholder="User Name" value={state?.['twitterSharing']?.userName} onChange={(e) => setState({ ...state, 'twitterSharing': { ...state['twitterSharing'], userName: e.target.value } })} readOnly={!editing?.['twitterSharing']} />
                        </div>
                    </div>
                </div>
            </div>

            {/* Google analytics Id   */}
            <div className="integration-card col-md-8 m-auto p-30">
                <div className='setting-container'>
                    <div className='setting-container-left pl-50'>
                        <h3 className='fw-bold'>Google Analytics Id</h3>
                    </div>

                    <div className='setting-container-right'>
                        <div className='edit-menu'>
                            {menuOpen === 'googleAnalyticsId' ? (
                                <div className='integration-edit-submenu'>
                                    <div className='integration-edit-submenu-item' onClick={() => { setEditing({ ...editing, googleAnalyticsId: false }); handleMenuToggle('') }}>Deactive</div>
                                    <div className='integration-edit-submenu-item' onClick={() => { setEditing({ ...editing, googleAnalyticsId: true }); handleMenuToggle('') }}>Edit</div>
                                </div>
                            ) : null}
                            <svg onClick={(e) => { handleMenuToggle('googleAnalyticsId') }} id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="#5f6978"><defs></defs><path fill="none" d="M0 0h24v24H0z" id="Bounding_Boxes" data-name="Bounding Boxes"></path><path d="M12 8a2 2 0 1 0-2-2 2 2 0 0 0 2 2zm0 2a2 2 0 1 0 2 2 2 2 0 0 0-2-2zm0 6a2 2 0 1 0 2 2 2 2 0 0 0-2-2z" id="Outline"></path></svg>
                        </div>

                        <div class="form-group">
                            <label class="fw-bold">Google Analytics Id</label>
                            <input type="text" className="form-control" placeholder="Analytics Id" value={state?.googleAnalyticsId?.id} onChange={(e) => setState({ ...state, googleAnalyticsId: { ...state.googleAnalyticsId, id: e.target.value } })} readOnly={!editing?.googleAnalyticsId} />
                        </div>
                    </div>
                </div>
            </div>


            {/* Google analytics 3   */}
            <div className="integration-card col-md-8 m-auto p-30">
                <div className='setting-container'>
                    <div className='setting-container-left pl-50'>
                        <h3 className='fw-bold'>Google Analytics 3</h3>
                    </div>

                    <div className='setting-container-right'>
                        <div className='edit-menu'>
                            {menuOpen === 'googleAnalytics3' ? (
                                <div className='integration-edit-submenu'>
                                    <div className='integration-edit-submenu-item' onClick={() => { setEditing({ ...editing, googleAnalytics3: false }); handleMenuToggle('') }}>Deactive</div>
                                    <div className='integration-edit-submenu-item' onClick={() => { setEditing({ ...editing, googleAnalytics3: true }); handleMenuToggle('') }}>Edit</div>
                                </div>
                            ) : null}
                            <svg onClick={(e) => { handleMenuToggle('googleAnalytics3') }} id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="#5f6978"><defs></defs><path fill="none" d="M0 0h24v24H0z" id="Bounding_Boxes" data-name="Bounding Boxes"></path><path d="M12 8a2 2 0 1 0-2-2 2 2 0 0 0 2 2zm0 2a2 2 0 1 0 2 2 2 2 0 0 0-2-2zm0 6a2 2 0 1 0 2 2 2 2 0 0 0-2-2z" id="Outline"></path></svg>
                        </div>

                        <div class="form-group">
                            <label class="fw-bold">Service Account Email</label>
                            <input type="text" className="form-control" placeholder="Service Account Email" value={state?.googleAnalytics3?.email} onChange={(e) => setState({ ...state, googleAnalytics3: { ...state.googleAnalytics3, email: e.target.value } })} readOnly={!editing?.googleAnalytics3} />
                        </div>

                        <div class="form-group">
                            <label class="fw-bold">Private Key</label>
                            <textarea rows={6} style={{ maxHeight: '200px' }} className="form-control" placeholder="Private Key" value={state?.googleAnalytics3?.privateKey} onChange={(e) => setState({ ...state, googleAnalytics3: { ...state.googleAnalytics3, privateKey: e.target.value } })} readOnly={!editing?.googleAnalytics3} />
                        </div>

                        <div class="form-group">
                            <label class="fw-bold">View Id</label>
                            <input type="text" className="form-control" placeholder="View ID" value={state?.googleAnalytics3?.viewId} onChange={(e) => setState({ ...state, googleAnalytics3: { ...state.googleAnalytics3, viewId: e.target.value } })} readOnly={!editing?.googleAnalytics3} />
                        </div>
                    </div>
                </div>
            </div>



            {/* Google analytics 4  */}
            <div className="integration-card col-md-8 m-auto p-30">
                <div className='setting-container'>
                    <div className='setting-container-left pl-50'>
                        <h3 className='fw-bold'>Google Analytics 4</h3>
                    </div>

                    <div className='setting-container-right'>
                        <div className='edit-menu'>
                            {menuOpen === 'googleAnalytics4' ? (
                                <div className='integration-edit-submenu'>
                                    <div className='integration-edit-submenu-item' onClick={() => { setEditing({ ...editing, googleAnalytics4: false }); handleMenuToggle('') }}>Deactive</div>
                                    <div className='integration-edit-submenu-item' onClick={() => { setEditing({ ...editing, googleAnalytics4: true }); handleMenuToggle('') }}>Edit</div>
                                </div>
                            ) : null}
                            <svg onClick={(e) => { handleMenuToggle('googleAnalytics4') }} id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="#5f6978"><defs></defs><path fill="none" d="M0 0h24v24H0z" id="Bounding_Boxes" data-name="Bounding Boxes"></path><path d="M12 8a2 2 0 1 0-2-2 2 2 0 0 0 2 2zm0 2a2 2 0 1 0 2 2 2 2 0 0 0-2-2zm0 6a2 2 0 1 0 2 2 2 2 0 0 0-2-2z" id="Outline"></path></svg>
                        </div>

                        <div class="form-group">
                            <label class="fw-bold">Service Account Email</label>
                            <input type="text" className="form-control" placeholder="Service Account Email" value={state?.googleAnalytics4?.email} onChange={(e) => setState({ ...state, googleAnalytics4: { ...state.googleAnalytics4, email: e.target.value } })} readOnly={!editing?.googleAnalytics4} />
                        </div>

                        <div class="form-group">
                            <label class="fw-bold">Private Key</label>
                            <textarea rows={6} style={{ maxHeight: '200px' }} className="form-control" placeholder="Private Key" value={state?.googleAnalytics4?.privateKey} onChange={(e) => setState({ ...state, googleAnalytics4: { ...state.googleAnalytics4, privateKey: e.target.value } })} readOnly={!editing?.googleAnalytics4} />
                        </div>

                        <div class="form-group">
                            <label class="fw-bold">Property Id</label>
                            <input type="text" className="form-control" placeholder="Property ID" value={state?.googleAnalytics4?.propertyId} onChange={(e) => setState({ ...state, googleAnalytics4: { ...state.googleAnalytics4, propertyId: e.target.value } })} readOnly={!editing?.googleAnalytics4} />
                        </div>

                        <div class="form-group">
                            <label class="fw-bold">Timezone</label>
                            <input type="text" className="form-control" placeholder="Timezone" value={state?.googleAnalytics4?.timezone} onChange={(e) => setState({ ...state, googleAnalytics4: { ...state.googleAnalytics4, timezone: e.target.value } })} readOnly={!editing?.googleAnalytics4} />
                        </div>
                    </div>
                </div>
            </div>



            {/* LinkedIn */}
            <div className="integration-card col-md-8 m-auto p-30">
                <div className='setting-container'>
                    <div className='setting-container-left pl-50'>
                        <h3 className='fw-bold'>LinkedIn</h3>
                    </div>

                    <div className='setting-container-right'>
                        <div className='edit-menu'>
                            {menuOpen === 'linkedIn' ? (
                                <div className='integration-edit-submenu'>
                                    <div className='integration-edit-submenu-item' onClick={() => { setEditing({ ...editing, linkedIn: false }); handleMenuToggle('') }}>Deactive</div>
                                    <div className='integration-edit-submenu-item' onClick={() => { setEditing({ ...editing, linkedIn: true }); handleMenuToggle('') }}>Edit</div>
                                </div>
                            ) : null}
                            <svg onClick={(e) => { handleMenuToggle('linkedIn') }} id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="#5f6978"><defs></defs><path fill="none" d="M0 0h24v24H0z" id="Bounding_Boxes" data-name="Bounding Boxes"></path><path d="M12 8a2 2 0 1 0-2-2 2 2 0 0 0 2 2zm0 2a2 2 0 1 0 2 2 2 2 0 0 0-2-2zm0 6a2 2 0 1 0 2 2 2 2 0 0 0-2-2z" id="Outline"></path></svg>
                        </div>

                        <div class="form-group">
                            <label class="fw-bold">API Key</label>
                            <input type="text" className="form-control" placeholder="API Key" value={state?.linkedIn?.apiKey} onChange={(e) => setState({ ...state, linkedIn: { ...state.linkedIn, apiKey: e.target.value } })} readOnly={!editing?.linkedIn} />
                        </div>

                        <div class="form-group">
                            <label class="fw-bold">API Secret</label>
                            <input type="text" className="form-control" placeholder="API Secret" value={state?.linkedIn?.apiSecret} onChange={(e) => setState({ ...state, linkedIn: { ...state.linkedIn, apiSecret: e.target.value } })} readOnly={!editing?.linkedIn} />
                        </div>
                    </div>
                </div>
            </div>


            {/* OneSignal */}
            <div className="integration-card col-md-8 m-auto p-30">
                <div className='setting-container'>
                    <div className='setting-container-left pl-50'>
                        <h3 className='fw-bold'>OneSignal</h3>
                    </div>

                    <div className='setting-container-right'>
                        <div className='edit-menu'>
                            {menuOpen === 'oneSignal' ? (
                                <div className='integration-edit-submenu'>
                                    <div className='integration-edit-submenu-item' onClick={() => { setEditing({ ...editing, oneSignal: false }); handleMenuToggle('') }}>Deactive</div>
                                    <div className='integration-edit-submenu-item' onClick={() => { setEditing({ ...editing, oneSignal: true }); handleMenuToggle('') }}>Edit</div>
                                </div>
                            ) : null}
                            <svg onClick={(e) => { handleMenuToggle('oneSignal') }} id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="#5f6978"><defs></defs><path fill="none" d="M0 0h24v24H0z" id="Bounding_Boxes" data-name="Bounding Boxes"></path><path d="M12 8a2 2 0 1 0-2-2 2 2 0 0 0 2 2zm0 2a2 2 0 1 0 2 2 2 2 0 0 0-2-2zm0 6a2 2 0 1 0 2 2 2 2 0 0 0-2-2z" id="Outline"></path></svg>
                        </div>

                        <div class="form-group">
                            <label class="fw-bold">API Key</label>
                            <input type="text" className="form-control" placeholder="API Key" value={state?.oneSignal?.apiKey} onChange={(e) => setState({ ...state, oneSignal: { ...state.oneSignal, apiKey: e.target.value } })} readOnly={!editing?.oneSignal} />
                        </div>

                        <div class="form-group">
                            <label class="fw-bold">API Id</label>
                            <input type="text" className="form-control" placeholder="API ID" value={state?.oneSignal?.apiId} onChange={(e) => setState({ ...state, oneSignal: { ...state.oneSignal, apiId: e.target.value } })} readOnly={!editing?.oneSignal} />
                        </div>
                    </div>
                </div>
            </div>



            {/* Polltype */}
            <div className="integration-card col-md-8 m-auto p-30">
                <div className='setting-container'>
                    <div className='setting-container-left pl-50'>
                        <h3 className='fw-bold'>PollType</h3>
                    </div>

                    <div className='setting-container-right'>
                        <div className='edit-menu'>
                            {menuOpen === 'pollType' ? (
                                <div className='integration-edit-submenu'>
                                    <div className='integration-edit-submenu-item' onClick={() => { setEditing({ ...editing, pollType: false }); handleMenuToggle('') }}>Deactive</div>
                                    <div className='integration-edit-submenu-item' onClick={() => { setEditing({ ...editing, pollType: true }); handleMenuToggle('') }}>Edit</div>
                                </div>
                            ) : null}
                            <svg onClick={(e) => { handleMenuToggle('pollType') }} id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="#5f6978"><defs></defs><path fill="none" d="M0 0h24v24H0z" id="Bounding_Boxes" data-name="Bounding Boxes"></path><path d="M12 8a2 2 0 1 0-2-2 2 2 0 0 0 2 2zm0 2a2 2 0 1 0 2 2 2 2 0 0 0-2-2zm0 6a2 2 0 1 0 2 2 2 2 0 0 0-2-2z" id="Outline"></path></svg>
                        </div>

                        <div class="form-group">
                            <label class="fw-bold">API Key</label>
                            <input type="text" className="form-control" placeholder="API Key" value={state?.pollType?.apiKey} onChange={(e) => setState({ ...state, pollType: { ...state.pollType, apiKey: e.target.value } })} readOnly={!editing?.pollType} />
                        </div>
                    </div>
                </div>
            </div>



            {/* Twitter */}
            <div className="integration-card col-md-8 m-auto p-30">
                <div className='setting-container'>
                    <div className='setting-container-left pl-50'>
                        <h3 className='fw-bold'>Twitter</h3>
                    </div>

                    <div className='setting-container-right'>
                        <div className='edit-menu'>
                            {menuOpen === 'twitter' ? (
                                <div className='integration-edit-submenu'>
                                    <div className='integration-edit-submenu-item' onClick={() => { setEditing({ ...editing, twitter: false }); handleMenuToggle('') }}>Deactive</div>
                                    <div className='integration-edit-submenu-item' onClick={() => { setEditing({ ...editing, twitter: true }); handleMenuToggle('') }}>Edit</div>
                                </div>
                            ) : null}
                            <svg onClick={(e) => { handleMenuToggle('twitter') }} id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="#5f6978"><defs></defs><path fill="none" d="M0 0h24v24H0z" id="Bounding_Boxes" data-name="Bounding Boxes"></path><path d="M12 8a2 2 0 1 0-2-2 2 2 0 0 0 2 2zm0 2a2 2 0 1 0 2 2 2 2 0 0 0-2-2zm0 6a2 2 0 1 0 2 2 2 2 0 0 0-2-2z" id="Outline"></path></svg>
                        </div>

                        <div class="form-group">
                            <label class="fw-bold">API Key</label>
                            <input type="text" className="form-control" placeholder="API Key" value={state?.twitter?.apiKey} onChange={(e) => setState({ ...state, twitter: { ...state.twitter, apiKey: e.target.value } })} readOnly={!editing?.twitter} />
                        </div>

                        <div class="form-group">
                            <label class="fw-bold">API Id</label>
                            <input type="text" className="form-control" placeholder="API ID" value={state?.twitter?.apiId} onChange={(e) => setState({ ...state, twitter: { ...state.twitter, apiId: e.target.value } })} readOnly={!editing?.twitter} />
                        </div>
                    </div>
                </div>
            </div>

            <div className='text-align-center mt-50 settings-save pb-50' >
                <button className="btn btn-primary" style={{ width: '10%' }} onClick={(e) => { e?.preventDefault(); saveSettings() }}>Save</button>
            </div>

        </>
    )
}

export default IntegrationSettings