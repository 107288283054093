// import React, { useState, useEffect } from 'react';
// import Select from 'react-select';
// import './style.css'
// const SyncSelect = ({ label, placeholder, isMulti, option, setQuery }) => {
//   const [selectedOption, setSelectedOption] = useState(null);
//   const [options, setOptions] = useState([]);

//   // Handle option change
//   const handleChange = (selectedOption) => {
//     setSelectedOption(selectedOption);
//   };

//   // Synchronize the selected option with the query
//   useEffect(() => {
//     if (selectedOption) {
//       if (isMulti) {
//         // Handle multi-select case
//         const selectedValues = selectedOption.map((opt) => opt.value);
//         setQuery((prev) => ({ ...prev, ids: selectedValues }));
//       } else {
//         // Handle single-select case
//         setQuery((prev) => ({ ...prev, id: selectedOption.value }));
//       }
//     }
//   }, [selectedOption, isMulti, setQuery]);

//   // Set initial options
//   useEffect(() => {
//     if (options.length === 0) {
//       setOptions(option);
//     }
//   }, [option, options]);

//   return (
//     <div className='input-container'>
//       <strong className='font-size-13'>{label}</strong>
//       <Select
//         isMulti={isMulti}
//         placeholder={placeholder}
//         value={selectedOption}
//         onChange={handleChange}
//         options={options}
//       />
//     </div>
//   );
// };

// export default SyncSelect;

import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import './style.css';

const SyncSelect = ({ label, placeholder, isMulti, option, setQuery, defaultOption }) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [options, setOptions] = useState([]);

  // Handle option change
  const handleChange = (selectedOption) => {
    setSelectedOption(selectedOption);
  };

  // Synchronize the selected option with the query
  useEffect(() => {
    if (selectedOption) {
      if (isMulti) {
        // Handle multi-select case
        const selectedValues = selectedOption.map((opt) => opt.value);
        setQuery((prev) => ({ ...prev, ids: selectedValues }));
      } else {
        // Handle single-select case
        setQuery((prev) => ({ ...prev, id: selectedOption.value }));
      }
    }
  }, [selectedOption, isMulti, setQuery]);

  // Set initial options and default selected option
  useEffect(() => {
    if (options.length === 0) {
      setOptions(option);
    }

    // Set default option if provided
    if (defaultOption && !selectedOption) {
      setSelectedOption(isMulti ? defaultOption : defaultOption[0]);
    }
  }, [option, options, defaultOption, isMulti, selectedOption]);

  return (
    <div className='input-container'>
      <strong className='font-size-13'>{label}</strong>
      <Select
        isMulti={isMulti}
        placeholder={placeholder}
        value={selectedOption}
        onChange={handleChange}
        options={options}
      />
    </div>
  );
};

export default SyncSelect;
