export const apiEndpoints = {
  permissionGroup: `permission-group`,
  permissionGroupById: (id) => `permission-group/${id}`,

  permission: `permission`,
  permissionById: (id) => `permission/${id}`,

  roles: `roles`,
  rolesById: (id) => `roles/${id}`,

  user: `user`,
  createUser: `auth/register`,
  userById: (id) => `user/${id}`,

  collection: `collection`,
  collectionById: (id) => `collection/${id}`,

  menus: `menus`,
  menusById: (id) => `menus/${id}`,

  tags: `tags`,
  tagsById: (id) => `/tags`,

  upload: `upload-files`,
  uploadById: (id) => `upload-files/${id}`,
  changePassword: (id) => `user/change-password/${id}`,
  forgetPassword: `user/change-password`,

  section: `section`,
  sectionById: (id) => `section/${id}`,

  opinionPolls: `opinion-polls`,
  opinionPollsById: (id) => `opinion-polls/${id}`,

  stories: `stories`,
  storiesById: (id) => `stories/${id}`,
  unpublishStory: (id) => `stories/unpublish/${id}`,

  editorNotes: `stories/editor-notes`,
  getEditorNotes: `stories/editor-notes/by-story`,
  timeline: `stories/timeline`,
  getTimeline: `timeline/story`,

  createFbFeed: (id) => `stories/${id}/create/fb-feed`,
  rssFacebookPost: (id) => `rss-breaking-stories/${id}/post/facebook`,
  rssInstagramPost: (id) => `rss-breaking-stories/${id}/post/instagram`,
  rssTwitterPost: (id) => `rss-breaking-stories/${id}/post/twitter`,

  page: `/page`,
  pageById: (id) => `/pages/${id}`,

  collectionOfCollection: `collection-of-collection`,
  collectionOfCollectionById: (id) => `collection-of-collection/${id}`,

  scriptTag: `script-tag`,
  scriptTagById: (id) => `script-tag/${id}`,

  notification: `notification`,
  notificationById: (id) => `notification/${id}`,

  storiesFilter: `stories/list/filter`,

  storiesFilterForCollection: (id) => `stories/list/for/collection/${id}`,

  listGeneralSettings: `general-settings`,

  breakingToRss: `rss-breaking-stories/system-breaking`
};
